import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayoutEn from "./CommonLayoutEn";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {makeRequest} from "components/AccountPage/AccountPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

export interface AccountBillingEnProps {
  className?: string;
  data?: string;
  location?: object;
}





const AccountBillingEn: FC<AccountBillingEnProps> = ({ className = "" , location }) => {


  var user = JSON.parse(localStorage.getItem("user"));
  var ids = JSON.parse(localStorage.getItem("rjsth"));
  const idUserUpload = parseInt(atob(ids));


  var tn = "Adrenalinika";
  var token = hashToken(tn);
  const urlUserUpload = "https://www.adrenalinika.com/gestionale/ajax_exemple/voucher/get-user-voucher.php";

  async function sendUploadUser (idUserUpload) {
  return await new Promise(function (resolve, reject) {

    var xhr = new XMLHttpRequest();
    xhr.open('POST', urlUserUpload, true);
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);

      }
    };


    xhr.send("customer_id=" + JSON.stringify(idUserUpload));

  });
  };


    const [data, setDataTot] = useState([]);
    var fieldVoucher = "";

    if(location.pathname == "/en/your-voucher"){
      var uploadUs = sendUploadUser(idUserUpload);
      fieldVoucher  = () =>  Promise.all ([uploadUs]).then((listRequestUpl) => {
        return listRequestUpl;
      });
}

  useEffect(() => {
    if(fieldVoucher != ""){
    fieldVoucher().then((ressts) => {
      var result = JSON.parse(ressts);
      setDataTot(result);
  });
  }
  }, []);


  if(!user){
    return (
      <Redirect to="/en/"/>
    );
  }

else{
  var dataScaduti = data;
  dataScaduti = dataScaduti.filter((_, i) => moment(dataScaduti[i].data_scadenza) <= moment() && moment(dataScaduti[i].data_scadenza) > moment().add(-365, 'days') );
  var dataUtilizzati = data;
  dataUtilizzati = data.filter((_, x) => parseInt(dataUtilizzati[x].importo) == 0 || moment(dataUtilizzati[x].utilizzato) == 1);
  var dataAttivi = data;
  dataAttivi = dataAttivi.filter((_, i) => moment(data[i].data_scadenza) > moment() && parseInt(data[i].importo) != 0 && parseInt(data[i].utilizzato) != 1 && parseInt(data[i].pagamento_effettuato) == 1);
  var dataAttesaConferma = data;
  dataAttesaConferma = dataAttesaConferma.filter((_, i) => moment(data[i].data_scadenza) > moment() && parseInt(data[i].importo) != 0 && parseInt(data[i].utilizzato) != 1 && parseInt(data[i].pagamento_effettuato) == 0);


  return (
    <div>
      <CommonLayoutEn>
        <div className="space-y-6 sm:space-y-8 pb-12">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Voucher</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="">
            <span className="text-xl font-semibold block">Active vouchers</span>
            <small className="mb-4 block">Select the "Redeem" item next to the voucher you want to use. On the next screen you can choose the date, time and any options. </small>
            <br />
            {dataAttivi ? dataAttivi.map((_, i) => (
                <ExperiencesCardH data={dataAttivi[i]} key={dataAttivi[i].id+[i]} buttonName="Redeem now" ButtonClass="background-red" link="/en/reedem-your-voucher" />
              )): <h2 className="text-1xl md:text-xl font-semibold ">No Result...</h2>}

          </div>
        </div>

          <div className="space-y-6 sm:space-y-8  pb-12">
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div>
              <span className="text-xl font-semibold block">Voucher Awaiting payment</span>
              <small className="mb-4 block">This section lists the vouchers awaiting payment receipt confirmation. (Ex. Bank Transfer, Welfare, etc.). You will be able to book your experience as soon as the payment appears in the Adrenalinika systems. </small>
              <br />
              <div  style={{opacity: '0.7'}}>
                      {dataAttesaConferma ? dataAttesaConferma.map((_, i) => (
                        <ExperiencesCardH data={dataAttesaConferma[i]} key={dataAttesaConferma[i].id+[i]} buttonName="Request info" ButtonClass="background-red" link="/contact" />
                      )): <h2 className="text-1xl md:text-xl font-semibold ">No Result...</h2>}
              </div>
            </div>
          </div>

          <div className="space-y-6 sm:space-y-8  pb-12">
            {/* HEADING */}
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div>
                <span className="text-xl font-semibold block">Expired vouchers</span>
                <small className="mb-4 block">You can request a deadline extension no later than 30 days. The renewal service costs 30% of the voucher amount.</small>
                <br />
                <div  style={{opacity: '0.7'}}>
                  {dataScaduti ? dataScaduti.map((_, i) => (
                      <ExperiencesCardH data={dataScaduti[i]} key={dataScaduti[i].id+[i]} buttonName="Renew" ButtonClass="background-red" link="/rinnova" />
                    )): <h2 className="text-1xl md:text-xl font-semibold ">No Result...</h2>}
              </div>
              </div>
        </div>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div style={{opacity: '0.7'}}>
              <span className="text-xl font-semibold block">Used vouchers</span>
              <small className="mb-4 block">History of purchased vouchers. </small>
              <br />
              {dataUtilizzati ? dataUtilizzati.map((_, x) => (
                  <ExperiencesCardH data={dataUtilizzati[x]} key={dataUtilizzati[x].id+[x]} buttonName="Buy again" ButtonClass="" completeLink={dataUtilizzati[x].href} />
                )): <h2 className="text-1xl md:text-xl font-semibold ">No Result...</h2>}
            </div>
      </div>
        </CommonLayoutEn>
    </div>
  );
};
}

export default AccountBillingEn;
