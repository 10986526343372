import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import Input from "shared/Input/Input";
import { withRouter } from 'react-router';
import PageLogin from "containers/PageLogin/PageLogin";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Formik, Field, Form } from "formik";
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {loginUser} from "components/FormLogin/FunctionUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import Label from "components/Label/Label";
import { useHistory } from "react-router-dom";

export interface FormForgotProps {
  className?: string;
}





const FormForgot: FC<FormForgotProps> = ({ className = ""}) => {
  const history = useHistory();
  const [visible, setvisible] = useState(false);
  const [newPass, setNewPass] = useState();
  const [confPass, setConfPass] = useState();
  const [dataConf, setDataConf] = useState();
  const [constDataSend, setConstDataSend] = useState(moment().format("YYYY/MM/DD"));




  const useQuery = () => new URLSearchParams(useLocation().search);
  const queryes = useQuery();
  const key = queryes.get('key');
  const seckey = queryes.get('seckey');

  var dataValid = "";
  var dataV = "";
  var datac = "";
  var datacc = "";
  var dataForg = "";
  var emtoforgot = "";
  var tokentoforgot = "";
  var dataValida = "";
  var dataOggi = moment().format("YYYY/MM/DD");


  if (key && seckey){
  dataValid = atob(seckey);
  dataValida = atob(seckey);
  dataV = key.split('=ReE6402tT');
  datac = atob(dataV[0]);
  datacc = atob(dataV[1]);
  dataForg = datacc.split('=ReE6402tT');
  emtoforgot =  atob(dataForg[0]);
  tokentoforgot =  dataForg[1];
  }


    const handleChangeNew = event => {
      var passwordcryptnew = hashPassword(event.target.value);
      setDataConf(datac);
      setNewPass(passwordcryptnew);
    };
    const handleChangeConfirm = event => {
      var passwordcryptconf = hashPassword(event.target.value);
     setConfPass(passwordcryptconf);
     var oras = moment(dataValida).add(9, 'days').format("YYYY-MM-DD");
     setConstDataSend(oras);

    };

    const handleClick = event => {
        event.preventDefault();
        if(newPass == confPass && newPass && confPass && dataConf && constDataSend){
          var uploadPssw = sendUploadPassword(newPass, confPass, dataConf, constDataSend);
        }else if (newPass != confPass){
          toast.error('The password confirmation does not match the new password.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        } else if (!newPass || !confPass || newPass == '' || confPass == ''){
          toast.error('Fill in all fields.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
    };

  useEffect(() => {
    if(dataValid == dataOggi && emtoforgot === datac && tokentoforgot == hashToken(datac)){
      setvisible(true);
    }
    else {

          toast.error('The reset link has expired.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }
  }, []);


  var tn = "Adrenalinika";
  var token = hashToken(tn);
  const urlUserUpload = 'https://www.adrenalinika.com/gestionale/ajax_exemple/password/modifica-password.php';

  const fieldUser  = () =>  Promise.all ([uploadUs]).then((listRequestUpl) => {
    return listRequestUpl;
  });

    async function sendUploadPassword (newPass, confPass, dataConf,constDataSend) {
      var tn = "Adrenalinika";
      var token = hashToken(tn);
      const urlUserUpload = 'https://www.adrenalinika.com/gestionale/ajax_exemple/password/modifica-password.php';
      var constDt = '' + constDataSend;
    return await new Promise(function (resolve, reject) {
      var mast = btoa(dataConf);
      var xhr = new XMLHttpRequest();
      xhr.open('PUT', urlUserUpload, true);
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
          if(xhr.response == 0){
            toast.error('Error, try entering a different password.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }else{

          toast('Update done');
          setTimeout(() => {history.push("/")}, 3000);
          }

        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
          toast.error('Error during the request process. Contact the site administrator', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      xhr.send(JSON.stringify({
        new_password: newPass,
        confirm_password: confPass,
        confirm_par: mast,
        const: constDt,
        })
      );

    });
  }


    return (
      <div className={`nc-FormForgot ${className}`} data-nc-id="FormForgot">
        <Helmet>
          <title>Reset password || Adrenalinika</title>
        </Helmet>
        <div>
        <div className="container mb-24 lg:mb-32">
          <h1 className="my-20 flex items-center text-2xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Password recovery
          </h1>
          <ToastContainer />
          <div className="max-w-md mx-auto space-y-6">
            {/* OR */}
            <div className="relative text-center">
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2  border-neutral-100 dark:border-neutral-800"></div>
            </div>
            {/* FORM */}
            { !visible ?  <a className="block text-center text-neutral-700 dark:text-neutral-300 text-xl" href="/forgot-pass">You can request a password change again from here</a> : "" }


          {visible ? <div className=" max-w-xl space-y-6">
              <div>
                <Label>New Password</Label>
                <Input type="password" className="mt-1.5" onChange={handleChangeNew} required="required" />
              </div>
              <div>
                <Label>Confirm password</Label>
                <Input type="password" className="mt-1.5" onChange={handleChangeConfirm} required="required" />
              </div>
              <div className="pt-2">
                <ButtonPrimary
                onClick={handleClick}>Update password</ButtonPrimary>
              </div>
            </div> : ""}

               </div>
              </div>
          </div>
          </div>
          );
        };


export default FormForgot;
