import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayoutEn from "./CommonLayoutEn";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardReservation";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {makeRequest} from "components/AccountPage/AccountPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import { Formik, Field, Form } from "formik";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface ModificaPrenotazioneEnProps {
  className?: string;
  data?: string;
}





const ModificaPrenotazioneEn: FC<ModificaPrenotazioneEnProps> = ({ className = "" }) => {

  var user = JSON.parse(localStorage.getItem("user"));
  var ids = JSON.parse(localStorage.getItem("rjsth"));
  const idUserUpload = parseInt(atob(ids));



  var tn = "Adrenalinika";
  var token = hashToken(tn);
  const urlUserUpload = "https://www.adrenalinika.com/gestionale/ajax_exemple/voucher/get-user-appointments.php";

  async function sendUploadUser (idUserUpload) {
  return await new Promise(function (resolve, reject) {

    var xhr = new XMLHttpRequest();
    xhr.open('POST', urlUserUpload, true);
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);

      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    xhr.send("customer_id=" + JSON.stringify(idUserUpload));

  });
  };

  const [data, setDataTot] = useState([]);

  var fieldVoucher = "";
  var urlControl = window.location.pathname;
  urlControl = urlControl.split('/');
  var forUrlControl = urlControl[2];

  if(forUrlControl == "modifica-prenotazione"){
    var uploadUs = sendUploadUser(idUserUpload);
     fieldVoucher  = () =>  Promise.all ([uploadUs]).then((listRequestUpl) => {
      return listRequestUpl;
    });
  }

useEffect(() => {
  if(fieldVoucher != ""){
  fieldVoucher().then((ressts) => {
    var result = JSON.parse(ressts);
    setDataTot(result);
  });
  }
}, []);



  if(!user){
    return (
      <Redirect to="/"/>
    );
  }

else{
  var dataAttivi = data;
  dataAttivi = dataAttivi.filter((_, i) => moment(data[i].start_datetime) > moment() && parseInt(data[i].importo) != 0);

  return (
    <div>
      <CommonLayoutEn>
        <div className="space-y-6 sm:space-y-8 pb-12">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Change booking</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <ToastContainer />
          <div className="">
          <span>Fill out the form to request an appointment change. You will be contacted * shortly.</span><br /><br />
          <Formik
            initialValues={{ name: "", surname: "", email: "", phone: "", request:"", appointment: "",}}
            onSubmit={(values, actions) => {
              var tn = "Adrenalinika";
              var token = hashToken(tn);
              setTimeout(() => {

               actions.setSubmitting(false);
             }, 3000);

            var email = btoa(values.email);


             var datas = {
                            firstName: values.name,
                            lastName: values.surname,
                            email: email,
                            phone: values.phone,
                            request: values.request,
                            appointment: values.appointment,

               };

              var datatot = JSON.stringify(datas);
              const xhr = new XMLHttpRequest();
              const url = 'https://www.adrenalinika.com/gestionale/ajax_exemple/mailfrontend/modifica-appuntamento.php';


              xhr.open('PUT', url, true);
              xhr.setRequestHeader("Content-Type", "application/json");
              xhr.setRequestHeader("Authorization", 'Bearer ' + token);
              //xhr.setRequestHeader("Token", "Basic ".passwordcrypt);
              xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
              xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
              xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
              xhr.onreadystatechange = function () {
              // In local files, status is 0 upon success in Mozilla Firefox
              if(xhr.readyState === XMLHttpRequest.DONE) {
                var status = xhr.status;

                if (status === 0 || status >= 200 && status < 400) {

                  if(status == 200){
                    toast.success('Change request sent successfully. You will receive feedback shortly. Keep an eye on your email box.', {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      });
                      window.location.replace("/lista-prenotazioni-effettuate");
                  } else if (status == 500){
                    toast.error('Server connection error. Please try again soon or contact Adrenalinika through other channels.', {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      });
                  }


                } else {
                  // Oh no! There has been an error with the request!

                  toast.error('Server connection error. Please try again soon or contact Adrenalinika through other channels.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                }
              }
            };
            xhr.send(datatot);

               //SEND EMAIL A UTENTE


            }
          }

          >
          {
            props => (
          <form className="grid grid-cols-1 gap-6" onSubmit={props.handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Name
              </span>
              <Input
                type="text"
                placeholder="Your name"
                className="mt-1"
                name="name"
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Surname
              </span>
              <Input
                type="text"
                placeholder="Your surname"
                className="mt-1"
                id="cognome"
                name="surname"
                value={props.values.surname}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="email"
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                required

              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Phone
              </span>
              <Input
                type="text"
                placeholder="+39 000 000 0000"
                className="mt-1"
                id="telefono"
                name="phone"
                value={props.values.phone}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Request reason
              </span>
              <Input
                type="text"
                placeholder="Describe the reason..."
                className="mt-1"
                id="request"
                name="request"
                value={props.values.request}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                required
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Select Appointment
              </span>

                <select
                  className={`nc-Select ${className} block w-full text-sm rounded-2xl border-neutral-200 pt-3 pb-3 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                  value={props.values.appointment}
                  onChange={props.handleChange}
                  id="appointment"
                  name="appointment"
                  onBlur={props.handleBlur}
                  required
                >
                <option key="empty"></option>
                {dataAttivi ? dataAttivi.map((_, i) => (  <option key={dataAttivi[i].codice_referenziale} value={dataAttivi[i].codice_referenziale}>{dataAttivi[i].title+(" (")+moment(dataAttivi[i].start_datetime).format("DD/MM/YYYY")+(") ")}</option> )) : <option value="no-result">Nessun risultato</option>}
                </select>
            </label>

            {props.errors.name && <div id="feedback">{props.errors.name}</div>}
            <ButtonPrimary type="submit">Send</ButtonPrimary>
          </form>
        )}
        </Formik>


          </div>
        </div>
        </CommonLayoutEn>
    </div>
  );
};
}

export default ModificaPrenotazioneEn;
