import GalleryImgs from "components/GallerySlider/GallerySingle";
import { ExperiencesDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import Avatar from "shared/Avatar/Avatar";
import moment from "moment";
import React, { FC, useState, useEffect } from "react";
import { Redirect } from 'react-router';
import Cookies from 'universal-cookie';


export interface ExperiencesCardHProps {
  className?: string;
  data: string;
}

const ExperiencesCardH: FC<ExperiencesCardHProps> = ({
  className = "",
  data,
  buttonName,
  buttonClass,
  link,
  completeLink,
  param,
}) => {

  const {
    id_servizio,
    title,
    duration,
    date,
    book_datetime,
    saleOff,
    price,
    id,
    customer_id,
    importo,
    service_id,
    data_acquisto,
    data_scadenza,
    bambini,
    adulti,
    addons,
    prodotto,
    utilizzato,
    importo_iniziale,
    pagamento_effettuato,
    metodo_pagamento,
    description,
    servizi_inclusi,
    servizi_non_inclusi,
    politica_cancellazione,
    requisiti_partecipazione,
    regole_appuntamento,
    featuredImage,
    commentCount,
    viewCount,
    reviewCount,
    reviewStart,
    like,
    galleryImgs,
    difficolta,
    address,
    lingue,
    isAds,
    authorId,
    author,
    maxGuests,
    listingCategoryId,
    sottocategoria,
    categoria_luoghi,
    conferma,
    title_eng,
  } = data;
  const [isDisableBlu, setIsDisableBlu] = useState(false);
  var tkn = btoa('jwt56wert');
  var user = JSON.parse(localStorage.getItem("user"));
  var ids = JSON.parse(localStorage.getItem("rjsth"));
  const idUserUpload = parseInt(atob(ids));
    //check is en
    const lang = new Cookies();
    lang.get('lang');


  const renderSliderGallery = () => {
    return (
      <div className="relative w-full md:w-72 flex-shrink-0 overflow-hidden">
        <GalleryImgs
          ratioClass="aspect-w-12 aspect-h-9 md:aspect-h-13"
          galleryImgs={data.featuredImage}
        />
        {/*data.saleOff && <SaleOffBadge className="absolute left-3 top-3" />*/}
      </div>
    );
  };

  useEffect(() => {
    var today = moment().endOf('day').format("YYYY-MM-DD");
    var dataScad = moment(data.data_scadenza).format("YYYY-MM-DD");
  if(today>dataScad){
    setIsDisableBlu(true);
  }
     }, []);

  const renderContent = () => {


    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">

            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{lang.cookies.lang == "en" ? data.title_eng : data.title}</span>
            </h2>
          </div>
          <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400">
            <div className="flex items-center">
              <span className="hidden sm:inline-block  text-small">
                <i className="las la-clock"></i> <b>{lang.cookies.lang == "en" ? "Purchase" : "Acquisto"}:</b> { moment(data.book_datetime).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="flex items-center">
              <span className="hidden sm:inline-block text-small">
                <i className="las la-clock"></i> <b>{lang.cookies.lang == "en" ? "Start" : "Inizio"}:</b> { moment(data.start_datetime).format("DD/MM/YYYY HH:mm")}
              </span>
            </div>
          </div>
          <div className="flex items-center space-x-8 mt-4 text-small text-sm text-neutral-500">
            <span className="hidden sm:inline-block text-small">
              <i className="las la-info-circle"></i> {data.all_add} {!!data.prodotto && data.prodotto != '' ? lang.cookies.lang == "en" ? "| Config: " : "| Config: "+data.prodotto : ''}{!!data.giri && data.giri != '' ? ' | '+data.giri : ''}
            </span>
          </div>
        </div>
        <div className="block md:flex items-center mdspace-x-8 md:mt-4">
          <div className="flex items-center space-x-2">
            <span className="md:text-sm text-neutral-500 dark:text-neutral-400">
            <b className="text-sm font-medium capitalize text-neutral-500 dark:text-neutral-400">{data.pagamento_effettuato === "1" || data.pagamento_effettuato === 1 ? lang.cookies.lang == "en" ? "Paid: " : "Pagato: " : lang.cookies.lang == "en" ? "Outstanding: " :"In sospeso: "}</b> <b style={{color: 'green'}}> {parseFloat(data.costo) > 0 ? '€ '+parseFloat(data.costo).toFixed(2) : ''}</b>
            </span>
          </div>
          <div className="block md:flex md:items-center md:space-x-2 text-sm text-neutral-500 dark:text-neutral-400 font-medium">
          <span className="hidden sm:inline"></span>{" "}
          <i className="las la-key text-sm"></i>  Prenotazione: <b style={{color: 'green', textTransform: 'uppercase'}}>{data.codice_referenziale}</b>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-3"></div>
        <div className="block sm:border-neutral-100 rounded rounded-2xl overflow-hidden transition-shadow p-3">
          <div className="flex items-center space-x-3 text-sm text-neutral-700  dark:text-neutral-300 sm:block pb-2">
            <span className="text-base text-neutral-500 dark:text-neutral-400">
            <b className="text-sm font-medium text-neutral-500 dark:text-neutral-400" style={{color: data.conferma === "1" || data.conferma === 1 ? 'green' : 'red', textTransform: 'uppercase'}}>{data.conferma === "1" || data.conferma === 1 ? lang.cookies.lang == "en" ? "The appointment has been approved by the provider." : "L'appuntamento è stato approvato dal provider." : lang.cookies.lang == "en" ? "The appointment is waiting to be approved by the provider." : "L'appuntamento è in attesa di essere approvato dal provider."}</b>
            </span>
          </div>
          <span className="pt-2 text-sm font-semibold text-secondary-700 sm:block">
            {<div><i className='las la-user'></i> {data.provider_username}</div>}
          </span>
          <span className="text-sm font-semibold text-secondary-700 sm:block">
            {<div><i className='las la-phone'></i> {data.provider_telefono}</div>}
          </span>
          <span className="text-sm font-semibold text-secondary-700 sm:block">
            {<div><i className='las la-envelope'></i> {data.provider_mail}</div>}
          </span>
        </div>
      </div>
    );
  };

  if(!user){
    return (
      <Redirect to="/"/>
    );
  }

  return (
    <div
      className={` mb-10 nc-ExperiencesCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="ExperiencesCardH"
    >
      <Link to={completeLink ? completeLink : link +'/?'+btoa('guestAdult='+data.adulti+'&guestChildren='+data.bambini+'&prodotto='+data.prodotto+'&addons='+data.addons+'&importo-scalare='+data.importo+'&pagato='+data.pagamento_effettuato+'&scadenza='+ moment(data.data_scadenza).format("DD-MM-YYYY"))}  className="md:flex md:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default ExperiencesCardH;
