import Glide from "@glidejs/glide";
import React, { FC, useState, useEffect } from "react";
import {makeListCategory} from "hooks/useRequestDb";
import Heading from "components/Heading/Heading";

import { TaxonomyType } from "data/types";
import ncNanoId from "utils/ncNanoId";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import CardCategory1 from "components/CardCategory1/CardCategory1";

  export interface SectionSliderNewPlacesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  item: {places};
}


const SectionSliderNewPlaces: FC<SectionSliderNewPlacesProps> = ({
  heading = "Esplora le esperienze per Regioni",
  subHeading = "Prenota o regala un'esperienza adrenalinica scegliendo tra le migliori località Italiane",
  className = "",
  itemClassName = "",
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
  item = "",


}) => {


  const UNIQUE_CLASS = "glide_" + ncNanoId();


      const places: TaxonomyType[] = item.map((item) => ({
        ...item,
        taxonomy: "places",
      }));


const [slider, setSlider] = useState();


  useEffect(() => {

    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
    new Glide((`.${UNIQUE_CLASS}`), {
        perView: itemPerRow,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: itemPerRow - 1,
          },
          1024: {
            gap: 20,
            perView: itemPerRow - 1,
          },
          768: {
            gap: 20,
            perView: itemPerRow - 2,
          },
          640: {
            gap: 20,
            perView: itemPerRow - 3,
          },
          500: {
            gap: 20,
            perView: 1.3,
          },
        },
      }).mount();
    }


} );





  const renderCard = (items: TaxonomyType, index: number) => {
    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={items} />;
      case "card4":
        return <CardCategory4 taxonomy={items} />;
      case "card5":
        return <CardCategory5 taxonomy={items} />;
      default:
        return <CardCategory3 taxonomy={items} />;
    }
  };



  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading
          desc={subHeading}
          hasNextPrev={sliderStyle === "style1"}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {places.map((items, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {renderCard(items, index)}
              </li>
            ))}
          </ul>
        </div>


        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
    </div>
  );
};

export default SectionSliderNewPlaces;
