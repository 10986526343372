import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import React, { FC }from "react";
import {useParams} from "react-router-dom";
import LoginForm from "components/FormLogin/FormLogin";
//const data = "http://www.adrenalinika.com/gestionale/login/User/read.php"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export interface PageLoginProps {
  className?: string;
}



const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {


  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Area Login || Adrenalinika Experiences</title>
        <meta name="description" content="Effettua il login su Adrenalinika experience. Attraverso la tua area personale, potrai riscattare i tuoi voucher esperienza oppure gestire le tue prenotazioni." />
        <link rel="canonical" href="https://www.adrenalinika.com/login" />
      </Helmet>
      <div id="successlog"></div>
      <div id="paginalog">
      <div className="container mb-24 lg:mb-32">
        <h2 className="mt-20 mb-5 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Effettua il Login
        </h2>
        <p className="text-center mb-20">Attraverso la tua area personale, potrai riscattare i tuoi voucher esperienza oppure gestire le tue prenotazioni.</p>
        <div className="max-w-md mx-auto space-y-6">

          {/* FORM */}
          <LoginForm />
                  <ToastContainer />
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Nuovo Utente? {` `}
            <Link to="/signup">Crea un account</Link>
          </span>
        </div>
      </div>
      </div>
    </div>
  );
};

export default PageLogin;
