import React from "react";
import { Link } from "react-router-dom";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";
import Cookies from 'universal-cookie';

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = "",
  imgLight = "",
  className = "",
}) => {

  //check is en
  const lang = new Cookies();
  lang.get('lang');

  var toLinkLogo = lang.cookies.lang == "en" ? "/en" : "/";

  return (
    <Link
      to={toLinkLogo}
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
      aria-label="logo-adrenalinika"
    >
      <LogoSvgLight />
      <LogoSvg />

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {/* {img ? (
        <img
          className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </Link>
  );
};

export default Logo;
