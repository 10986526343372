import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import React, { FC, useState, useEffect }from "react";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import { withRouter } from 'react-router';
import { Formik, Field, Form } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {FormForgot} from "containers/PageForgot/FormForgot";
import {loginUser} from "components/FormLogin/FunctionUser";
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import moment from "moment";

export interface PageLoginEnProps {
  className?: string;
}




  async function sendForgotAsync(datal,token,em){
    var datat = datal;


     const xhr = new XMLHttpRequest();
     const url = 'https://www.adrenalinika.com/gestionale/ajax_exemple/password/verifica-modifica.php?email='+datat.email.toLowerCase()+'&forgot='+datat.forgot;


     xhr.open('POST', url, true);
     xhr.setRequestHeader("Authorization", 'Bearer ' + token);
     xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

     xhr.setRequestHeader("Content-Type", "application/json");
     xhr.onreadystatechange = function (datat) {

     if(xhr.readyState === XMLHttpRequest.DONE) {
       var status = xhr.status;

       if (status >= 200 && status < 400) {

         if(xhr.responseText == 0){
           toast.error('Error during the reset process. Contact the site administrator', {
             position: "top-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
         } else {
           toast.success('Check your email inbox to change your password.');
         }

       } else {
         // Oh no! There has been an error with the request!

         toast.error('Error during the registration process. Contact the site administrator', {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
           });
       }
     }
   };
   xhr.send(JSON.stringify(datat));
 }







const forgotUser = (datal,token,em) => {

    if(em && token && datal){
      var uploadUs = sendForgotAsync(datal,token,em);
      const fieldUser  = () =>  Promise.all ([uploadUs]).then((listRequestUpl) => {
        return listRequestUpl;
      });




   }else if (!em || em == ''){
      toast.error('Inserisci una mail corretta', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
};



const PageLoginEn: FC<PageLoginEnProps> = ({ className = "", key = "", seckey = "" }) => {



  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot password || Adrenalinika Experiences</title>
      </Helmet>
      <div id="successlog"></div>
      <div id="paginalog">
      <div className="container mb-24 lg:mb-32">
        <h1 className="my-20 flex items-center text-2xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Password recovery
        </h1>
        <p style={{maxWidth:'680px'}} className={`m-auto justify-center text-center text-small text-neutral-900 dark:text-neutral-100 justify-center`}>Have you purchased an experience without registering? Don't worry, enter the email you used during checkout and set your password.</p>
        <ToastContainer />
        <div className="max-w-md mx-auto space-y-6">
          {/* OR */}
          <div className="relative text-center">
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2  border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}



            <Formik
              initialValues={{ email_login: "", forgot: ""}}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                 actions.setSubmitting(false);
               }, 3000);
               var em = values.email_login.toLowerCase();
               var tn = "Adrenalinika";
               var token = hashToken(tn);
               var datal = {
                              email: em,
                              forgot: btoa(em)+'=ReE6402tT'+hashToken(em),
                 };
                 forgotUser(datal,token,em);
                }
            }

            >
            {
              props => (
                <form id="formlog" className="grid grid-cols-1 gap-6" onSubmit={props.handleSubmit}>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">

                    </span>
                    <Input
                      type="email"
                      placeholder="Your email (mail@mail.com)"
                      className="mt-1"
                      id="email_login"
                      name="email_login"
                      value={props.values.email_login}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  </label>
                  {props.errors.email_login && <div id="feedback">{props.errors.email_login}</div>}
                  <ButtonPrimary type="submit">Next</ButtonPrimary>
                </form>
      )}
      </Formik>


          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Sign Up | {` `}
            <Link to="/en/signup">New account</Link>
          </span>
        </div>
      </div>
      </div>
      </div>
  );
};

export default PageLoginEn;
