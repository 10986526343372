import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import Cookies from 'universal-cookie';



export interface GuestsInputProps {
  defaultValue: {
    guestAdults?: number;
    guestChildren?: number;
  };
  onChange?: (data: GuestsInputProps["defaultValue"]) => void;
  fieldClassName?: string;
  classNamePop?: string;
  typeofex?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  maxGuests,
  minGuests,
  fieldClassName = "[ nc-hero-field-padding ]",
  classNamePop,
  typeofex,
}) => {

  //check is en
  const lang = new Cookies();
  lang.get('lang');

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
     0
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
     0
  );



  useEffect(() => {
    if (onChange) {
      onChange({
        guestAdults: guestAdultsInputValue,
        guestChildren: guestChildrenInputValue,
      });
    }
  }, [guestAdultsInputValue, guestChildrenInputValue]);

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue;


function limiterguest(totalGuests, maxGuests){
  if (parseInt(totalGuests) == parseInt(maxGuests)){
    return "0";
  } else {
    return maxGuests;
  }
}
  return (
    <Popover className={`flex relative [ nc-flex-1 ]`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex border dark:border-neutral-800 rounded-2xl text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer border-neutral-300 ${
              open ? " rounded-full dark:bg-neutral-800" : ""
            }`}
          >
            <div className="text-neutral-300 dark:text-neutral-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="nc-icon-field"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#b9241c"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
              </svg>
            </div>
            <div className="flex-grow">
              <span className="block xl:text-lg font-semibold">
                {totalGuests || ""} {lang.cookies.lang == "en" ? "People": totalGuests ? "Partecipanti" : "Aggiungi Partecipanti"}
              </span>
              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                {lang.cookies.lang == "en" ? totalGuests ? "People" : "Add People" : totalGuests ? "Persone" : "Aggiungi Persone"}
              </span>

            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className={`absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ${classNamePop}`}>
          {typeofex === 'entrambi' || typeofex === 'maggiorenni' || typeofex == '' || !typeofex ?  <NcInputNumber
              className="w-full"
              defaultValue={guestAdultsInputValue}
              onChange={(value) => setGuestAdultsInputValue(value)}
              max={limiterguest(totalGuests, maxGuests)}
              min={typeofex === 'minorenni' ? 0 : minGuests}
              label={lang.cookies.lang == "en" ? "Adults" : "Adulti"}
              desc={lang.cookies.lang == "en" ? "+18 yo" : "+18 anni"}
            /> : ''}
            <div className="py-2 block"></div>
            {typeofex === 'entrambi' || typeofex === 'minorenni' ? <NcInputNumber
              className="w-full"
              defaultValue={guestChildrenInputValue}
              onChange={(value) => setGuestChildrenInputValue(value)}
              max={limiterguest(totalGuests, maxGuests)}
              min={typeofex === 'maggiorenni' || typeofex === 'entrambi' ? 0 : minGuests}
              label={lang.cookies.lang == "en" ? "Children" : "Bambini"}
              desc="-"
            /> : ''}


            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
