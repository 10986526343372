import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";

export interface PageRegolamentoEnProps {
  className?: string;
}

const PageRegolamentoEn: FC<PageRegolamentoEnProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageRegolamento"
    >
      <Helmet>
        <title>Rules, Terms and Conditions || Adrenaline Activity</title>
        <link rel="canonical" href="https://www.adrenalinika.com/en/regulation" />
        <meta name="description" content="Adrenalinika is a platform for booking or giving away experiences in Italy. Choose adrenaline-pumping experiences, immersive adventures or daredevil courses." />
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div className="nc-Section relative">
        <Heading desc="">
        Terms and Conditions Adrenalinika SRL
        </Heading>
        <div className="">

        <div className="">
        <h4 className="text-neutral-6000 text2 dark:text-neutral-300 mb-10">
        <b>INTRODUCTION</b> <br />
        1. INTRODUCTION AND DEFINITIONS <br />
        2. CREATION OF ORDERS AND CONTRACT <br />
        3. PAYMENT AND PRICES EXPERIENCES <br />
        4. BOOKING PROCESS <br />
        5. DELIVERY <br />
        6. ACTIVATION OF THE EXPERIENCES/SERVICE <br />
        7. PARTNERS <br />
        8. EXPERIENCE/SERVICE <br />
        9. EXPERIENCE/SERVICE CANCELLATION <br />
        10. RESPONSIBILITY, ACCESS AND OPERATION OF THE SITE <br />
        11. CUSTOMER SERVICE AND COMPLAINTS <br />
        12. COMMITMENT AND OBLIGATIONS OF THE CUSTOMER <br />
        13. PROPERTY <br />
        14. CHANGES <br />
        15. CHANGES TO CUSTOMER TERMS AND CONDITIONS <br />
        16. PROTECTION OF PERSONAL DATA <br />
        </h4>

        </div>

 <div className="text-neutral-6000 dark:text-neutral-300">
 <span className="text-neutral-6000 text-xl dark:text-neutral-300">INTRODUCTION</span>
 <p>These "Terms and Conditions" intend to define the rules relating to the sale of Experiences/Services and their use. These are valid as of December 1, 2022 for any purchase and/or use made from that date.<br />
 Adrenalinika srl reserves the right to modify these “Terms and Conditions”<br />
 <br />
 at any time, without notice. In the event of a change, the aforementioned applicable will be those in force on the date of purchase or use of the Experience/Service. A copy of the aforementioned "Terms and Conditions" can be consulted online and can be printed directly from the online site in the "Regulations" section<br />
 Any User/Purchaser/Beneficiary declares to be at least 18 years old and capable of acting, in accordance with article 2 of the Civil Code, to contract and use the Site in accordance with the "Terms and Conditions" reported.<br />
 Adrenalinika srl publishes and retails electronic Vouchers of packages relating to Outdoor activities carried out via the internet, in the name and on behalf of its Partners who provide the Services proposed in the Experiences themselves. Adrenalinika is an Italian company, with registered office in Milan, Via Fabio Filzi 2, registered with the Chamber of Commerce located in Milan with REA code: MI-2663406 and with operational headquarters located in Vertemate con Minoprio in Via Canturino 22, Milan. </ p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">1. INTRODUCTION AND DEFINITIONS</span>
 <p>1.1 The terms defined below will have the following meanings in the "Terms and Conditions" of the Sale of the Experience/Service:<br />
 • Purchaser: designates the person who purchases an Experience/Service;<br />
 • Beneficiary: designates the person who uses the Experience/Service;<br />
 • Online purchase: designates the purchase of an Experience/Service;<br />
 • Cookies: designate small files sent to your computer's hard drive<br />
 of the User, in order to facilitate navigation on the Site and to offer him the pages that best correspond to his interests on subsequent visits to the Adrenalinika site;<br />
 • Personal data: designate all the information that makes it possible to identify, directly or indirectly, a natural person (surname, first name, e-mail address, etc.);<br />
 • Experience: The Experiences/Services are divided into different formats: the "Gift Box" or "GiftBox", the "Voucher", the "Gift Card":<br />
 1. "Gift box" or "GiftBox", is presented in physical format with a box that contains a key ring signed Adrenalinika<br />
 Experience, as well as a paper guide presenting the conditions<br />
 of Adrenalinika srl.<br />
 2. "Backpack/Sacca Adrenalinika" is presented in physical format as well as in<br />
 fabric and often used as a backpack/sports bag;<br />
 3. "Voucher" as a virtual version of the Gift Box.<br />
 4. “Gift Card” corresponding to an electronic version, containing<br />
 a unique code that can only be used on the online site to purchase an Experience/Service.<br />
 • Service: designates the service provided by the Partner to the Beneficiary among the selection of offers contained in the corresponding Experience/Service. The services present content of various kinds which can be indicated as Experiences/Services (e.g. Parachute jump, Hot air balloon flight, Quad excursion, Dinner and night in a mountain hut on a snowmobile, Rafting, etc.)<br />
 • Site: designates the Adrenalinika website, with the following link https://www.adrenalinika.com/<br />
 • User: designates the person who browses or connects to the Site, without necessarily being a Purchaser or a Beneficiary.<br />
 </p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">2. ORDER AND CONTRACT CREATION</span>
 <p>2.1 The Purchaser can make online Purchases of Experiences/Services directly via the Internet on the website www.adrenalinika.com<br />
 2.2 The procedure for placing orders on the Site involves the following steps:<br />
 • The Purchaser selects one or more Experiences/Services on the Site;<br />
 • If necessary, the buyer selects the option of<br /> using a button
 make a reservation in advance based on the availability on the calendar of each individual experience/service. You can choose to continue the purchase and book the experience/service even later, within 12 months from the date of purchase;<br />
 The Purchaser will then be invited to choose the format of his Experience/Service and the delivery methods (only for physical Boxes or Backpack/Adrenalinika Bag). The Purchaser will also have the option of entering a Promotional Code if necessary;<br />
 To continue the order, the Purchaser can proceed without having to create an account, by filling in the fields necessary to send the order, or<br />
 you can create or connect to your account by clicking on the link provided for this purpose;<br />
 2.3 The creation of an account automatically gives access to a Personal Area, which allows the Purchaser to manage:<br />
 • Account information<br />
 • Change of password<br />
 • Management of active/expired/used vouchers<br />
 • Management of active or past bookings<br />
 • Active/expired codes used for Gift Box or Adrenalinika Backpack/Bag<br />
 • Logout<br />
 • Orders<br />
 • Any Experiences/Services<br />
 • Personal information indicated in the dedicated space<br />
 2.4 The Purchaser guarantees, in this regard, that all the information he will provide to Adrenalinika srl will be correct and free from misleading information.<br />
 The Purchaser undertakes to update his data, through his personal space in the event of a change.<br />
 2.5 The Purchaser is informed and accepts that the information entered for the purpose of creating or updating his account are valid as proof of his identity and commit him since their confirmation.<br />
 The Purchaser undertakes to maintain the confidentiality of the data relating to his account. Any intervention carried out with the Purchaser's personal registration data will be considered carried out by the latter and the Purchaser remains solely responsible. The Purchaser therefore expressly relieves Adrenalinika srl from any liability in the event of abusive use by third parties of their identification elements and/or their account.</p>

 <p> In the event of loss, theft or any fraudulent use of his username and/or password, the Purchaser must immediately inform Adrenalinika srl in order to obtain a replacement.<br />
 After selecting the order, the buyer will check the details of his order and related changes to be made before final registration. The verification of this order refers to elements such as the content, the choices and the total price of the selected Experiences/Services, the details, any shipping costs as well as the payment method.<br />
 The registration of the order will be made after acceptance of these "Terms and Conditions". The sale will be considered definitive only after the acceptance of the means of payment used and the collection by Adrenalinika srl.<br />
 2.6 For payments by bank card, the Experience/Service Electronic Voucher will be considered officially terminated after the Bank has accepted the payment.<br />
 Adrenalinika srl reserves the right to cancel or refuse orders from the Purchaser in which a dispute arises relating to the payment of a previous order that has remained unpaid, or in the event of serious suspicion of a fraudulent order.<br />
 2.7 After the definitive confirmation of the order, Adrenalinika srl will send an e-mail confirming the order to the Purchaser, which includes all the relative information required by the Experience/Service. By printing and/or keeping this e-mail, the Purchaser will have proof of the order placed. In the case of a Voucher/Gift Card or Gift Box order, this order confirmation e-mail must be kept<br />
 by the Purchaser and, if necessary, be forwarded to the Beneficiary. This email will be requested by Adrenalinika srl and/or Partners as a means of proof of the Purchase in case of non-activation problems of the Experience/Service.</p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">3. PAYMENT AND PRICES EXPERIENCES</span>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">2. ORDER AND CONTRACT CREATION</span>
 <p>2.1 The Purchaser can make online Purchases of Experiences/Services directly via the Internet on the website www.adrenalinika.com<br />
 2.2 The procedure for placing orders on the Site involves the following steps:<br />
 • The Purchaser selects one or more Experiences/Services on the Site;<br />
 • If necessary, the buyer selects the option of<br /> using a button
 make a reservation in advance based on the availability on the calendar of each individual experience/service. You can choose to continue the purchase and book the experience/service even later, within 12 months from the date of purchase;<br />
 The Purchaser will then be invited to choose the format of his Experience/Service and the delivery methods (only for physical Boxes or Backpack/Adrenalinika Bag). The Purchaser will also have the option of entering a Promotional Code if necessary;<br />
 To continue the order, the Purchaser can proceed without having to create an account, by filling in the fields necessary to send the order, or<br />
 you can create or connect to your account by clicking on the link provided for this purpose;<br />
 2.3 The creation of an account automatically gives access to a Personal Area, which allows the Purchaser to manage:<br />
 • Account information<br />
 • Change of password<br />
 • Management of active/expired/used vouchers<br />
 • Management of active or past bookings<br />
 • Active/expired codes used for Gift Box or Adrenalinika Backpack/Bag<br />
 • Logout<br />
 • Orders<br />
 • Any Experiences/Services<br />
 • Personal information indicated in the dedicated space<br />
 2.4 The Purchaser guarantees, in this regard, that all the information he will provide to Adrenalinika srl will be correct and free from misleading information.<br />
 The Purchaser undertakes to update his data, through his personal space in the event of a change.<br />
 2.5 The Purchaser is informed and accepts that the information entered for the purpose of creating or updating his account are valid as proof of his identity and commit him since their confirmation.<br />
 The Purchaser undertakes to maintain the confidentiality of the data relating to his account. Any intervention carried out with the Purchaser's personal registration data will be considered carried out by the latter and the Purchaser remains solely responsible. The Purchaser therefore expressly relieves Adrenalinika srl from any liability in the event of abusive use by third parties of their identification elements and/or their account.</p>

 <p> In the event of loss, theft or any fraudulent use of his username and/or password, the Purchaser must immediately inform Adrenalinika srl in order to obtain a replacement.<br />
 After selecting the order, the buyer will check the details of his order and related changes to be made before final registration. The verification of this order refers to elements such as the content, the choices and the total price of the selected Experiences/Services, the details, any shipping costs as well as the payment method.<br />
 The registration of the order will be made after acceptance of these "Terms and Conditions". The sale will be considered definitive only after the acceptance of the means of payment used and the collection by Adrenalinika srl.<br />
 2.6 For payments by bank card, the Experience/Service Electronic Voucher will be considered officially terminated after the Bank has accepted the payment.<br />
 Adrenalinika srl reserves the right to cancel or refuse orders from the Purchaser in which a dispute arises relating to the payment of a previous order that has remained unpaid, or in the event of serious suspicion of a fraudulent order.<br />
 2.7 After the definitive confirmation of the order, Adrenalinika srl will send an e-mail confirming the order to the Purchaser, which includes all the relative information required by the Experience/Service. By printing and/or keeping this e-mail, the Purchaser will have proof of the order placed. In the case of a Voucher/Gift Card or Gift Box order, this order confirmation e-mail must be kept<br />
 by the Purchaser and, if necessary, be forwarded to the Beneficiary. This email will be requested by Adrenalinika srl and/or Partners as a means of proof of the Purchase in case of non-activation problems of the Experience/Service.</p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">3. PAYMENT AND PRICES EXPERIENCES</span>
 <p>3.1 The prices of the Experiences/Services displayed on the adrenalinika.com Website are those in effect on the day the order is placed. The Refund is possible only and exclusively for the purchase of the open date Electronic Voucher, therefore it is not possible if the Purchaser has already booked a calendar date, present on the online site.</p>

 <p> Adrenalinika srl will not issue any refund to Buyers who have not paid for the service for which a refund is requested. Refunds cannot be requested for purchases paid for with Corporate Welfare Credit. The cost of the Gift Box or the Adrenalinika Backpack/Bag will not be refunded.<br />
 The following will be deducted from the refund amount:<br />
 – from Adrenalinika srl the amount of € 15.00 for administrative expenses and reimbursement management;<br />
 – the rate automatically withheld by the payment platform used by the customer during the purchase (PayPal, Stripe, Scalapay), which varies from 1.6% to 3% based on the order amount.<br />
 This refund does not apply to online purchases made using an Adrenalinika srl Gift Card credit.<br />
 3.5 The customer has the right to withdraw from the contract pursuant to article 52 of the consumer code (Legislative Decree 6 September 2005, n. 206 - Updated to 11 May 2019), communicating his will by registered letter with return receipt to be sent strictly within 14 working days from the date of purchase.<br />
 Upon receipt of the Registered Letter, Adrenalinika srl will refund within 15/30 working days, through direct communication to the customer. The refund will be made by bank transfer or by the payment method used by the customer during the purchase (PayPal, Stripe, Scalapay).<br />
 There are no refunds in the following cases:<br />
 • Booking of the experience online. In this case the article will be considered<br />
 used and it will not be possible to request a return;<br />
 • Suspension or cancellation of events due to force majeure<br />
 (pandemic, runway unusable, bad weather forecast, etc) and Customer safety. In this case, independent responsibility is considered by the Purchaser, to whom alternative proposals will be indicated.<br />
 3.6 The refund request by registered letter with return receipt must contain:<br />
 1. Name and Surname of the Purchaser;<br />
 2. Address of Residence (Street, House number, Zip Code, City, Province);<br />
 3. Order Number and Voucher Code (contained in the purchase confirmation e-mail);<br />
 4. Date of purchase;</p>

 <p> 5. Telephone and e-mail address;<br />
 6. Buyer's bank details (Bank, IBAN and current account holder); 7. Signed photocopies of the Purchaser's documents front and back (Identity Card, Tax Code).<br />
 and must be sent to the following address: Adrenalinika srl<br />
 Via Canturino, 22<br />
 22070 Vertemate con Minoprio (CO)</p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">4. BOOKING PROCESS</span>
 <p>4.1 Through the booking process, the Purchaser will be able to choose the Experience/Service within the online site, generating an Electronic Voucher to be used within a specific time frame. Through the choice and purchase of the chosen Experience/Service, the Purchaser will be able to define a specific date on the calendar entered on the Adrenalinika srl online site, thus generating a booking request to the Partner where the Experience /Service will be provided.<br />
 4.2 Having accessed the Website online, the Purchaser will be able to access the Experience/Service by selecting the relevant Place, Type or Category of Experience/Service from the Adrenaline Activities, with subsequent definition of the preferred package.<br />
 In this regard, the Purchaser will be able to manage his own purchase of the Experience/Service in the following ways:<br />
 • Through the Electronic Voucher Purchase option: after choosing the Experience/Service, the Purchaser will be able to proceed with the definition of the number of Participating Guests and with the selection of the optional Backpack/Adrenaline Bag or Gift Box options, to then proceed to the Purchase of the Electronic Voucher. At this stage, you will be asked to enter personal data and the identification details required for payment, so as to allow the authorization, which will be carried out through a secure payment system. The insertion of the identification details of the payment cards entails, by Adrenalinika srl, a confirmation of the charge of the expense to the Purchaser.</p>

 <p> During the compilation during the payment phase, the Purchaser is required to provide Adrenalinika srl with all the information requested, with responsibility for the truthfulness of the aforementioned information.<br />
 At the end of the payment, the Purchaser will receive by e-mail a confirmation regarding the purchase of the Electronic Voucher, which contains an identification code, necessary to proceed to the reservation request given, followed by a direct link connecting to the site<br /> con cui l’Acquirente potrà accedere, inserire le proprie credenziali tali come Username, Password e Codice identificativo e procedere alla scelta di una data a calendario, elaborando cosi una richiesta di prenotazione.<br /> The Partner has 48 hours from the time of receipt of the Booking Request to accept or expressly refuse it. The Partner is always required to provide feedback to the Purchaser, both in the case of positive or negative confirmation for the booking request. In the event of no reply from the Partner within 48 hours, there will be another 24 hours to wait: at the end of the aforementioned, the outcome is to be considered automatically negative, therefore the booking request by the Purchaser will systematically canceled and a new request will be made, always selecting a preferred date on the calendar, based on the availability provided.<br />
 In the event that the Booking Request is accepted, the provision of the chosen Service/Experience is definitively stipulated, through a notification of receipt sent by e-mail containing the summary of the booking in detail.<br />
 In the event that the Booking Request is refused, the Partner and/or Adrenalinika srl reserves the right to offer the Purchaser an alternative date or time, through a new booking proposal, to be understood as a counter-proposal to the Formulated Booking Request by the Buyer.<br />
 The Purchaser has the possibility to choose whether to accept or refuse the Alternative Proposal offered to him, with consequent booking confirmation via notification sent by e-mail. It should be understood that the Activities offered by the Partners within the Site are subject to prior verification by the Partners of availability. The lack of availability by the Partner, for the first booking request made by the same Buyer, will allow the Buyer to agree on a new date among those available in the future, with consequent confirmation of</p>

 <p> negative or positive outcome by the Partner and any booking confirmation or new alternative date proposal. In the event of a negative outcome from the Partner, the Purchaser will be able to accept a new date proposal selected by the Partner, or, once the booking has been cancelled, he will be able to choose to intervene on his purchase through an Experience/Service Change.<br />
 • Through the Experience/Service Booking option:<br />
 after choosing the Experience/Service, the Purchaser can proceed with the selection of the optional Backpack/Adrenalin Bag or Gift Box/Gift Box options, and then select a preferred date, from the options proposed on the calendar.<br />
 At this point the Purchaser can activate a booking request by filling in the data requested subsequently on the Experience/Service Details page. In this phase, the Purchaser is required to provide the Partner with the requested information, including date, time and number of Participants.<br />
 Once the Booking Request has been completed, the Purchaser will enter the identification details required for the payment, so as to allow the authorization, which will be carried out through a secure payment system. The insertion of the identification details of the payment cards entails, by Adrenalinika srl, a confirmation of the charge of the expense to the Purchaser.<br />
 During the compilation during the payment phase, the Purchaser is required to provide Adrenalinika srl with all the information requested, with responsibility for the truthfulness of the aforementioned information.<br />
 At the end of the payment, the Purchaser will receive by e-mail a confirmation regarding the reservation request made, which contains an identification code, necessary to view the details of the Experience/Service purchased on the site line, followed by a direct link to the online site, with which the Buyer can log in, enter their credentials such as Username, Password and Identification Code and proceed to read the details of the Experience/Service or view of your personal purchase history. The Partner has 48 hours from the time of receipt of the Booking Request to accept or expressly refuse it. The Partner is always required to provide feedback to the Purchaser, both in the case of positive or negative confirmation for the booking request. In case of</p>

 <p> failure to reply from the Partner within 48 hours, the outcome is to be considered automatically negative.<br />
 In the event that the Booking Request is accepted, the provision of the chosen Service/Experience is definitively stipulated, through a notification of receipt sent by e-mail containing the summary of the booking in detail.<br />
 In the event that the Booking Request is refused, the Partner and/or Adrenalinika srl reserves the right to offer the Buyer a date or a<br />
 alternative times, through a new booking proposal, to be understood as a counter-proposal to the Booking Request formulated by the Purchaser.<br />
 The Purchaser has the possibility to choose whether to accept or refuse the Alternative Proposal offered to him, with consequent booking confirmation via notification sent by e-mail. It should be understood that the Activities offered by the Partners within the Site are subject to prior verification by the Partners of availability. The lack of availability by the Partner, for the first booking request made by the same Buyer, will allow the Buyer to agree on a new date among those available in the future, with consequent confirmation of negative or positive outcome by the Partner and eventual confirmation reservation or new proposal for an alternative date. In the event of a negative outcome from the Partner, the Purchaser will be able to accept a new date proposal selected by the Partner, or, once the booking has been cancelled, he will be able to choose to intervene on his purchase through an Experience/Service Change.<br />
 4.3 Adrenalinika srl offers the Purchaser the possibility to request a Change of the booked date (means a confirmed date, therefore after the 48 hours dedicated to the confirmation of the booking request), directly within the online site in the dedicated section, by accessing the "profile" section using your credentials. The aforementioned modification request will be processed only after having checked the terms and conditions relating to the Cancellation Policy belonging to the Partner for whom the purchased Experience/Service is provided. In this case, the Purchaser may, upon payment of a single penalty equal to Euro 15.00 to be entitled to the cancellation of the chosen date and the subsequent rescheduling of a new date, by contacting Adrenalinika srl Customer Assistance directly. <br />
 The cancellation request by the Purchaser will always be subject to a cancellation fee. If the cancellation policies indicated by the Partner in the description of the Experience/Service are not respected, the request to change the date will not be accepted, therefore the Purchaser will not have the right to a refund of the Experience/Service purchased but will eventually be able to intervene on the modification of the Experience/Service through an exchange<br />
 Experience/Service or an exchange for a Gift Card.<br />
 Changing the date, at the explicit request of the Purchaser, does not in any way disregard the request for cancellation of the Experience/Service by the Partner: it is possible, always upon payment of the cancellation penalty and always within and no later than the terms of cancellation defined by the Partner, cancel and reschedule the date without a defined time limit.<br />
 All information relating to confirmation, cancellation, programming or rescheduling of the date, always carried out by the Purchaser and for essential reasons by the Partner, will be communicated via telephone, social media or e-mail, in order to guarantee the use of such information in a constant and coherent manner towards the Purchaser, maintaining the adequate timing required.</p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">5. DELIVERY</span>
 <p>5.1: Adrenalinika srl sends the Adrenalinika Gift Boxes or Rucksack/Bag only within the Italian territory by Express Courier GLS or BRT. The Gift Boxes or Adrenalinika Backpack/Bag will be sent to the address indicated by the Purchaser at the time of his online Purchase, with processing of the same by 11:00 am from Monday to Friday. All shipping orders of the Gift Box or Adrenalinika Backpack/Bag received between Saturday and Sunday will be processed on the following Monday and will take effect from Tuesday as the effective shipping day. In case of errors regarding the entry of incorrect data (delivery and shipping address) by the Purchaser, Adrenalinika srl cannot be held responsible. In the event of the absence of the recipient of the gift box or backpack/bag at the time of delivery by the Courier, Adrenalinika srl cannot be held responsible. However, the Buyer may contact Adrenalinika srl for a new shipment at the Buyer's expense.<br /><br />
 5.2: The maximum delivery time is 7 working days from the order confirmation. The Purchaser will receive an e-mail notification of the order being sent which indicates the transport reference number, in order to trace the route of the order being shipped.<br />
 For information purposes, starting from the receipt of the shipping confirmation e-mail, the average delivery time, as communicated by the<br />
 transport, is estimated as follows within 2 and 3 working days on the Italian territory. 5.3: Shipping costs:<br />
 Shipping costs charged to Adrenalinika srl are flat rates and are invoiced in addition to the cost of the Adrenalinika Gift Box and Rucksack/Bag, at a cost of 6.00 Euros including VAT and subject to specific promotions on shipping costs. Electronic Vouchers or Gift Cards are delivered by e-mail and do not involve any additional delivery costs.<br />
 5.4: Deterioration and Missing Items:<br />
 Each gift box or backpack/Adrenalinika bag sent must be subject to meticulous checks by the recipient. In the event of a damaged or missing Gift Box or Backpack/Bag, the recipient must indicate the problem directly on the Transport Note and subsequently confirm the reservations made upon delivery, by Registered Letter or Pec within 72 working hours from receipt< br />
 of the disputed order. In the absence of communication of one of the terms indicated above, the order will be considered as delivered correctly and in good condition, therefore it cannot be object of any dispute against Adrenalinika srl.</p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">6. ACTIVATION OF EXPERIENCES/SERVICE</span>
 <p>6.1: The Experiences/Services are activated at the time of payment and will have the following activation methods:<br />
 • If the Experience/Service was purchased online, activation will be immediate. With online purchase, we mean the creation of a Voucher</p>

 <p> Electronic open date, or creating a request for<br />
 booking a specific date.<br />
 • Unactivated Experiences/Services cannot be used. Just in case<br />
 of activation problems, the proof of purchase of the Experience/Service (order confirmation e-mail or Paper Electronic Voucher with relative purchase identification code) must be communicated via social channels, e-mail or phone call, to the Customer Service of Adrenalinika srl.<br />
 In this regard, in case of purchase of an Adrenalinika Gift Box or Bag/Backpack, Electronic Voucher or Gift Card that has not yet been activated, Adrenalinika srl reserves the right not to proceed with any complaint related to the activation problem itself.<br />
 6.2: Using the GIFT CARD:<br />
 The Gift Card allows you to choose an Experience/Service free of charge in PDF format, to be selected from the range of Experiences/Services offered on the online site.<br />
 If the Beneficiary wishes to receive an Experience/Service in physical format "Gift Box/Gift Box or Backpack/Adrenalinika bag or other extras", he will suffer the application of additional expenses relating to the cost of each single element indicated above, regardless of the Experience/ Selected service.<br />
 If the selected Experience/Service has a cost higher than the economic availability of the Gift Card, the Beneficiary will have the possibility to use the Gift<br />
 Card and finalize the order after choosing the Experience/Service, paying the difference indicated with another payment method among the options on the online site.<br />
 The Adrenalinika Gift Card product:<br />
 • It can be used to purchase one or more Experiences/Services offered on our online site, up to the amount;<br />
 • It cannot be used in conjunction with another Adrenalinika Gift Card;<br />
 • It has no periodic expiry;<br />
 <br />
 6.3: Loss and Theft Guarantee:<br />
 In the event of loss or theft of the Adrenalinika Electronic Voucher, Gift Card or Gift Box/Gift Box and Rucksack/Bag and, provided that the latter have not been used in the meantime, the Beneficiary may contact the Adrenalinika srl Customer Service at order to request the replacement of the lost or stolen Voucher/Gift Card, for an amount equal to the purchase price of the lost or stolen Voucher/Gift Card, without payment of commissions.<br />
 6.4: Adrenalinika srl does not apply substitutions in the event of loss or theft of the Adrenalinika Gift Box and Backpack/Bag, given the direct management of shipments by the Express Courier.<br />
 Attention: if the lost or stolen Voucher/Gift Card has been used before requesting a replacement, no exchange will be possible.<br />
 The Beneficiary is required to take all necessary measures to keep the Purchase Code accurately, in order to avoid fraudulent use.</p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">7. PARTNERS</span>
 <p>7.1: The Voucher entitles you to different Services from one Partner to another; the reservation can be made directly on the adrenalinika.com web portal in the specific Redemption area section or in advance when choosing and purchasing the experience/service.<br />
 The content of the Performances to the Experience/Service provided may be subject to change. Adrenalinika undertakes to propose alternative solutions to the Beneficiary.<br />
 The photographic and editorial content presented in the Experiences/Services on the online site is to be considered indicative and may be subject to changes, even after what was defined in the contractual phase.<br />
 In case of any anomalies with respect to the state of the Partners' structures, Adrenalinika srl will not charge any type of responsibility.<br />
 Adrenalinika srl prohibits any partial or total reproduction of the content of the Experiences/Services, reminding the Partner that they are responsible for the correct execution of the Performance of their Experiences/Services. If necessary, Adrenalinika srl will do its best to try to find an amicable solution to the disputes between the Beneficiary and the Partner.<br />
 It should also be noted that Adrenalinika srl cannot be held responsible for any losses/damages/thefts that the Beneficiary suffers during the Experience. The Beneficiary is invited to contact the Partner in case of</p>

 <p> any dissatisfaction with the Performance.<br />
 7.2: Notwithstanding the foregoing, in the event of difficulties related to the execution of the Service by the Partner, the beneficiary will have the possibility to contact Adrenalinika srl indifferently, which will be liable within the limits and under the conditions established by the applicable law in the event of contractual breach in the execution of said Services.<br />
 7.3: Availability and Provision of Partner Offerings:<br />
 Given that Adrenalinika srl cannot guarantee the continuous availability of the offers of the Partner chosen by the Beneficiary, the Beneficiary is strongly advised to go to the Site as soon as possible and consult the list of Partners in order to make a choice and book the Service as soon as possible .<br />
 Unless otherwise indicated, the Voucher is valid every day of the week, including weekends, according to actual availability, days and opening hours provided directly by the Partner.<br />
 The supply of the chosen Service will be possible only upon reservation and subsequently confirmed after checking by the Partner regarding the validity of the order number of the Electronic Voucher.<br />
 7.4: Partner Insurance:<br />
 The Partners selected by Adrenalinika srl have declared to the latter that they hold professional civil liability insurance for amounts<br />
 sufficient for the provision of the Services, to possess all the authorizations and titles that allow them to carry out their business on a regular basis and in any case to comply with the legislative and regulatory provisions applicable to them. However, the Beneficiary is invited to acquire sufficient insurance coverage, in particular for the practice of some so-called "risky" sports. The Beneficiary is reminded that the provision of the Services in good safety conditions does not dispense him from observing the elementary rules of prudence for sporting activities, in particular those known as "at risk". The Beneficiary accepts the consequent risks.<br />
 </p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">8. EXPERIENCE/SERVICE</span>
 <p>8.1: The Experiences/Services offered on the online site can usually be valid for 12 months from the purchase. Adrenalinika srl reserves the right to integrate and/or modify or cease the list of Experiences/Services or their dissemination, at its sole discretion and at any time. In the event of an interruption in the marketing of an Experience/Service before the final date of validity, Adrenalinika srl will offer the Beneficiary an Alternative Intervention method<br />
 on the Electronic Voucher, with the exception of Gift Cards. 8.2: Methods of exchanging Experiences<br />
 In any case, the exchange is reserved exclusively for Experiences/Services for which the voucher or order number has not been used. The change is made only after the purchase of an open date Experience/Service, therefore it will not be possible if a date has already been booked on our online site.<br />
 Except for the exercise of the right of withdrawal recognized by law in the case of online purchases, the Gift Card is never refundable. Exchanges are always made according to the purchase value of the Experience to be exchanged, or according to the price actually paid by the Purchaser and according to the purchase date, even if the cost includes discounts or promotions applied until that date. The exchange allows you to benefit from a new Experience/Service, the validity period of which will be equal to the duration of the residual validity of the Experience/Service exchanged. Adrenalinika srl reserves the right to<br />
 accept an extension of the validity of the Experience/Service, upon payment of a penalty amount corresponding to the value of the original purchase amount of the Experience/Service.<br />
 8.3: It is possible to change more than one Experience/Service with a new Experience/Service, by paying any price difference that remains the responsibility of the Beneficiary, directly to Adrenalinika srl, by bank transfer.<br />
 If the Purchaser chooses to exchange the Experience/Service purchased with an Experience/Service of a lower amount than the original purchase, Adrenalinika assumes no responsibility for the refund or integration of the difference amount, which will be systematically lost.</p>

 <p> The change of Experience is free if requested within 6 months from the expiry date of the original Voucher, otherwise it is subject to the following penalties: – Voucher amount from €0.00 to €150.00 = penalty of 20 % of the voucher amount + VAT<br />
 – Voucher amount from €151.00 to €350.00 = penalty of 15% of the voucher amount + VAT<br />
 – Amount of the voucher from €351.00 = penalty of 10% of the amount of<br />
 voucher + VAT<br />
 These exchange costs are subject to Italian VAT at the rate applicable on the day of exchange.<br />
 Adrenalinika srl reserves the right to change an Experience/Service free of charge only if the request is made within 6 months of the purchase by the Purchaser and only after consulting with the Partner regarding any indisposition in the implementation of the experience, due to causes totally independent of the Buyer.<br />
 It is reiterated that the change of an Experience/Service can only be made no later than the expiry date of the Electronic Voucher of the chosen Experience/Service, therefore Adrenalinika srl assumes no responsibility for requests made after the set deadline : in this case we define the systematic cancellation of the Electronic Voucher with the consequent impossibility of rescheduling the Experience/Service.<br />
 8.4: Electronic Voucher Extension Method:<br />
 The renewal of the Experiences/Services may be subject to the payment of an additional price. The voucher will be extended for a further 6 months after the original expiry date.<br />
 Renewal is free if requested within 6 months of the expiry date of the original Voucher, otherwise it is subject to the following penalties: – Voucher amount from €0.00 to €150.00 = penalty of 20% of the amount of the voucher + VAT<br />
 – Voucher amount from €151.00 to €350.00 = penalty of 15% of the voucher amount + VAT<br />
 – Voucher amount from €351.00 = penalty of 10% of the voucher amount + VAT</p>

 <p> These exchange costs are subject to Italian VAT at the rate applicable on the day of exchange.<br />
 Adrenalinika srl reserves the right to extend an Experience/Service free of charge only if the request is made within 6 months of the purchase by the Purchaser and only after consulting with the Partner regarding any indisposition in the implementation of the experience, due to<br />
 causes totally independent of the Buyer.<br />
 It is reiterated that the extension of an Experience/Service can only be made no later than the expiry date of the Electronic Voucher of the chosen Experience/Service, therefore Adrenalinika srl assumes no responsibility for requests made after the set deadline : in this case we define the systematic cancellation of the Electronic Voucher with the consequent impossibility of rescheduling the Experience/Service.<br />
 8.5: GIFT CARD/Gift Experience VOUCHER activation method<br />
 Following the purchase by a User and/or Purchaser of a Gift Card, with the consequent purchase linked to the delivery of the Adrenaline Bag/Backpack or Gift Box, it will be possible to manage the reservation by following the instructions contained in the Gift Box delivered/purchased or inside the delivered/purchased Adrenalinika Backpack/Bag.<br />
 The generation of the purchase by the User or Buyer on the site systematically involves the creation of a notification e-mail which indicates the details of the purchase and the relative Voucher Code or Booking Code of a given Experience/Service: the same Code is shown in the description of use inside the Backpack/bag or Gift Box received.</p>

 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10"> 9. CANCELLATION OF THE EXPERIENCE/SERVICE</span>
 <p>9.1: Adrenalinika srl is an intermediary between customers and business managers (our Partners). After a reservation through Adrenalinika srl, a payment is generated and completed directly to the same and not to the structure that will guarantee the provision of the chosen Experience/Service.<br />
 Adrenalinika srl of each reservation generated and each purchase completed: indicate with completed purchase a payment concluded with receipt of<br />
 confirmation for the booking request, sent to the buyer by e-mail.<br />
 9.2: Considering that the purchase of an open date electronic Voucher and the purchase of an Experience/Service with a choice of date on the calendar constitute a completed reservation to all intents and purposes, there are cancellation terms and conditions, which vary according to to purchasing circumstances as follows:<br />
 • If the Purchaser purchases an Experience/Service with a booking request for a date chosen on the calendar, he will have to wait for confirmation from the establishment within 48 hours of the request being made: if the outcome is negative, the Purchaser may choose to reschedule a date always on the calendar, directly on our online site based on the availability indicated, or you can request the cancellation of the booking request.<br />
 Cancellation in this case will be possible, through the provision of the Refund of the Experience/Service purchased, only within 14 days of the purchase; otherwise and therefore exceeded the 14 days from<br />
 Once the purchase has been made, Adrenalinika srl will provide alternative solutions to intervene on the Experience/Service, through the Experience/Service Change or Experience/Service Change in a Gift Card.<br />
 The cancellation of the Experience/Service is possible only and exclusively if the booking request is not accepted, with regard to circumstances independent in all respects from the Purchaser, but therefore dependent only on the Partner or on the conditions linked to the impossibility of carrying out the aforementioned Experience/Service.<br />
 If the request for cancellation of the Experience/Service is made, by the Purchaser and therefore for reasons independent of the Partner but dependent solely and exclusively on the Purchaser, before the chosen and confirmed date of the Experience/Service to be performed.</p>

 <p> the Purchaser will be able to reschedule another date of his preference on the calendar, among those available, only and exclusively if the cancellation request is made within the times indicated by the Partner in the description of the Experience/Service on our site, in the Cancellation Rules section of each package, in relation to the Experience/Service purchased.<br />
 If the request for cancellation of the Experience/Service is made within the date of the booking, but beyond the terms of<br />
 cancellation indicated by the Partner, the Purchaser will be able to reschedule a new date of his preference in the calendar, among those available, free of charge only and exclusively for a single cancellation request. It is therefore understood that starting from the second cancellation request by the Purchaser, it will be possible to reschedule a new preference date on the calendar, among those available, upon payment of a Cancellation Penalty of Euro 15.00.<br />
 Note that the Terms and Conditions relating to the Cancellation rules for each individual Experience/Service may vary from one Partner to another and from one Experience/service to another Experience/Service, therefore they may also be non-existent and therefore with the impossibility cancellation of the chosen Experience/Service.<br />
 It is understood that all cancellation requests made after the required deadlines will not be accepted or taken into consideration, or rejected with the consequent obligation on the part of the Purchaser to lose the Experience/Service or Change the Experience/Service and/or Transform it into a Gift Card. The change of experience/service and/or transformation into a gift card requires the payment of the penalty as above.<br />
 • If the Purchaser purchases an open date Electronic Voucher, he will receive a Booking Code by e-mail, with which he can choose a calendar date among those available, provided by the Partner directly on our online site: at that point the Purchaser will make a booking request for the chosen date and I will wait for confirmation from the structure within 48 hours of the request: if the outcome is negative, the Purchaser can choose to reschedule a date always on the calendar, directly on our site online based on the availability indicated, or may request the cancellation of the booking request.<br />
 Cancellation in this case will be possible, through the provision of the </p>

 <p> Refund of the Experience/Service purchased, only within 14 days of the purchase; otherwise and therefore after 14 days have passed since the purchase, Adrenalinika srl will provide alternative solutions to intervene on the Experience/Service, through the Experience/Service Change or Experience/Service Change in a Gift Card.<br / >
 The cancellation of the Experience/Service is possible only and exclusively if the booking request is not accepted, in<br />
 regarding circumstances independent in all respects from the Purchaser, but therefore dependent only on the Partner or on the conditions linked to the impossibility of carrying out the aforementioned Experience/Service.<br />
 If the request for cancellation of the Experience/Service is made by the Purchaser and therefore for reasons independent of the Partner but dependent solely and exclusively on the Purchaser, before the chosen and confirmed date of the Experience/Service to be performed, the The Buyer will be able to reschedule another date of his preference on the calendar, among those available, only and exclusively if the cancellation request is made within the timescales indicated by the Partner in the description of the Experience/Service on our site, in the Rules section. Cancellation of each package, in relation to the Experience/Service purchased.<br />
 If the request for cancellation of the Experience/Service is made within the booking date itself, but beyond the cancellation terms indicated by the Partner, the Purchaser will be able to reschedule a new date of his preference on the calendar, among those available, free of charge only and exclusively for a single request of<br />
 cancellation. It is therefore understood that starting from the second cancellation request by the Purchaser, it will be possible to reschedule a new preference date on the calendar, among those available, upon payment of a Cancellation Penalty of Euro 15.00.<br />
 Note that the Terms and Conditions relating to the Cancellation rules for each individual Experience/Service may vary from one Partner to another and from one Experience/service to another Experience/Service, therefore they may also be non-existent and therefore with the impossibility cancellation of the chosen Experience/Service.<br />
 It is understood that all cancellation requests made after the required deadlines will not be accepted or taken into consideration, or rejected with the consequent obligation on the part of the Purchaser to lose the Experience/Service or Change the Experience/Service and/or Transformation </ p>

 <p> into a Gift Card. The change of experience/service and/or transformation into a gift card requires the payment of the penalty as above.<br />
 9.3: Cancellation by Partners<br />
 In case of circumstances that make the activity unsafe or impracticable, such as particularly severe weather conditions,<br />
 or problems related to the logistics of the means available, or the impossibility of carrying out the Experience/Service with maximum security for the Buyer, the Partner may decide to cancel the activity. As it is not always possible to predict these events in advance, cancellation may occur at any time.<br />
 In this case it will be Adrenalinika srl which, as intermediary between the parties, will provide the Buyer with the following alternative solutions:<br />
 • Cancellation of the Experience/Service purchased, in order to generate a new Experience/Service for an amount equal to or greater than the first purchase made;<br />
 • Cancellation of the Experience/Service purchased, in order to generate an open-dated electronic Voucher, for an amount equal to that of the first purchase;<br />
 • Cancellation of the Experience/Service purchased with relative Reimbursement of the Experience/Service, to which the administrative costs of managing the purchase and expenses relating to the payment method used will be applied.</p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">10. RESPONSIBILITY, ACCESS AND OPERATION OF THE SITE AND THE SERVICE</span>
 <p>10.1: Buyers acknowledge and are aware of the fact that, due to incorrect functioning of the internet and telecommunications systems, it may be impossible to guarantee:<br />
 • The correct operation of the Site and the Service, in particular in terms of interrupted accessibility to the Site, the performance of the Site and the Service such as, for example, the response time for the various requests and actions performed;</p>

 <p> • The security of the Site and the Service, in particular with respect to the presence of computer viruses.<br />
 10.2: Buyers are required to be aware of the above, regardless of the resources employed by the Company, Internet and telecommunications systems, which have technical specifications external to the Company's sphere of domain.<br />
 Buyers are also required to be aware that the Site and the Service<br />
 may be temporarily suspended, particularly in the event of system failures, maintenance, repairs or updates, therefore Adrenalinika srl cannot be held responsible for any damage caused to Buyers as a result of using the Site or the Service and, in particular, any damage resulting from the unavailability of the Site.<br />
 10.3: Scope of the Company's Responsibility<br />
 Adrenalinika srl provides a technical service aimed at putting Users and Partners in communication.<br />
 The Partners are solely responsible towards the Customers and Participants for the correct performance of the Experience/Service offered on the Site, therefore Adrenalinika srl as a third party, will not contribute in any way to regulating its forms, methods and terms of exercise and therefore cannot be held responsible in any way with respect to the total or partial non-compliance of the obligation, and/or poor performance, rendered by a Partner with reference to the performance of an Experience/Service, nor of the correctness of the information provided by the Partner in relation to the characteristics of the Activities and<br />
 conditions of access.<br />
 In the same way, liability on the part of Adrenalinika srl is excluded with respect to the actions committed by the Participants during the execution of the Experience/Service.<br />
 In relation to situations of total or partial non-compliance with the obligation, and/or poor performance, rendered by a Partner, the Purchaser undertakes not to take legal action against Adrenalinika srl, acknowledging its quality as a third party and, therefore, as such extraneous to the relationship in dispute.<br />
 Buyers are personally responsible for the acceptance of the Customer Terms and Conditions by the Participants for whom the Buyer has booked the Activity.</p>

 <p> 10.4: Limitations of Liability<br />
 Adrenalinika srl assumes responsibility only in cases of violation demonstrated by a Purchaser, of its contractual obligations in the execution of the Experience/Service.<br />
 The Company cannot be held liable in the following cases:<br />
 • Acts performed by a Buyer, Participant, or Partner;<br />
 • In cases of force majeure;<br />
 • In the event of acts performed by third parties and in particular malfunctions,<br />
 the impossibility of accessing or difficulty in using the Internet;<br />
 • In the event of any consequential or non-consequential damages relating to<br />
 Experience/Service different from the Experience/Service provided on the online site<br />
 Adrenalinika srl, in compliance with these provisions;<br />
 • In case of damage attributable to the contents, nature, or characteristics<br />
 of the Experience/Service provided by a Partner to a Buyer.</p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">11. CUSTOMER SERVICE AND COMPLAINTS</span>
 <p>11.1: With regard to the purchase, refund, exchange or operation of an Experience/Service or Electronic Voucher on the Adrenalinika srl online site, the Purchaser can make a request via the site www.adrenalinika.com by consulting the "Contact Us" section ” or by writing to<br />
 administration@adrenalinika.com<br />
 11.2: In case of problems with a Partner, Adrenalinika srl undertakes to contact him with the aim of solving the problem on behalf of the Customer, even if not committing himself in a binding way and not guaranteeing the success of the negotiation with absolute certainty.<br / >
 </p>
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">12. COMMITMENTS AND OBLIGATIONS OF THE CUSTOMER</span>
 <p>12.1: Users are required to use the Site and the Service respecting the rights of third parties and in compliance with the rights of the Partners and the Company, therefore they must, when connected to the Site and when using the Service, comply with the applicable regulations .<br />
 12.2: In particular, Users undertake to:<br />
 • Not to use the Site or the Service for purposes unrelated to it, or through systems capable of contravening the applicable regulations;<br />
 • Not to defame, insult or denigrate natural or legal persons;<br />
 • Do not create User Accounts with fictitious identities, or forge your own;<br />
 • Not to cause damage to third parties by using the Site or the Service and, in particular,<br />
 not steal the identity of third parties or, in general, use proprietary data<br />
 third party;<br />
 • Not to use the contractual information obtained through the Site or the<br />
 Service to send unsolicited communications, regardless of form<br />
 used for such communications;<br />
 • Not to use the information obtained through the use of the Site or the<br />
 Service to disseminate computer viruses or similar threats;<br />
 • Not cause damage to the reputation of the Partners or the Company, or to<br />
 brand Adrenalinika srl;<br />
 • Not cause the Site to be interrupted or stopped;<br />
 • Not cause damage, or attempt to cause damage, related to the services provided by<br />
 one or more suppliers or Partners, in particular to the company that supplies the<br />
 hosting service, for example by exposing the Site to computer viruses, creating saturation or flooding of the server, saturating messaging systems with emails, and other similar conduct;<br />
 • Not to access, or attempt to access, information not expressly made available to Users;<br />
 • Not to probe, scan, or test the vulnerability of the system, or violate the Site, the security systems of the Service or the authentication processes, or attempt to gain illegal access to the networks and systems connected to the Site;<br />
 • Do not upload to the Site, send by email or by any other means, any element containing computer viruses or other computer code, files, or programs designed to interrupt, destroy or limit the functionality of any computer, software, hardware or telecommunications equipment .</p>

 <p> 12.3: Responsibilities and Obligations relating to the reservation<br />
 With respect to the booking and performance of the Activities, the Purchaser acknowledges that:<br />
 • Participants will be able to carry out the Activity only if they possess the conditions required for the exercise of the same (for example certificates proving the possession of a correct physical condition, a certain level of ability, as well as additional certificates, licenses or licences, <br />
 required by law); otherwise, the Partner has the right to refuse the Participants' participation in the Activity without deriving a right to reimbursement of the price paid to the Customer;<br />
 • Participants must, with particular reference to the Activity, comply with the rules and instructions dictated in order to allow the Activity to be carried out in optimal safety conditions; otherwise, the Partner has the right to interrupt the performance of the Activity without deriving a right to reimbursement of the price paid to the Customer;<br />
 • Participants must arrive on time at the meeting point indicated in the summary of the Activity. A delay of more than 15 minutes with respect to the time agreed for the execution of the Activity may result in the cancellation of the reservation, in accordance with what is established in the general terms and conditions of the Partner Activity, and indicated in the reservation summary.<br />
 12.4: Buyers are required to ensure the accuracy of the information provided to the Partner when booking the Activity; the falsity of the information provided by the Buyers, if arranged in order to circumvent the limits imposed by the<br />
 Partners for the exercise of the Activity, entitle the Partner to refuse the execution of its service, with the further exclusion of any form of reimbursement. It is also specified that any activity that does not expressly fall within the activity booked is not under the responsibility of the Company or the Partner.<br />
 Adrenalinika srl assumes no responsibility for the documentation concerning the state of health, the possession of patents, certificates, or any other document produced by the Purchaser at the time of booking. The truthfulness of the declarations made is the exclusive responsibility of the Purchaser and/or the Participant, without prejudice to the right of the Partners to request their presentation, at the time of carrying out the Activity subject to the reservation. Consequently, it is expressly specified that Buyers and Participants are solely responsible for booking and participating in the Activities, taking into consideration their level of ability, physical condition, experience, limitations, and</p>

 <p> all the risks deriving from taking part in potentially dangerous sports and non-sporting activities. To this end, neither the Partners nor the Company can be held responsible for injuries, accidents, or death caused by the performance of the Activity, if one of these events results from the false information provided by the Buyer or the Participant.<br />
 12.5: INSURANCE<br />
 Buyers are informed about the possibility of stipulating, for themselves and for<br />
 all Participants, insurance policies for civil liability in Italy and abroad while they carry out the Activities. It is also specified that the Activities offered through Adrenalinika srl, unless expressly specified, do not include any type of insurance.<br />
 It is the responsibility of each Participant to ensure that their insurance coverage is sufficient and, if it proves otherwise necessary, to implement all appropriate measures to make it so, supplementing the existing policy, or entering into an agreement with an insurance company of their choice . On the Site, the Company may list "risky" activities. Buyers are reminded that additional insurance is strongly recommended for this type of business.</p >
 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">13. PROPERTY</span>
 <p>13.1: Site and Service Rights<br />
 The Site, the Service and the information contained therein are protected by the law on intellectual property.<br />
 It follows from this that, with the exception of cases where this is otherwise expressed, the intellectual property rights relating to the documents, as well as all information, of any kind, contained in the Site or relating to the Service, in particular with reference to any element composes the Site and the Service (images, animated or not; illustrations; photographs; sounds; know-how; IT architectures; texts; graphic elements; etc.) including software and databases, are to be considered the exclusive property of the Company and of its Partners (hereinafter, the "Protected Items").</p>
 <p> 13.2: The Company does not grant Users any license with respect to the use of the Protected Elements, all or even only a part, except that relating to their display on the Site and use for the purposes of the Service.<br />
 The reproduction, in whole or in part, of the Site and/or of the Protected Elements may be authorized for information and/or dissemination purposes only, for personal and private reasons. The use in these terms of the Site, or of its Protected Elements, is possible only following the express approval given by the Company following a communication to be addressed to info@adrenalinika.com<br />
 Otherwise, any reproduction, use of copies, regardless of the form and method of use, is to be considered expressly prohibited unless authorized by the Company. Such conduct, in relation to the damage caused to the Company, may constitute an element of assessment for the purposes of civil liability and, if the elements exist, of criminal liability.<br />
 13.3: It is also prohibited:<br />
 • Copy, modify, assemble, sell, or in any way transfer any part of the Secured Elements;<br />
 • Modify, even partially, the Secured Elements to gain unauthorized access to the Service or the Site by any means other than the user interface provided by the Company for this purpose;<br />
 • Access or use the Secure Elements for purposes, principles, or terms of use not expressly permitted by the Company;<br />
 Any reproduction and/or representation, in whole or in part, of the Site, the Service, and the Protected Elements, without the prior and express authorization, to be received in writing, from the Company.<br />
 13.4: Rights concerning distinctive signs<br />
 • Users assign to the Company all intellectual property rights attributable to the Contents generated by them on the Site.<br />
 The rights assigned in this regard include, in particular: the reproduction, representation, transmission, translation, distribution, use and, where appropriate, modification of the contents subject to assignment. The rights assigned may also include those relating to the translation of the media, whatever the type. The transfer of these contents is to be considered free of charge, as a contribution of social importance to the development of the Site, aimed at ensuring greater efficiency of the service as well as better information transparency for other Users, and thus authorizing its publication online immediately. and not, by any means or technical process.</p>
 <p> • Users guarantee that they own the property rights relating to the Contents being published. The possession of the property rights relating to the Contents constitutes a sufficient prerequisite for authorizing their publication. Users are the only ones who can be held responsible for the compliance of such Content with the law. In this regard, Users are responsible for ensuring that the Contents published do not infringe any third-party rights, in particular those relating to intellectual property rights.<br />

 • Users acknowledge the impartiality of the Company with respect to the publications they make on the Site. In any case, where the contents violate the intellectual property rights of third parties, the Company, following a report from third parties or from The Judicial Authority will remove them without delay.<br />
 14. CHANGES<br />
 14.1: Changes to the Site and/or Service<br />
 Users acknowledge and accept the possibility for the Company to freely update or modify their Site and/or Service, at any time and at its sole discretion.<br />
 Any modification made to the Site and/or the Service is subject to the sole discretion of the Company.<br />
 15. CHANGES TO BUYERS TERMS AND CONDITIONS<br />
 15.1: The Company reserves the right to modify, in terms of integration, removal or adaptation, these Terms and Conditions, if this is justified in order to fulfill emerging legislative requirements, implement organizational changes aimed at improving the service or preventing repetition of misconduct by Users when these have not already been governed by these Terms and Conditions or with respect to which the already provided discipline has proved to be ineffective for prevention.<br />
 In any case, the use of the Service and its functions presupposes acceptance of these TCCs, as well as any changes made to them.<br />
 </p>

 <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">16. PROTECTION OF PERSONAL DATA</span>
 <p>16.1: With reference to the respect of the rights and obligations concerning the use and protection of personal data, Users are invited to consult the Privacy Policy accessible online on the Site.<br />
 The Privacy Policy is an integral part of these Customer Terms and Conditions.<br />
 To find out about the characteristics of the personal data protection policy implemented by Adrenalinika srl, the User, the Purchaser and the Beneficiary are expressly invited to read the Privacy Statement which can be consulted on the Website.<br />
 16.2: Cookies<br />
 To find out more about the use of Cookies, the User, the Purchaser and the Beneficiary are expressly invited to read the Adrenalinika srl Information on the use of Cookies available on the online Site.<br />
 The elements that make up the Experiences/service, in particular, but without limitation, texts, photographs, images, icons, logos, as well as the Adrenalinika brand, are the full and exclusive property of Adrenalinika srl. It is strictly prohibited to use in any way, including, without limitation, reproduce, represent, modify, transmit, publish, adapt, in any medium, by any means, or exploit in any way, all or part of these elements without prior <br />
 written authorization from Adrenalinika srl.<br />
 The Site is an intellectual work protected by intellectual property rights. The Site and each element that composes it (such as registered trademarks, logos, texts, etc.) are the exclusive property of Adrenalinika srl, partners or third parties and are protected by way of intellectual property. By accessing the Website online, the User, the Purchaser and the Beneficiary are therefore granted a limited use license for browsing the Website, on a non-exclusive basis and solely for private use. Therefore, the reproduction of documents published on the Site is possible for informational purposes only and for strictly personal and private use.<br />
 Any reproduction or modification, integral or partial, of the Site or of any of the elements that compose it and that belong to is expressly prohibited.</p>

 <p>Adrenalinika srl or to third parties, on any medium whatsoever, for purposes other than personal and private use, and in particular for commercial purposes, unless there is prior written authorization from Adrenalinika srl or third party rights holders of the work . Any operation in violation of the above exposes the User, the Purchaser and/or the Beneficiary to criminal and civil liability.<br />
 16.3: Entire Agreement<br />
 Failure by Adrenalinika srl to exercise, at a given time, one of the clauses of these Terms and Conditions cannot be interpreted as a waiver to subsequently make use of the aforementioned clauses and of the rights and faculties deriving from them. In the event that one of the stipulations of these Terms and Conditions should be declared null or considered ineffective, the validity of the other provisions will not be called into question.<br />
 16.4: Applicable Law and Disputes<br />
 These Terms and Conditions and the contract concluded on the occasion of a purchase of an Experience/Service, including matters concerning their validity, their interpretation, their execution or withdrawal from the same, are subject to Italian law. It should be noted that the online purchase of Experiences is subject, among other things, to the provisions of the Consumer Code, on distance contracts. With regard to any disputes, the Parties undertake before carrying out any legal action, to try to reach an amicable solution in good faith within a period of thirty (30) days. The Purchaser or the Beneficiary must therefore first submit their complaint in writing to the Adrenalinika Customer Service.<br />
 In the event of impossibility to find an amicable agreement, the dispute will be referred to the competent Italian jurisdiction according to the applicable laws in force.<br />
 16.5: Adrenalinika declines all responsibility for any inaccuracies and/or inaccuracies of the information transmitted.<br />
 Any offer contained on the site does not imply any legal obligation for Adrenalinika. Parts of the text or the entire publication, offers included, may undergo changes and/or additions or be eliminated without notice by Adrenalinika.</p>
       </div>
         </div>
       </div>
      </div>
    </div>
  );
};

export default PageRegolamentoEn;
