import SectionHero2 from "components/SectionHero/SectionHeroDue";
import React, { useState, useEffect } from "react";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { Helmet } from "react-helmet";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSliderNewSubCategories from "components/SectionSliderNewCategories/SectionSliderNewSubCategories";
import SectionSliderNewPlaces from "components/SectionSliderNewCategories/SectionSliderNewPlaces";
import {makeListCategory} from "hooks/useRequestDb";
import {makeListSubCategories} from "hooks/useRequestSubCategories";
import {makeListPlaces} from "hooks/useRequestPlaces";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import Heading from "components/Heading/Heading";
import { MetadataGenerator } from 'metatags-generator';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import slideUne from "images/guida-una-ferrari.webp";
import slideDue from "images/guida-un-quad.webp";
import slideTre from "images/volo-aereo.webp";


function PageHome() {

  var lisCat = makeListCategory();
  const listCategories  = () =>  Promise.all ([lisCat]).then((listRequestCat) => {
    return listRequestCat;
  });

  var lisSubCat = makeListSubCategories();
  const listSubCategories  = () =>  Promise.all ([lisSubCat]).then((listRequestSubCat) => {
    return listRequestSubCat;
  });

  var lisPlaces = makeListPlaces();
  const listPlaces  = () =>  Promise.all ([lisPlaces]).then((listRequestPlaces) => {
    return listRequestPlaces;
  });
  const imgGall = [slideUne, slideDue, slideTre];

  const [categories, setFieldCategorie] = useState([]);
  const [subcategories, setFieldSubCategories] = useState([]);
    const [places, setFieldPlaces] = useState([]);

  useEffect(() => {

        listCategories().then((res) => {
          setFieldCategorie(JSON.parse(res));
      });

      listSubCategories().then((rest) => {
        setFieldSubCategories(JSON.parse(rest));
      });

      listPlaces().then((resp) => {
        setFieldPlaces(JSON.parse(resp));
      });

  }, []);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Adrenalinika || Le migliori esperienze in tutta Italia</title>
        <meta name="description" content="Adrenalinika è una piattaforma per prenotare o regalare le esperienze in Italia. Scegli tra esperienze adrenaliniche, avventure immersive o corsi spericolati." />
        <link rel="canonical" href="https://www.adrenalinika.com" />
      </Helmet>

      {/* SECTION HERO */}
      <SectionHero2 data={subcategories} luoghi={places} imgGall={imgGall} className="pb-0 md:pb-16" />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">

        {/* SECTION 1 */}
        <SectionSliderNewSubCategories
          heading="Esplora le esperienze per tipologia"
          subHeading="Una selezione delle esperienze adrenaliniche più regalate dagli utenti"
          categoryCardType="card4"
          itemPerRow={4}
          item={subcategories}
        />
        <div className="center text-center mt-0" style={{marginTop: "3rem"}}>
        <ButtonPrimary
        href="/listing-subcategories"
        children="Esplora tutte le esperienze"
        className="text-center center"
        />
        </div>

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categoryCardType="card4"
            itemPerRow={4}
            heading="Categorie in evidenza"
            subHeading="Non sai quale esperienza scegliere? Seleziona una categoria per esplorare le numerose possibilità offerte dal team Adrenalinika."
            sliderStyle="style2"
            item={categories}
          />
        </div>


        {/* SECTION 1 */}
        <SectionSliderNewPlaces item={places}/>
        <div className="center text-center mt-0" style={{marginTop: "3rem"}}>
        <ButtonPrimary
        href="/listing-places"
        children="Esplora tutte le Regioni"
        className="text-center center"
        />
        </div>

        {/* SECTION2 */}
        <SectionOurFeatures />



        {/* SECTION */}
        <SectionHowItWork />





        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>



        {/* SECTION */}
        <SectionVideos />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
