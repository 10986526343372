import React from "react";

const LogoSvg = () => {
  return (
<span>
<svg className="w-24 dark:hidden md:block" viewBox="0 0 85 58" fill="red" xmlns="http://www.w3.org/2000/svg"><path d="M27,18.5V31H54.5L67,18.5,79.5,6H61l-6.9,6.5c-5.4,5.1-7.5,6.5-10,6.5H41V6H27Z" transform="translate(-27 -6)"/><path d="M27,46.5V59H41V46h3.5c2.9,0,4.6,1.1,10.4,6.5L61.7,59h8.7c4.7,0,8.6-.4,8.6-.9S73.5,52,66.8,45.6L54.6,34H27Z" transform="translate(-27 -6)"/></svg>
</span>
  );
};

export default LogoSvg;
