import * as React from "react";
import { FC, useState, useEffect, useRef } from "react";
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';
import {checkTokenControls} from "components/FormLogin/FunctionCheck";
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {makeRequest} from "components/AccountPage/AccountPage";

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    isSignedIn: boolean;
    path: string;
}



const PrivateRoute = (props: PrivateRouteProps) => {
  const keySecrets = "M3NHZlZCU2QzSnNmZG5ibmJkQVNLRk0zVVNzZEdIQUROMzM9PVNIM0dGRDRzNXNIc1NGQXNBQUhkU3NzNTNINEg9PVl6TmFjR0pJVm5kalJ6bEJZbGM1ZFZwaE9YbGFWMUYxWVZoUnBR";
    var tknExternal = "Z25HYktvUzMyWXNDLzNIMmpmek9WVWVadmh2Y2pHUU0wT3czeiswYmtKdGx5ZGxRcFQ=";
    var tkns = sessionStorage.getItem("session_id");
    var tknss = sessionStorage.getItem("checkTokenAdrenalinika");
    var user = JSON.parse(localStorage.getItem("user"));
    var checkTok = "";
    var tknssc = "";
    var otp = "r3DqDg=";
      useEffect(() => {
    if(tknss && user){
      checkTok = atob(tkns).split(otp);
      user = user.split(atob(tknExternal));
      user = user[1];
    checkTok = atob(checkTok[0]); // da session giusto
    tknssc = tknss.split(atob(keySecrets));
 
    tknssc = atob(tknssc[0]);
    }
      });


  var user = JSON.parse(localStorage.getItem("user"));
    const { component: Component, isSignedIn, ...rest } = props;




    return (
        <Route
            {...rest}
            render={(routeProps) =>
                tknssc == checkTok ? (
                    <Component {...routeProps} />
                ) : (
                        <Redirect
                            to="/login"
                        />
                    )
            }
        />
    );
};

export default PrivateRoute;
