import React, { FC, useState, useEffect } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import CurrencyInput from 'react-currency-input-field';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import Heading2 from "components/Heading/Heading2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from "shared/Checkbox/Checkbox";
import ModalPhotos from "./ModalPhotos";
import NcImage from "shared/NcImage/NcImage";

export interface PagePrivacyCookieProps {
  className?: string;
}


const PagePrivacyCookie: FC<PagePrivacyCookieProps> = ({ className = "" }) => {


  return (
    <div
      className={`nc-Privacy overflow-hidden relative ${className}`}
      data-nc-id="PagePrivacyCookie"
    >
      <Helmet>
        <title>Indicazioni Privacy e Cookie || Adrenalinika Experiences</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}

      <div className="container py-8 lg:py-8 space-y-4 lg:space-y-4">


        <ToastContainer />

        <h1 className="text-2xl">ADRENALINIKA Informativa sulla privacy  </h1>
        <h3>Introduzione</h3>
        La presente Informativa sulla privacy delinea le prassi di ADRENALINIKA relativamente alle informazioni raccolte dagli utenti che hanno effettuato l’accesso al nostro sito Web all’indirizzo www.adrenalinika.com (” Sito “), o alternativamente condividono le informazioni personali con noi (collettivamente: ” Utenti “).
        Basi legali per la raccolta dei dati
        Elaborare le informazioni personali dell’Utente (ossia tutte le informazioni che ne consentirebbero l’identificazione con mezzi ragionevoli; da qui in avanti ” Informazioni personali “)
        è necessario per l’adempimento dei nostri obblighi contrattuali verso l’Utente e per fornire i nostri servizi, per proteggere i nostri legittimi interessi e per conformarci agli obblighi normativi di natura legale e finanziaria ai quali siamo sottoposti.
<br />
        Quando l’Utente utilizza il Sito, acconsente alla raccolta, archiviazione, utilizzo, divulgazione e altri impieghi delle Informazioni personali così come descritto nella presente Informativa sulla privacy.
<br />
        Incoraggiamo i nostri Utenti a leggere attentamente l’Informativa sulla privacy e ad utilizzarla per pendere decisioni informate.
<br />
        Quali informazioni raccogliamo?
<br />
        Raccogliamo due tipi di informazioni degli utenti.
<br />
        Il primo tipo riguarda le informazioni non identificate e non identificabili pertinenti a un Utente (o Utenti) eventualmente rese disponibili o raccolte tramite l’utilizzo del Sito (“ Informazioni non personali ”). Non siamo a conoscenza dell’identità di un Utente di cui sono state raccolte informazioni non personali. Le informazioni non personali raccolte possono comprendere informazioni di utilizzo aggregate e informazioni tecniche trasmesse dal dispositivo, tra cui determinate informazioni software e hardware (ad esempio il tipo di browser e di sistema operativo utilizzati dal dispositivo, le preferenze di lingua, l’ora di accesso ecc.) e vengono utilizzate al fine di migliorare la funzionalità del nostro Sito. Possiamo inoltre raccogliere informazioni sull’attività dell’Utente sul Sito (ad esempio pagine visualizzate, navigazione online, clic, azioni, ecc.).
<br />
        Il secondo tipo riguarda le Informazioni personali che sono le informazioni personalmente identificabili, ossia le informazioni che identificano un individuo o possono individuarlo con uno sforzo ragionevole. Tali informazioni comprendono:
        Informazioni sul dispositivo: raccogliamo informazioni personali dai dispositivi. Tali informazioni comprendono dati di geolocalizzazione, indirizzo IP, identificatori univoci (ad esempio indirizzi MAC e UUID) e altre informazioni relative all’attività dell’Utente in tutto il Sito.
        Informazioni di registrazione: quando l’Utente si registra sul nostro Sito gli sarà richiesto di fornire determinati dettagli come: nome e cognome, e-mail o indirizzo fisico, e altre informazioni.
        Come riceviamo le informazioni sugli utenti?
        Riceviamo informazioni personali da diverse fonti:
        Quando l’Utente fornisce volontariamente i dati personali per registrarsi al sito;
        Quando l’Utente utilizza il nostro Sito o vi accede in connessione all’utilizzo dei nostri servizi ;
        Da provider di terza parte, servizi e registri pubblici (ad esempio, i provider dell’analisi del traffico).
        Quali informazioni raccogliamo?
        Non cediamo, vendiamo o condividiamo le informazioni relative agli Utenti con terze parti ad eccezione di quanto descritto nella presente Informativa sulla privacy.
<br />
        Possiamo utilizzare le informazioni ai seguenti fini:
        Comunicare con l’Utente – inviare avvisi relativi ai nostri servizi, fornire informazioni tecniche e rispondere a qualsiasi problema con l’assistenza clienti;
        Comunicare con l’Utente e informarlo degli ultimi aggiornamenti e servizi;
        Proporre annunci pubblicitari quando utilizza il nostro Sito (maggiori informazioni alla voce “Annunci pubblicitari”);
        Commercializzare i nostri siti Web e i nostri prodotti (maggiori informazioni alla voce “Marketing”);
        Svolgere analisi e statistiche, finalizzate a migliorare il Sito.
        In aggiunta ai diversi usi elencati sopra, possiamo trasferire o divulgare Informazioni personali alle nostre sussidiarie, aziende affiliate e collaboratori.
<br />
        Oltre alle finalità elencate nella presente Informativa sulla privacy, possiamo condividere Informazioni personali con i nostri provider di terza parte di fiducia, che possono essere ubicati in diverse giurisdizioni di tutto il mondo, con le seguenti finalità:
        Hosting e funzionamento del nostro Sito;
        Proporre all’Utente i nostri servizi, tra cui una vista personalizzata del nostro Sito;
        Archiviare ed elaborare tali informazioni per nostro conto;
        Proporre all’Utente annunci pubblicitari e sostenerci nella valutazione del successo della nostra campagna pubblicitaria, oltre ad aiutarci a ricalibrare il target di utenti;
        Fornire all’Utente offerte di Marketing e materiali promozionali relativi al Sito e ai servizi;
        Effettuare ricerche, diagnostica tecnica e analisi;
        Possiamo inoltre divulgare informazioni in buona fede se riteniamo che farlo sia utile o ragionevole per: (i) adempiere a leggi, normative, processi legali o richieste governative applicabili; (ii) far applicare le nostre politiche (incluso il Contratto), incluse le indagini relative a potenziali violazioni; (iii) indagare, rilevare, prevenire o intraprendere azioni riguardanti attività illegali o altre azioni illecite, sospette frodi o problemi di sicurezza; (iv) stabilire o esercitare i nostri diritti di difesa da rivendicazioni legali; (v) prevenire danni ai diritti, alla proprietà o alla sicurezza nostri, dei nostri clienti, degli Utenti o di terzi; o (vi) allo scopo di collaborare con le forze dell’ordine e / o nel caso in cui lo riteniamo necessario per far valere la proprietà intellettuale o altri diritti legali.
<br />
        Diritti dell’Utente
<br />
        L’Utente può richiedere di:
        Ricevere conferma se le informazioni personali che lo riguardano vengono elaborate o meno e accedere alle informazioni personali memorizzate, insieme a informazioni supplementari.
        Ricevere una copia delle informazioni personali fornite volontariamente in un formato strutturato, comunemente usato e leggibile da un dispositivo.
        Rettificare le informazioni personali nell’ambito del nostro controllo.
        Cancellare le informazioni personali.
        Opporsi all’elaborazione delle informazioni personali da parte nostra.
        Limitare l’elaborazione delle informazioni personali da parte nostra.
        Presentare una denuncia presso l’autorità di vigilanza.
<br />
        Tuttavia, si prega di notare che questi diritti non sono assoluti e possono essere soggetti ai nostri legittimi interessi e requisiti normativi.
<br />
        Se si desidera esercitare i diritti di cui sopra o ricevere maggiori informazioni, si prega di contattare il nostro Responsabile della protezione dei dati (“DPO”) utilizzando i dettagli forniti qui di seguito:
        info@adrenalinika.com
<br />
        Conservazione<br />
        Conserveremo le informazioni personali dell’Utente per tutto il tempo necessario a fornire i nostri servizi e ad adempiere ai nostri obblighi legali, risolvere controversie e far rispettare le nostre norme. I periodi di conservazione saranno determinati tenendo conto del tipo di informazioni raccolte e lo scopo per cui sono raccolte, tenendo conto dei requisiti applicabili alla situazione e della necessità di distruggere informazioni obsolete e non utilizzate il prima possibile entro limiti ragionevoli. Alla luce delle regolamentazioni applicabili, conserviamo i record contenenti i dati personali dei clienti, i documenti relativi all’apertura di account, comunicazioni e quant’altro richiesto dalle leggi e normative applicabili.
<br />
        Possiamo rettificare, integrare o rimuovere informazioni incomplete o inaccurate, in qualsiasi momento e a nostra esclusiva discrezione.
<br />
        Ultima modifica 15/05/2020
      </div>
      <div key="Cookie" id="Cookie"  className="container py-8 lg:py-8 space-y-4 lg:space-y-4">

      <h1 className="text-2xl mt-5">Cookie Policy</h1><br />


      Noi e i nostri partner fidati utilizziamo i cookie e altre tecnologie nei nostri servizi correlati, compreso quando l’Utente visita il nostro Sito o accede ai nostri servizi.
 <br />
      Un “cookie” è una piccola informazione che un sito Web assegna al dispositivo dell’Utente mentre visualizza un sito Web. I cookie sono molto utili e possono essere utilizzati per diverse finalità, che comprendono la possibilità di navigare tra le pagine in modo efficiente, abilitare l’attivazione automatica di alcune funzionalità, ricordare le preferenze e rendere più rapida e semplice l’interazione tra l’Utente e i nostri servizi. I cookie vengono inoltre utilizzati per garantire che gli annunci pubblicitari visualizzati siano pertinenti per l’Utente e per i suoi interessi e per compilare dati statistici sull’utilizzo dei nostri Servizi.
       <br />
      Il Sito utilizza i seguenti tipi di cookie:
       <br />
      a. ‘cookie di sessione’ archiviati solo temporaneamente durante una sessione di navigazione per consentire il normale utilizzo del sistema, eliminati dal dispositivo alla chiusura del browser;
       <br />
      b. ‘cookie persistenti ‘ letti solo dal Sito, salvati sul computer dell’Utente per un periodo stabilito e non eliminati alla chiusura del browser. Tali cookie sono utilizzati quando è necessario sapere chi è l’Utente per le visite ripetute, ad esempio per consentirci di archiviare le preferenze per l’accesso successivo;
       <br />
      c. ‘cookie di terza parte’ impostati da altri servizi online che seguono contenuti sulla pagina visualizzata, ad esempio, da società di analisi di terza parte, che monitorano e analizzano l’accesso al nostro sito Web.
       <br />
      I cookie non contengono alcuna informazione che identifica l’Utente personalmente, ma le Informazioni personali che archiviamo sull’Utente potrebbero essere collegate, da noi, alle informazioni memorizzate e ottenute dai cookie. È possibile rimuovere i cookie seguendo le istruzioni delle preferenze del dispositivo; tuttavia, se si sceglie di disabilitare i cookie, alcune funzionalità del nostro Sito potrebbero non funzionare correttamente e l’esperienza online potrebbe essere limitata.
       <br />
      Utilizziamo inoltre uno strumento denominato “Google Analytics” per raccogliere le informazioni sull’utilizzo del Sito. Google Analytics raccoglie informazioni come la frequenza con cui gli Utenti accedono al Sito, quali pagine visitano, quando lo fanno, ecc. Noi utilizziamo le informazioni allo scopo esclusivo di migliorare il nostro Sito e i nostri servizi. Google Analytics raccoglie l’indirizzo IP assegnato all’utente alla data in cui quest’ultimo ha visitato i siti invece del nome o di altre informazioni di identificazione. Non combiniamo le informazioni raccolte tramite Google Analytics con le informazioni personalmente identificabili. La capacità di Google di utilizzare e condividere le informazioni da esso raccolte sulla visita a questo Sito è limitata a Google Analytics Termini di utilizzo e Informativa sulla privacy di Google.
      Raccolta di informazioni di terze parie
      La nostra policy riguarda esclusivamente l’utilizzo e la divulgazione delle informazioni raccolte presso l’Utente. Nella misura in cui l’Utente divulga le informazioni che lo riguardano ad altre parti o siti in Internet, si possono applicare regole diverse all’uso o alla divulgazione delle informazioni ad essi trasmesse. Di conseguenza, incoraggiamo gli Utenti a leggere i termini e condizioni e l’informativa sulla privacy di ciascuna terza parte a cui scelgono di divulgare le proprie informazioni.
       <br />
      La presente Informativa sulla privacy non si applica alle prassi delle aziende che non possediamo né controlliamo, oppure ai singoli individui che non lavorano per noi e che non gestiamo, incluse le terze parti che possono divulgare informazioni come previsto nella presente Informativa sulla privacy.
      Come ci prendiamo cura delle informazioni?
      Teniamo molto all’implementazione e al mantenimento della sicurezza del Sito e alle informazioni degli utenti.
      Abbiamo adottato politiche e procedure standard del settore per garantire la sicurezza delle informazioni che raccogliamo e conserviamo, preveniamo un utilizzo non autorizzato di tali informazioni, e richiediamo ad ogni terza parte di adeguarsi con simili requisiti di sicurezza, secondo la presente Informativa sulla privacy . Anche se abbiamo adottato tutti i passi ragionevoli per salvaguardare le informazioni, non possiamo essere responsabili degli atti di coloro che riescono ad avere un accesso non autorizzato o a usare impropriamente il nostro Sito, e non diamo nessuna garanzia, espressa, implicita o di altra natura che impediremo tale accesso.
      Trasferimento dei dati al di fuori dello Spazio economico europeo
      Nota: alcuni destinatari dei dati potrebbero essere ubicati al di fuori dello Spazio economico europeo. In tal caso, trasferiremo i dati solo nei Paesi approvati dalla Commissione Europea in quanto offrono un livello adeguato di protezione dei dati, oppure stipuleremo accordi legali per garantire un livello adeguato di protezione dei dati.
      Annunci pubblicitari
      Possiamo utilizzare tecnologie pubblicitarie di terze parti per pubblicare annunci pubblicitari quando l’Utente accede al Sito). Queste tecnologie utilizzano le informazioni del cliente relative all’utilizzo dei Servizi per fornire annunci pubblicitari (ad esempio posizionare cookie di terza parte sul browser Web dell’Utente).
       <br />
      L’Utente può disattivare molte reti di annunci di terze parti, tra cui quelle gestite dai membri della Network Advertising Initiative (“NAI”) e della Digital Advertising Alliance (“DAA”). Per ulteriori informazioni su questa prassi dei membri NAI e DAA e sulle scelte relative all’utilizzo di tali informazioni da parte di queste aziende, incluso come disattivare le reti di annunci di terze parti gestite da membri NAI e DAA, visitare i rispettivi siti web: http://optout.networkadvertising.org/#!/ e http://optout.aboutads.info/#!/.
      Marketing
      Possiamo utilizzare le Informazioni personali come il nome, l’indirizzo e-mail, il numero di telefono ecc. internamente oppure utilizzando collaboratori di terze parti allo scopo di fornire all’Utente materiali promozionali relativi ai nostri servizi, che, a nostro avviso, potrebbero interessarlo.
       <br />
      Per rispetto al diritto alla privacy dell’Utente, all’interno di tali materiali di marketing sono disponibili i mezzi per rifiutare di ricevere ulteriori offerte di marketing da parte nostra. Se l’Utente annulla l’iscrizione, rimuoveremo il suo indirizzo email o il suo numero di telefono dai nostri elenchi di distribuzione del marketing.
       <br />
      Nota: anche se l’Utente ha annullato l’iscrizione alla ricezione di e-mail di marketing da parte nostra, potremmo inviargli altri tipi di comunicazioni e-mail importanti senza offrire l’opportunità di disattivare la ricezione. Tali comunicazioni possono comprendere annunci del servizio clienti o avvisi amministrativi.
      Transazione societaria
      Possiamo condividere informazioni nel caso di una transazione aziendale (ad esempio la vendita di una parte significativa della nostra azienda, una fusione, un consolidamento o una vendita di risorse). In tal caso, la società cessionaria o acquirente si assumerà i diritti e gli obblighi come descritto nella presente Informativa sulla privacy.
      Minori
      Siamo consapevoli dell’importanza di proteggere la privacy dei bambini, specialmente in un ambiente online. In nessun caso consentiamo ai minori di utilizzare i nostri servizi senza il preventivo consenso di un genitore o di un tutore legale. Non raccogliamo consapevolmente informazioni personali dai minori. Se un genitore o un tutore si dovesse rendere conto che il minore ci ha fornito Informazioni personali senza il loro consenso, dovrà contattarci all’indirizzo INFO@ADRENALINIKA.COM
      Aggiornamenti o modifiche alla presente Informativa sulla privacy
      Ci riserviamo il diritto di modificare periodicamente o rivedere l’Informativa sulla privacy; le modifiche materiali saranno effettive immediatamente, alla visualizzazione dell’Informativa sulla privacy aggiornata. L’ultima revisione sarà riportata nella sezione “Ultima modifica”. L’utilizzo continuativo della Piattaforma, a seguito della notifica di tali modifiche sul nostro sito web, rappresenta l’accettazione e il consenso a tali modifiche all’Informativa sulla privacy e il consenso a sottostare ai relativi termini.
      Come contattarci
      Per qualsiasi domanda generica sul Sito oppure sulle informazioni che raccogliamo sull’Utente e su come le utilizziamo, è possibile contattarci all’indirizzo info@adrenalinika.com
       <br />
      adrenalinika |
      via xx settembre, 44 Legnano (MI)
      </div>

    </div>
  );
};

export default PagePrivacyCookie;
