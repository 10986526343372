import GalleryImgs from "components/GallerySlider/GallerySingle";
import { ExperiencesDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import moment from "moment";
import React, { FC, useState, useEffect } from "react";
import { Redirect } from 'react-router';
import Cookies from 'universal-cookie';


export interface ExperiencesCardHProps {
  className?: string;
  data: string;
}


const ExperiencesCardH: FC<ExperiencesCardHProps> = ({
  className = "",
  data,
  buttonName,
  buttonClass,
  link,
  completeLink,
}) => {

  const {
    id_servizio,
    title,
    duration,
    date,
    saleOff,
    price,
    id,
    customer_id,
    importo,
    service_id,
    data_acquisto,
    data_scadenza,
    bambini,
    adulti,
    addons,
    prodotto,
    utilizzato,
    importo_iniziale,
    pagamento_effettuato,
    metodo_pagamento,
    description,
    servizi_inclusi,
    servizi_non_inclusi,
    politica_cancellazione,
    requisiti_partecipazione,
    regole_appuntamento,
    featuredImage,
    commentCount,
    viewCount,
    reviewCount,
    reviewStart,
    like,
    galleryImgs,
    giri,
    difficolta,
    address,
    lingue,
    isAds,
    authorId,
    author,
    maxGuests,
    listingCategoryId,
    sottocategoria,
    categoria_luoghi,
    title_eng,

  } = data;
  const [isDisableBlu, setIsDisableBlu] = useState(false);
  var tkn = btoa('jwt56wert');
  var user = JSON.parse(localStorage.getItem("user"));
  var ids = JSON.parse(localStorage.getItem("rjsth"));
  const idUserUpload = parseInt(atob(ids));

  //check is en
  const lang = new Cookies();
  lang.get('lang');

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full md:w-72 flex-shrink-0 overflow-hidden">
        <GalleryImgs
          ratioClass="aspect-w-12 aspect-h-9 md:aspect-h-11"
          galleryImgs={data.featuredImage}
        />
        {/*data.saleOff && <SaleOffBadge className="absolute left-3 top-3" />*/}
      </div>
    );
  };

  useEffect(() => {
    var today = moment().endOf('day').format("YYYY-MM-DD");
    var dataScad = moment(data.data_scadenza).format("YYYY-MM-DD");
  if(today>dataScad){
    setIsDisableBlu(true);
  }
     }, []);

  const renderContent = () => {


    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">

            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{lang.cookies.lang == "en" && data.title_eng ? data.title_eng : data.title}</span>
            </h2>
          </div>
          <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400">
            <div className="flex items-center">
              <span className="hidden sm:inline-block  text-small">
                <i className="las la-clock"></i> <b>{lang.cookies.lang == "en" ? "Purchase" : "Acquisto"}:</b> { moment(data.data_acquisto).format("DD/MM/YYYY")}
              </span>
            </div>
            <div className="flex items-center">
              <span className=" sm:inline-block text-small">
                <i className="las la-clock"></i> <b>{lang.cookies.lang == "en" ? "Expiration:" : "Scadenza:"}</b> { moment(data.data_scadenza).format("DD/MM/YYYY")}
              </span>
            </div>
            {  data.giri != 0 ? <div className="flex items-center">
              <span className="hidden sm:inline-block text-small">
                <i className="las la-retweet"></i> <b>{lang.cookies.lang == "en" ? "Laps: " : "Giri in pista: "}</b> {data.girim}
              </span>
            </div> : ""}
          </div>
          <div className="flex items-center space-x-8 mt-4 text-small text-sm text-neutral-500">
            <span className="hidden sm:inline-block text-small">
              <i className="las la-map-marked"></i> {data.address}
            </span>
          </div>

        </div>
        <div className="flex items-center space-x-8 mt-4  ">
          <div className="flex items-center space-x-2">
            <span className="text-base text-neutral-500 dark:text-neutral-400">
            <b className="text-base font-medium capitalize text-neutral-500 dark:text-neutral-400">{lang.cookies.lang == "en" ? "Residual voucher value" : "Valore voucher residuo"}</b><br/> <b style={{color: 'green'}}>€ {data.importo}</b>
            </span>
          </div>

        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          <div className="flex items-center space-x-3 text-sm text-neutral-700  dark:text-neutral-300">

            <span className=" sm:inline-block">
              <span className="hidden sm:inline"></span>{" "}
              <i className="las la-user text-xl"></i> {lang.cookies.lang == "en" ? "Adults: " : "Adulti: "} <b style={{color: 'green'}}>{data.adulti}</b> | <i className="las la-user text-xl"></i> {lang.cookies.lang == "en" ? "Children: " : "Bambini: "} <b style={{color: 'green'}}>{data.bambini}</b> | <i className="las la-key text-xl"></i> Cod: <b style={{color: 'green', textTransform: 'uppercase'}}>{data.codice}</b>
            </span>
          </div>
          <span className="text-base font-semibold text-secondary-700">
          <ButtonPrimary className={`sm:px-4 sm:text-sm sm:py-2 ${buttonClass}`} isDisabled={isDisableBlu}>{buttonName}</ButtonPrimary>
          </span>
        </div>
      </div>
    );
  };

  if(!user){
    return (
      <Redirect to="/"/>
    );
  }

  return (
    <div
      className={` mb-10 nc-ExperiencesCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="ExperiencesCardH"
    >
      <Link to={completeLink ? completeLink : link+'/'+id_servizio+'/?'+btoa('guestAdult='+data.adulti+'&guestChildren='+data.bambini+'&prodotto='+data.prodotto+'&addons='+data.addons+'&importo-scalare='+data.importo+'&pagato='+data.pagamento_effettuato+'&giri='+data.giri+'&codice='+data.codice+'&scadenza='+ moment(data.data_scadenza).format("DD-MM-YYYY"))}  className="md:flex md:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default ExperiencesCardH;
