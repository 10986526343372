import Label from "components/Label/Label";
import React, { FC, useState, useEffect, useRef } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Checkbox from "shared/Checkbox/Checkbox";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayoutEn";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {makeRequest} from "components/AccountPage/AccountPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {checkToken} from "components/FormLogin/FunctionCheck";
import Cookies from 'universal-cookie';
import {logout} from "components/FormLogin/FunctionUser";


export interface AccountPageEnProps {
  className?: string;
}


  var user = JSON.parse(localStorage.getItem("user"));
  var ids = JSON.parse(localStorage.getItem("rjsth"));
  const cookies = new Cookies();
  var coookieOk = cookies.get('__profile');
  const sessionToken = sessionStorage.getItem("session_id") === 'true';
  var idUserUpload = atob(ids);




  const AccountPageEn: FC<AccountPageEnProps> = ({ className = "" }) => {
    const file = useRef(null);
    const [fotos, setFotos] = useState();
    const [getDisabilitato, setDisabilitato] = useState('true');
    const [getName, setName] = useState();
    const [getCognome, setCognome] = useState();
    const [getEmail, setEmail] = useState();
    const [getTelefono, setTelefono] = useState();
    const [getIndirizzo, setIndirizzo] = useState();
    const [getCitta, setCitta] = useState();
    const [getCap, setCap] = useState();
    const [getMarketing, setMarketing] = useState(0);

    const [teset, settsetset] = useState();
    const [em, setEm] = useState();
    const [controlChecks, setControlChecks] = useState(false);
    var tkns = sessionStorage.getItem("session_id");

    useEffect(() => {

      let dataUserTot = Promise.all([usAcc]).then((datums) => {

      var marketing = JSON.parse(datums[0]).marketing;

      if (marketing == 0 || marketing == "0"){
      setMarketing(false);
      } else if (marketing == 1 || marketing == "1"){
        setMarketing(true);
      } else if (!marketing || marketing == null || marketing == "" || marketing == ''){
        setMarketing(false);
      }

    });

    }, []);

       if(!tkns){

       return (
         <div className="mt-20 mb-20 text-center"> {user && ids ? '' : 'Errore. Chiudi le schede del browser e ripeti il login.'} <br /><br /> <a to="/account" onClick={() => {logout()}} className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 ">Logout</a></div>
       );
     } else {

//|| em == teset

var usAcc = makeRequest();
let dataUserTot = Promise.all([usAcc]).then((datums) => {


var nome = JSON.parse(datums[0]).firstName;
document.getElementById("nome").defaultValue = nome;

var cognome = JSON.parse(datums[0]).lastName;
document.getElementById("cognome").defaultValue = cognome;

var telefono = JSON.parse(datums[0]).phone;
document.getElementById("telefono").defaultValue = telefono;

var foto = JSON.parse(datums[0]).foto;
setFotos(foto);


var indirizzo = JSON.parse(datums[0]).address;
document.getElementById("indirizzo").defaultValue = indirizzo;

var city = JSON.parse(datums[0]).city;
document.getElementById("city").defaultValue = city;

var cap = JSON.parse(datums[0]).zip;
document.getElementById("cap").defaultValue = cap;


});








const handleChangeName = event => {
  setName(event.target.value);
};
const handleChangeCognome = event => {
 setCognome(event.target.value);
};
const handleChangeTelefono = event => {
 setTelefono(event.target.value);
};
const handleChangeIndirizzo = event => {
 setIndirizzo(event.target.value);
};
const handleChangeCitta = event => {
 setCitta(event.target.value);
};
const handleChangeCap = event => {
 setCap(event.target.value);
};





const handleClick = event => {
    event.preventDefault();
    var uploadUs = sendUploadUser(getName, getCognome, getTelefono, getIndirizzo, getCitta, getCap, getMarketing,);

};


var tn = "Adrenalinika";
var token = hashToken(tn);
const urlUserUpload = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/customers/'+idUserUpload;

const fieldUser  = () =>  Promise.all ([uploadUs]).then((listRequestUpl) => {
  return listRequestUpl;
});

  async function sendUploadUser (getName, getCognome, getTelefono, getIndirizzo, getCitta, getCap, getMarketing) {
  return await new Promise(function (resolve, reject) {

    var xhr = new XMLHttpRequest();
    xhr.open('PUT', urlUserUpload, true);
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
        toast.success('Le informazioni sono state modificate.');
        setDisabilitato(!getDisabilitato);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    xhr.send(JSON.stringify({
      firstName: getName,
      lastName: getCognome,
      phone: getTelefono,
      address: getIndirizzo,
      city: getCitta,
      zip: getCap,
      marketing: getMarketing,
    }));
  });
}



  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">

      <Helmet>
        <title>Account || Adrenalinika</title>
      </Helmet>

      <CommonLayout>

        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
            <div className="flex flex-col md:flex-row space-x-7">
              <div className="flex-shrink-0 flex items-center">
                <h2 className="text-3xl font-semibold">Account Information</h2>
              </div>
              <div className="flex-shrink-0 flex items-center">
                <ButtonSecondary
                onClick={
                    () => {setDisabilitato(!getDisabilitato);}
                }
                >
                Update information
                </ButtonSecondary>
              </div>
            </div>
<ToastContainer />
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={fotos} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change image</span>
                </div>
                <input
                  ref={file}
                  type="file"
                  name="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>Name</Label>
                <Input className="mt-1.5" id="nome" onChange={handleChangeName} disabled = {getDisabilitato ? "disabled" : ""} />
              </div>
              <div>
                <Label>Surname</Label>
                <Input className="mt-1.5" id="cognome" onChange={handleChangeCognome} disabled = {getDisabilitato ? "disabled" : ""} />
              </div>

              <div>
                <Label>Phone</Label>
                <Input className="mt-1.5" id="telefono" onChange={handleChangeTelefono} disabled = {getDisabilitato ? "disabled" : ""} />
              </div>

              <div>
                <Label>Address</Label>
                <Input className="mt-1.5" id="indirizzo" onChange={handleChangeIndirizzo} disabled = {getDisabilitato ? "disabled" : ""}/>
              </div>

              <div>
                <Label>City</Label>
                <Input className="mt-1.5" id="city" onChange={handleChangeCitta} disabled = {getDisabilitato ? "disabled" : ""} />
              </div>

              <div>
                <Label>CAP</Label>
                <Input className="mt-1.5" id="cap" onChange={handleChangeCap} disabled = {getDisabilitato ? "disabled" : ""} />
              </div>
              <div className="flex">
                <input type="checkbox" className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500 mt-1.5 mr-2" checked={getMarketing} id="marketing" key="marketing" value={getMarketing ? 1 : 0}  onChange={(e)=>setMarketing(e.target.checked)} disabled = {getDisabilitato ? "disabled" : ""} />
                <Label className="mt-1.5">I agree to receive communications and dedicated offers from Adrenalinika.</Label>
              </div>
              <div className="pt-2">
                <ButtonPrimary
                onClick={handleClick}
                disabled = {getDisabilitato ? 'disabled' : ''}
                >
                Send
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>

    </div>

  );

  }
};

export default AccountPageEn;
