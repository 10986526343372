import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/adrenalinika-esperienze.webp";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
      data-nc-id="SectionBecomeAnAuthor"
    >
      <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
        <Logo className="w-20" />
        <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
          Le esperienze Adrenalinika?
        </h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          Le esperienze a marchio Adrenalinika sono tutte Certificate.
          Da anni, oltre 500 partner fanno parte della nostra famiglia.
          Inoltre i professionisti ti seguiranno attentamente per tutta la durata dell'esperienza.
          Il supporto clienti è a disposizione per ogni esigenza.
        </span>
        <a href="/contact"><ButtonPrimary className="mt-6 sm:mt-11">
          Richiedi informazioni
        </ButtonPrimary></a>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
