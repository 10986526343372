import React, { useState, useEffect } from "react";
import Routes from "routers/index";
import moment from "moment";
import {checkToken, getUs} from "components/FormLogin/FunctionCheck";
import attachBanner from "@beyonk/gdpr-cookie-consent-banner";
import "@beyonk/gdpr-cookie-consent-banner/dist/style.css"; // import optional styles
import ReactGA from "react-ga4";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from "react-helmet";
function App() {



  //GA

//getControlKeySani
  const [teset, settsetset] = useState();
  var session_r = sessionStorage.getItem('session_r');
  if(!session_r){
    sessionStorage.setItem('session_r', btoa('AdrenaLinaKa29'));
  }


  const [user, setState] = useState(() => {
    return !!JSON.parse(localStorage.getItem("user"));
  }, []);



  var check = checkToken();
  const checkAll  = () =>  Promise.all ([check]).then((key) => {
     return key;
   });


   useEffect(() => {
     checkAll().then((ressts) => {
       var result = JSON.stringify(ressts[0]);
       result = JSON.parse(result);
       result = atob(result[0]).replace(',', '');
       settsetset(result);
   });
   }, []);


   //getUs
   var dis = JSON.parse(localStorage.getItem("rjsth"));
   if(dis){

   const urlUserUpload = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/customers/'+atob(dis);
   var id = atob(dis);
   const teke = getUs(urlUserUpload);

   const fieldUser  = () =>  Promise.all ([teke]).then((listRequestUpl) => {
     var resutl = JSON.parse(listRequestUpl);

     resutl = btoa(resutl.email);
     var resuts = btoa(resutl.firstName);
     var finalTok = btoa(resutl)+"r3DqDg="+btoa(resuts);
    const confirm = sessionStorage.getItem("confirmAci");
     if (teset === resutl && confirm != ''){
       const session = sessionStorage.setItem("session_id", btoa(finalTok));
       const reloadCount = sessionStorage.getItem('reloadCount');
           if(reloadCount < 1) {
             sessionStorage.setItem('reloadCount', String(reloadCount + 1));
             window.location.reload();
             sessionStorage.setItem('confirmAci', '==fsdydsjhfsusjbf02983sdysf737');
           } else {
             sessionStorage.removeItem('reloadCount');
             sessionStorage.setItem('confirmAci','');
           }
     }
     return resutl;
   });

    fieldUser();
    }

    //Facebook Pixel
    const advancedMatching = '';
      const optionsy = {
        autoConfig: false,
        debug: true,
      };


    //Google Analytics
    var track = Cookies.get('_CookiePolicy');
    if(track){
      ReactGA.initialize("G-JY0PH5HH8M");
      ReactGA.send("pageview");
      ReactPixel.init('666993277478928', optionsy);
      ReactPixel.pageView();
    } else {

    }

        //check is en and if url is correct

    var lang = Cookies.get('lang');
    if(lang == "en"){
      var ifeng = window.location.pathname.split("/");
        if(ifeng[1] != "en"){
          Cookies.set('lang', "it", { path: '/', maxAge: '172800' });
          window.location.replace(window.location.pathname);
        }
    }

    if(lang == "it"){
      var ifeng = window.location.pathname.split("/");
        if(ifeng[1] == "en"){
          Cookies.set('lang', "en", { path: '/', maxAge: '172800' });
          window.location.replace(window.location.pathname);
        }
    }


  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <Helmet htmlAttributes={{ lang : lang == "en" ? "en" : "it" }}></Helmet>
      <Routes />
      <CookieConsent
        enableDeclineButton
        declineButtonText={lang == "en" ? "Technicians only" : "Solo Tecnici"}
        onAccept={() => {

        }}
        onDecline={() => {

        }}
        overlay={true}
        acceptOnOverlayClick={true}
        location="bottom"
        buttonText={lang == "en" ? "I agree" : "Acconsento"}
        cookieName="_CookiePolicy"
        style={{ background: "#2B373B", borderRadius: '0 16px 0 0', maxWidth:'80%', display: 'block', padding: '10px' }}
        contentStyle= {{ color: "#fff", fontSize: "13px", borderRadius:'999px', }}
        buttonStyle={{ color: "#fff", fontSize: "13px", background: '#4f46e5', borderRadius:'999px', }}
        declineButtonStyle={{ fontSize: "13px", borderRadius:'999px', }}
        expires={150}
      >
    {lang == "en" ?  "We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, also for 'measurement' and 'targeting and advertising' as specified in the cookie policy. Refusal of consent may make the relative functions unavailable. You can freely give, refuse or revoke your consent at any time." : " Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per 'misurazione' e 'targeting e pubblicità' come specificato nella cookie policy. Il rifiuto del consenso può rendere non disponibili le relative funzioni. Puoi liberamente prestare, rifiutare o revocare il tuo consenso, in qualsiasi momento."}
      {lang == "en" ? " To find out more, please refer to our" : "Per saperne di più, si prega di fare riferimento alla nostra"} <a href={lang == "en" ? "/en/privacy-cookie-policy" : "/privacy-cookie-policy"}>privacy policy</a>.
      </CookieConsent>
      <div key="cookieOK" onClick={()=> {Cookies.remove('_CookiePolicy'); window.location.reload();}} id="cookie-OK" className="fixed bottom" style={{zIndex: '999999', color: '#1a1a1a', background: '#f3f4f6', width: 'fit-content', height: '34px', visibility: 'visible', bottom: '0', opacity: '1', padding: '5px 10px', borderRadius: '0 20px 0 0', fontSize: '14px', letterSpacing: '-1px'}}>Privacy&Cookie</div>
    </div>
    );
  }

export default App;
