import React, { FC, useState, useEffect,useReducer, Fragment } from "react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerRangeController, DayPickerSingleDateController, FocusedInputShape } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import RentalCarDatesRangeInput from "components/HeroSearchForm/RentalCarDatesRangeInput";
import RentalOpenDatesInput from "components/HeroSearchForm/RentalOpenDatesInput";
import { TimeRage } from "components/HeroSearchForm/RentalCarSearchForm";
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import NcDropDownItem from "shared/NcDropDown/NcDropDown";
import Calendar from "components/HeroSearchForm/Calendar";
import Select from "react-select";
import {BrowserRouter as Router, Link, useLocation} from "react-router-dom";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import convertNumbThousand from "utils/convertNumbThousand";
import { Range } from "rc-slider";
import loader from "images/loader.png";
import eccezionale from "images/trust_eccezionale.webp";

export interface ListingExperiencesRiscattoProps {
  className?: string;
}

var PHOTOS: string[] = [];


const customStylesSelect = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted #eee',
    color: state.isSelected ? 'white' : 'black',
    background:  state.isSelected ? 'rgba(var(--c-neutral-800), var(--tw-bg-opacity))' : 'white',
    padding: 15,

  }),
  control: () => ({

    height: '54px',
    display: 'flex'
  }),
  container: (provided, state) => {
    const border = '1px solid #e5e7ec';
    return {...provided, border};
  },
  input:(provided, state) => {
    const opacity = state.isFocused ? 0.5 : 0;
    return {...provided, opacity};
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const fontSize = '1.1em';
    const marginLeft = '8px';
    const color = 'hsl(0deg 0% 41%)';

    return { ...provided, opacity, transition, fontSize, marginLeft, color };
  }
}

const customStylesSelect2 = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted #eee',
    color: state.isSelected ? 'white' : 'black',
    background:  state.isSelected ? 'rgba(var(--c-neutral-800), var(--tw-bg-opacity))' : 'white',
    padding: 15,

  }),
  control: () => ({

    height: '64px',
    display: 'flex',
    paddingLeft: '8px'
  }),
  container: (provided, state) => {
    const border = 'unset';
    const margin = '5px auto';
    const maxWidth = '90%';
    const marginTop = '10px';
    return {...provided, border, margin, maxWidth,marginTop};
  },
  input:(provided, state) => {
    const opacity = state.isFocused ? 0.5 : 0;
    return {...provided, opacity};
  },


  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const fontSize = '1.3em';
    const fontWeight = '600';
    const marginLeft = '8px';
    const color = 'rgba(var(--c-neutral-900), var(--tw-text-opacity))';


    return { ...provided, opacity, transition, fontSize, marginLeft, color, fontWeight };

  }
}




const ListingExperiencesRiscatto: FC<ListingExperiencesRiscattoProps> = ({
  className = "",
}) => {

    var urlst = window.location.search;

    urlst = urlst.replace('?', '');

    var finalpath = atob(urlst);



    var useQuery = () => new URLSearchParams(finalpath); //campi importati
    var queryes = useQuery();
    var guestAdult = queryes.get('guestAdult');
    var guestChildren = queryes.get('guestChildren');
    var prodotto = queryes.get('prodotto');
    var getaddonsquery = queryes.get('addons');
    var importoScalare = queryes.get('importo-scalare');
    importoScalare = parseFloat(importoScalare).toFixed(2);
    var pagato = queryes.get('pagato');
    var scadenza = queryes.get('scadenza');
    var codice = queryes.get('codice');
    var giri = queryes.get('giri');

    const [putkey, setPutKey] = useState(0);
    const allAdds = getaddonsquery.split(',');


  const addsPush = []; // array da inviare con addons selezionati

  const [totalAddons, setTotalAddons] = useState(parseFloat(0)); // prezzo degli addons selezionati
  const [totalHourPrice, setTotalHourPrice] = useState(null); //prezzo slot ora scelta
  const [totalProductsPrice, setTotalProductsPrice] = useState(null); // prezzo prodotto in maggiorazione
  const [checkoutFieldProduct, setCheckoutFieldProduct] = useState(null); //campo prodotto selezionato da inviare
  const [selectedHour, setSelectedHour] = useState([]); //ora selezionata al click da inviare
  const [selectedHourConverted, setSelectedHourConverted] = useState([]); //ora selezionata al click da inviare
  const [numGuestArray, setNumGuestArray] = useState({guestAdults: parseInt(guestAdult), guestChildren: parseInt(guestChildren)});//tutti gli ospiti (bambini + adulti) da inviare
  const [fieldAddonsCheckout, setFieldAddonsCheckout] = useState(addsPush); //lista addons selezionati da inviare
  const [totalPrices, setTotalPrices] = useState(parseFloat(0)); // totale da pagare
  const [totalPricesPlus, setTotalPricesPlus] = useState(parseFloat(importoScalare)); // totale da pagare del voucher scalato finale

    //render first free day and month in calendar
    const [getPrimoGiornoMese, setPrimoGiornoMese] = useState();


  const [maxGuestAdult, setMaxGuestAdult] = useState(1); //AGGIORNA MAX GuestsInput
  const [makeCalendar, setMakeCalendar] = useState([]); //lista ore calendario
  const [getRedirect, setRedirect] = useState(); //Set redirect button
  const [dateSelected, setDateSelected]= useState(); //Data calendario selezionata in tempo reale
  const [dateSelectedConverted, setDateSelectedConverted]= useState(); //Data calendario selezionata in tempo reale
  const [experienceData, setListExperienceData] = useState([]); //dati stamap esperienza info
  const [controlForm, setControlForm] = useState(false); // controlla se i campi ospiti siano compilati
  var checkDisp = false; // controlla se i campi ospiti siano compilati
const [varVisibilityHour, setVarVisibilityHour] = useState(false);

const [loadingApi, setLoadingApi] = useState(false); //per attivare il loader in cal
  //var endHour = moment(dateSelected+ ' ' +selectedHour).add(experienceData.duration, 'minutes').format('HH:mm'); //Calcolo ora di fine esperienza in base alla durata


var endDefault = moment(dateSelected+ ' ' +selectedHour); // duration in moment format
var endHour = moment(endDefault).add(experienceData.duration, "minutes").format('HH:mm'); //Calcolo ora di fine esperienza in base alla durata
var endDate = moment(endDefault).add(experienceData.duration, "minutes").format('YYYY:MM:DD');



  endHour = endHour.replace(':', 'D');
  endDate = endDate.replace(':', 'D').replace(':', 'D');

  const [isOpenReservation, setIsOpenReservation] = useState(false);
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);

  var tn = "Adrenalinika";
  var token = hashToken(tn);
  var idExp = window.location.pathname;
  idExp = idExp.split('/');
  var idExperiences = idExp[2];
  const url = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/services/' + idExperiences;

  var isNew = JSON.parse(localStorage.getItem('rjsth'));
  if (!isNew) {isNew = 0; }

  const closeModalMoreFilter = () => {
    setisOpenMoreFilter(false);
    window.location.reload();
    }
  const openModalMoreFilter = () => setisOpenMoreFilter(true);



const orderExportAll = {
  productId: idExperiences, //id Prodotto
  startDate: dateSelected, //data selezionata
  startHour: selectedHour, //ora selezionata
  endHour: endHour, //ora fine esperienza
  guestAll: numGuestArray, //Ospiti adulti e bambini come oggetto
  selectedProduct: checkoutFieldProduct, //prodotto selezionato se c'è
  addonsSelected: fieldAddonsCheckout, //gli optional selezionati

}




    async function makeExperiencesdata() {
    return await new Promise(function (resolve, reject) {

      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText);

        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      xhr.send();
    });
  }


  var detailExperience = makeExperiencesdata();
  const detailExp  = () => Promise.all([detailExperience]).then((listRequest) => {
  return listRequest;
  });

   useEffect(() => {
     detailExp().then((rest) => {
       setListExperienceData(JSON.parse(rest));
     });
   }, []);

var [foto, setGallery] = useState([]);

useEffect(() => {
  detailExp().then((restdue) => {
    var array = JSON.parse(restdue);
    setGallery(array.galleryImgs);
  });
}, []);

PHOTOS = foto;

var [author, setAuthor] = useState([]);

useEffect(() => {
  detailExp().then((resttre) => {
    var array = JSON.parse(resttre);
    setAuthor(array.author);
  });
}, []);

var [inclusi, setInclusi] = useState([]);

useEffect(() => {
  detailExp().then((restqttr) => {
    var array = JSON.parse(restqttr);
    setInclusi(array.servizi_inclusi);
  });
}, []);

var [nonInclusi, setNonInclusi] = useState([]);

useEffect(() => {
  detailExp().then((restqttrt) => {
    var array = JSON.parse(restqttrt);
    setNonInclusi(array.servizi_non_inclusi);
  });
}, []);

useEffect(() => {
  detailExp().then((restqttrt) => {
    var array = JSON.parse(restqttrt);

  });
}, []);

//FINE DATI SERVIZIO

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  // USE STATE
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: moment().add(1, 'day').endOf('day'),
    endDate: moment().add(1, 'day').endOf('day'),
  });
  const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    startTime: "10:00 AM",
    endTime: "10:00 AM",
  });







  //INIZIO API PER RICHIESTA PRIMO MESE E GIORNO DISPONIBILE

    var tn = "Adrenalinika";
    var token = hashToken(tn);
    var idExp = window.location.pathname;
    idExp = idExp.split('/');
    var idExperiences = "";
    idExperiences = idExp[2];




    //richiesta primo giorno disponibile per calendario provider
    var urlPrimoGiorno = 'https://www.adrenalinika.com/gestionale/ajax_exemple/get-first-date-month.php?serviceId=' + parseInt(idExperiences);

    async function primoGiorno() {
    return await new Promise(function (resolve, reject) {

      var xhr = new XMLHttpRequest();
      xhr.open('POST', urlPrimoGiorno, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText);

        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      xhr.send(idExperiences);
    });
  }

    var prendiPrimoGiorno = primoGiorno();

    const prendiPrimoGiornoFinal  = () => Promise.all([prendiPrimoGiorno]).then((listOfDays) => {
    return listOfDays;
    });

      prendiPrimoGiornoFinal().then((restdays) => {
          var resultPrimoGiorno = JSON.parse(restdays[0]);
          var okResGiorno = resultPrimoGiorno[0].start_datetime;
          setPrimoGiornoMese(okResGiorno);
      });



  //FINE RICHIESTA PRIMO MESE E GIORNO DISPONIBILE



//INIZIO REQUEST ORARIO



    async function renderTimeGuest(setClickDate, makeCalendar) {
      var idExp = window.location.pathname;
      idExp = idExp.split('/');
      var idExperiences = idExp[2];
      setLoadingApi(true);
        const url2 = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/availabilities?date='+setClickDate+'&serviceId='+parseInt(idExperiences);


      async function makecalendario() {
      return await new Promise(function (resolve, reject) {

        var xhr = new XMLHttpRequest();
        xhr.open('POST', url2, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", 'Bearer ' + token);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
        xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.responseText);
            setLoadingApi(false);
          } else {
            reject({
              status: xhr.status,
              statusText: xhr.statusText
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        };

        xhr.send();
      });
    }


  var detailCalendar = makecalendario();


  const detailCal  = () => Promise.all([detailCalendar]).then((listRequest) => {
  return listRequest;
  });


      detailCal().then((restcal) => {
        setMakeCalendar(JSON.parse(restcal));
        console.log(makeCalendar);
      });


}

//FINE API ORARIO


//check




var idsy = idExperiences;
var code = codice;

const checkurld = 'https://www.adrenalinika.com/gestionale/ajax_exemple/voucher/check-voucher.php';

  async function checkKid() {
  return await new Promise(function (resolve, reject) {
    var kety = btoa(code);
    var xhr = new XMLHttpRequest();
    xhr.open('POST', checkurld, true);
    //xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {

        resolve(xhr.response);
        if(xhr.response == 1){
          sessionStorage.setItem('check_id', kety);
        }else{
          sessionStorage.removeItem('check_id', kety);
          window.location.replace("/");
        }
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    xhr.send("code="+JSON.stringify(code)+"&k="+parseInt(idsy));
  });
}

if (codice && idExperiences){
var detailKeys = checkKid();

const detailKesy  = () => Promise.all([detailKeys]).then((listRequestsy) => {
return listRequestsy;
});
detailKesy().then((restval) => {
  setPutKey(JSON.parse(restval));
});
}









//STAMPA Guest IN SELECT

const renderSectionGuest = () => {

if(prodotto != '' && !!prodotto && prodotto != 0 && prodotto != '0'){ //se ci sono prodotti
  if(getProducts == '' || !getProducts){ //se il prodotto non è dispon in ora selezionata
    checkDisp = false;
    return(

    <div key="guestAdults" className="w-full text-sm rounded-2xl border-neutral-200 focus:border-neutral-300 focus:ring focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900">
    <div className="mt-6">
      <h2 className="text-2xl font-semibold">Prodotti terminati</h2>
      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
        Il provider ha terminato i prodotti per quest'ora.
      </span>
    </div>
    </div>

    )
  } else{

      if(maxGuestAdult){
        checkDisp = true;
      return(

      <div key="guestAdults" className="w-full text-sm rounded-2xl border-neutral-200 focus:border-neutral-300 focus:ring focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 hidden">
        <GuestsInput
          classNamePop={"displaynoneguest"}
          maxGuests={maxGuestAdult}
          fieldClassName="p-3 mt-5 border-neutral-100 focus:border-neutral-300 dark:border-neutral-700 coinsta w-full hidden"
          defaultValue={{
            guestAdults: parseInt(numGuestArray.guestAdults),
            guestChildren: parseInt(numGuestArray.guestChildren),
          }}
          onChange={(datas) => {
            //setNumGuestArray(datas);

            var totOspiti = parseInt(numGuestArray.guestAdults) + parseInt(numGuestArray.guestChildren);
            if(totOspiti > 0){
              setControlForm(true);
            } else{
              setControlForm(false);
            }
            setTotalPrices((totalHourPrice+totalAddons+totalProductsPrice)*parseInt(totOspiti));
          }}
        />
      </div>

      )
    } else {
      checkDisp = false;
      return(

      <div key="guestAdults" className="w-full text-sm rounded-2xl border-neutral-200 focus:border-neutral-300 focus:ring focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 hidden">
      <div className="mt-6">
        <h2 className="text-2xl font-semibold">Posti terminati</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          Non ci sono posti disponibili per l'ora selezionata.
        </span>
      </div>
      </div>

      )
    }
  }
} else {

        if(maxGuestAdult){
          checkDisp = true;
        return(

        <div key="guestAdults" className="w-full text-sm rounded-2xl border-neutral-200 focus:border-neutral-300 focus:ring focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 hidden">
          <GuestsInput
            classNamePop={"displaynoneguest"}
            maxGuests={maxGuestAdult}
            fieldClassName="p-3 mt-5 border-neutral-100 focus:border-neutral-300 dark:border-neutral-700 coinsta w-full hidden"
            defaultValue={{
              guestAdults: parseInt(numGuestArray.guestAdults),
              guestChildren: parseInt(numGuestArray.guestChildren),
            }}
            onChange={(datas) => {
              //setNumGuestArray(datas);

              var totOspiti = parseInt(numGuestArray.guestAdults) + parseInt(numGuestArray.guestChildren);
              if(totOspiti > 0){
                setControlForm(true);
              } else{
                setControlForm(false);
              }

              setTotalPrices((totalHourPrice+totalAddons+totalProductsPrice)*parseInt(totOspiti));
            }}
          />
        </div>

        )
      } else {
        checkDisp = false;
        return(

        <div key="guestAdults" className="w-full text-sm rounded-2xl border-neutral-200 focus:border-neutral-300 focus:ring focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900">
        <div className="mt-6">
          <h2 className="text-2xl font-semibold">Posti terminati</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Non ci sono posti disponibili per l'ora selezionata.
          </span>
        </div>
        </div>

        )
      }

}



}

// FINE STAMPA ORARI IN SELECT


//INIZIO REQUEST PRODOTTI PER PRENOTAZIONE CON FILTRO DISPONIBILITA' PER GIORNO E ORA


  const [getProducts, setProducts] = useState([]);
  const [getGiriData, setGiriData] = useState([]);

//stampa prodotti PER PRENOTAZIONE
const [makeCalendarProvider, setMakeCalendarProvider] = useState([]); //prendi provider dal servizio se provider prodotto non esiste.

const [getProductsProvider, setProductsProvider] = useState([]); //se esiste il prodotto prendi questo id provider altrimenti prendi il provider servizio
const renderSectionProducts = () => {

  return(

    <Select
      className={` coinsta w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
      options={getProducts.filter((_,i) => getProducts[i].id == prodotto)}
      name={getProducts.id}
      value = {getProducts.filter((_,i) => getProducts[i].id == prodotto)}
      onLoadedData = {(i) =>  {
        var totGuest = numGuestArray.guestAdults + numGuestArray.guestChildren;
        setTotalPrices((parseFloat(i.prezzo)+totalAddons+totalHourPrice) * totGuest);
        setTotalProductsPrice(parseFloat(i.prezzo));
        setCheckoutFieldProduct(i.id);
        setProductsProvider(i.providerId);
      }}
      getOptionLabel={(getProducts) => {
        var totGuest = numGuestArray.guestAdults + numGuestArray.guestChildren;
        setTotalPrices((parseFloat(getProducts.prezzo)+totalAddons+totalHourPrice) * totGuest);
        setTotalProductsPrice(parseFloat(getProducts.prezzo));
        setCheckoutFieldProduct(getProducts.id);
        setProductsProvider(getProducts.providerId);
        return(
          <h4 className="text-md">  {`${getProducts.label}`}
          </h4>
        )
      } }
      placeholder="Seleziona un elemento"
      styles={customStylesSelect}
      onChange={ (p) => {
          var totGuest = numGuestArray.guestAdults + numGuestArray.guestChildren;
          setTotalPrices((parseFloat(p.prezzo)+totalAddons+totalHourPrice) * totGuest);
          setTotalProductsPrice(parseFloat(p.prezzo));
          setCheckoutFieldProduct(p.id);
          setProductsProvider(p.providerId);
        }}
      />

)
}



async function renderProducts(setClickDateHour, dataCalProdotti) {
const hourConverted = setClickDateHour.replace(':', '-');

  var idExp = window.location.pathname;
  idExp = idExp.split('/');
  var idExperiences = idExp[2];


  const urlp = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/prodotti?serviceId=' + parseInt(idExperiences) + '&date=' + dataCalProdotti + '&hour=' + hourConverted;

    async function requestProducts() {
    return await new Promise(function (resolve, reject) {

      var xhr = new XMLHttpRequest();
      xhr.open('GET', urlp, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText);

        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      xhr.send(hourConverted);
    });
  }


  var getProduct = requestProducts();

  const detProds  = () => Promise.all([getProduct]).then((listProducts) => {
  return listProducts;
  });


  detProds().then((restprd) => {
    setProducts(JSON.parse(restprd));
    setGiriData(JSON.parse(restprd)[0].giri);
  });


}


//FINE API PRODOTTI



//GET ADDONS IN SELECT api


  const [makeAdds, setMakeAdds] = useState([]);



    const url3 = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/availabilities?serviceId=' + parseInt(idExperiences);


      async function makeadds() {
      return await new Promise(function (resolve, reject) {

        var xhr = new XMLHttpRequest();
        xhr.open('PUT', url3, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", 'Bearer ' + token);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
        xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.responseText);

          } else {
            reject({
              status: xhr.status,
              statusText: xhr.statusText
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        };

        xhr.send();
      });
    }


  var detailAdds = makeadds();

  const detAdds  = () => Promise.all([detailAdds]).then((listRequest) => {
  return listRequest;
  });

  useEffect(() => {

    detAdds().then((restads) => {
      var responseadds = JSON.parse(restads)

      setMakeAdds(responseadds);

    });

  }, []);


//FINE API ADDONS


  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>("startDate");

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };


  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };
//RENDER ADDONS PER PRENOTAZIONE FORM
  const rendercheckbox = () => {
    return(
    makeAdds.filter((f) => !allAdds.includes(f.id))
      .map((makeAdd, indext) => (
     <div key={'check-box-addons-'+indext} id={'check-id-addons-'+indext} className="block mt-3">
    <div className={`flex text-sm sm:text-base ${className}`}>
      <input
        key={'1-arr'+indext}
        id={indext+'-a'+indext}
        name={'name-'+indext}
        type="checkbox"
        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
        onChange={(e) => {

                          var totGuest = numGuestArray.guestAdults + numGuestArray.guestChildren;
                          if (e.target.checked) {
                            setFieldAddonsCheckout(addsPush => [...addsPush, makeAdd.id]);
                                if(parseInt(makeAdd.id) == 1 || parseInt(makeAdd.id) == 2){
                                  setTotalAddons(totalAddons+parseFloat(makeAdd.price));
                                  setTotalPrices(totalPrices+(parseFloat(makeAdd.price)));
                                  setTotalPricesPlus(totalPricesPlus+(parseFloat(makeAdd.price))); //compreso addons in più da voucher
                                }else{
                                  setTotalAddons(totalAddons+parseFloat(makeAdd.price));
                                  setTotalPrices(totalPrices+(parseFloat(makeAdd.price)*totGuest));
                                  setTotalPricesPlus(totalPricesPlus+(parseFloat(makeAdd.price)*totGuest)); //compreso addons in più da voucher
                                }
                          } else {
                            const arrToremove = [makeAdd.id];
                            setFieldAddonsCheckout(addsPush => (addsPush.filter(item => item != arrToremove)));

                            if(parseInt(makeAdd.id) == 1 || parseInt(makeAdd.id) == 2){
                            setTotalAddons(totalAddons-parseFloat(makeAdd.price));
                            setTotalPrices(totalPrices-(parseFloat(makeAdd.price)));
                            setTotalPricesPlus(totalPricesPlus-(parseFloat(makeAdd.price)));
                            }else{
                            setTotalAddons(totalAddons-parseFloat(makeAdd.price));
                            setTotalPrices(totalPrices-(parseFloat(makeAdd.price)*totGuest));
                            setTotalPricesPlus(totalPricesPlus-(parseFloat(makeAdd.price)*totGuest));
                            }
                          }

                        }}
      />
      {makeAdd.name && (
        <label
          htmlFor={'1-arr'+indext}
          key={'1-2-a'+indext}
          className="ml-3.5 flex flex-col flex-1 justify-center"
        >
          <span className=" text-neutral-900 dark:text-neutral-100">
            {makeAdd.name} {/*<small className="small-price-apc"> {' (€ ' + makeAdd.price + ' p.p.)'} </small>*/}
          </span>

        </label>
      )}
    </div>
    </div>
    )));
  }

  //RENDER ADDONS PER PRENOTAZIONE FORM GIA ACQUISTATI IN FASE DI VOUCHER
    const rendercheckboxInclusi = () => {
      return(
      makeAdds.filter((f) => !!allAdds.includes(f.id))
        .map((makeAdd, indext) => (
       <div key={'check-box-addons-'+indext} id={'check-id-addons-'+indext} className="block mt-3">
      <div className={`flex text-sm sm:text-base ${className}`}>
        <input
          key={'1-'+indext}
          id={indext+'-'+indext}
          name={'name-'+indext}
          type="checkbox"
          checked={true}
          disabled={true}
          className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
        />
        {makeAdd.name && (
          <label
            htmlFor={'1-'+indext}
            key={'1-2-'+indext}
            className="ml-3.5 flex flex-col flex-1 justify-center"
          >
            <span className=" text-neutral-900 dark:text-neutral-100">
              {makeAdd.name} <small className="small-price-apc"> {' (incluso nel voucher)'} </small>
            </span>

          </label>
        )}
      </div>
      </div>
      )));
    }






  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          {/*  <Badge color="pink" name={experienceData.categoryName} />*/}
        {/*  <LikeSaveBtns />*/}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {experienceData.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span className="text-red-700 font-bold">
            <i className="las la-map-marker-alt text-3xl"></i>
            <span className="ml-1"> {experienceData.address}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="">
        <div className=" pl-1 items-center space-y-3 sm:text-left ">
          <span style={{fontSize:'13px'}}>Eccezionale ・ 4,5</span>
        </div>
          <a href="https://it.trustpilot.com/review/adrenalinika.com" target="_blank" rel="noopener noreferrer"><img src={eccezionale} className="h-16" alt="recensioni" /></a>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 font-bold sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-clock  text-secondary-700 text-3xl"></i>
            <span style={{fontSize:'13px'}}>{!experienceData.duration || experienceData.duration == "undefined" ? "" : experienceData.duration == 60 ? '1 ora' : experienceData.duration == 360 ? 'mezza giornata' : experienceData.duration > 360 ? 'Intera giornata' : experienceData.duration > 1440 && experienceData.duration < 2940 ? '2 giorni' : experienceData.duration == 120 ? '2 ore' : experienceData.duration == 180 ? '3 ore' : experienceData.duration == 240 ? '4 ore' : experienceData.duration == 300 ? '5 ore' : experienceData.duration == 90 ? '1:30 ore' : experienceData.duration == 150 ? '2:30 ore' : experienceData.duration + ' minuti'}</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 font-bold  sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends  text-secondary-700 text-3xl"></i>
            <span style={{fontSize:'13px'}}>{experienceData.posti_massimi} pers.</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center font-bold  space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-cloud  text-secondary-700 text-3xl"></i>
            <span style={{fontSize:'13px'}}>{experienceData.lingue}</span>
          </div>

        </div>
      </div>
    );
  };



  const renderSection2 = () => {
    if(experienceData){
      var length = 35;
      var myString = "experienceData.description";
      var myTruncatedString = myString.substring(0,length);
    }
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Descrizione Esperienza</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div id="descriptionText" className="text-neutral-6000 dark:text-neutral-300" style={{maxHeight: '120px', overflow: 'hidden'}} dangerouslySetInnerHTML={{__html: experienceData.description}}>
        </div>
        <div style={{maxWidth:'128px'}}>
        <a id="deskBtn" className="nc-Button relative h-auto inline-flex text-center items-center justify-center rounded-full transition-colors text-sm sm:text-sm px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" style={{display:'block'}} onClick={(e) => {var element = document.getElementById("descriptionText"); element.style.cssText = ""; e.target.style="display:none"; var deskBtnno = document.getElementById("deskBtnno");deskBtnno.style.cssText = "display:block"; }}>leggi di più</a>
        <a id="deskBtnno" className="nc-Button relative h-auto inline-flex text-center items-center justify-center rounded-full transition-colors text-sm sm:text-sm px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" style={{display:'none'}} onClick={(e) => {var element = document.getElementById("descriptionText"); element.style.cssText = "max-height: 120px; overflow: hidden;"; var element = document.getElementById("deskBtn"); element.style.cssText = "display:none"; e.target.style="display:none"; var deskBtn = document.getElementById("deskBtn");deskBtn.style.cssText = "display:block";  }}>leggi meno</a>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Servizi inclusi </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Servizi inclusi nell'offerta.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {inclusi
            .filter((_, i) => i < 12)
            .map((item, indesc) => (
              <div id={'inclusi-'+indesc} key={indesc+'-inclusi'} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSectionNotIncluded = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Servizi non inclusi </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Servizi non inclusi nell'offerta.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {nonInclusi
            .filter((_, i) => i < 12)
            .map((item, ind) => (
              <div id={'non-inclusi-'+ind} key={ind+'-non-inclusi'} className="flex items-center space-x-3">
                <i className="las la-times-circle text-2xl"></i>
                <span>{item}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Disponibilità</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Seleziona la data per verificare la disponibilità e il prezzo.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="listingSection__wrap__DayPickerRangeController flow-root">
          <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">

          <ExperiencesDateSingleInput
            defaultValue={dateRangeValue.startDate}
            defaultTimeValue={timeRangeValue}
            onFocusChange={() => {
              setDateRangeValue(focusedInputSectionCheckDate);
              setVarVisibilityHour(false);
            }}
            numberOfMonths={1}
            variable={moment(getPrimoGiornoMese)}
            fieldClassName="p-5"
            wrapFieldClassName="flex flex-col w-full flex-shrink-0 relative divide-y divide-neutral-200 dark:divide-neutral-700"
            onChange={(focusedInputSectionCheckDate) => {
              setDateRangeValue(dateRangeValue);
              setControlForm(false);
              if(focusedInputSectionCheckDate){
                const setClickDate = moment(focusedInputSectionCheckDate['_d']).format("YYYY-MM-DD");
                renderTimeGuest(setClickDate);

              }
            }}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
          </div>
        </div>
      </div>
    );
  };



const renderhourfield = () => {
  if(!varVisibilityHour || !!makeCalendar){
    var totAdultsChild = parseInt(numGuestArray.guestAdults)+parseInt(numGuestArray.guestChildren);
    var filterMakeCalendar = makeCalendar.filter((_, i) => parseInt(makeCalendar[i].posti) >= parseInt(totAdultsChild));
    return(
      <div className="flex-1">

            <Select
                className={` coinsta w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                options={filterMakeCalendar}
                getOptionLabel={(filterMakeCalendar) => {

                  return(
                    !loadingApi ?
                <h4 className="text-md">  {`${filterMakeCalendar.value}`}
                </h4> : ''
                  )
                } }
                name={filterMakeCalendar.value}
                defaultValue = {filterMakeCalendar.value}
                isSearchable={false}
                isLoading={loadingApi}
                loadingMessage={() => 'Caricamento...'}
                value={filterMakeCalendar.prezzo}
                onChange={ (e) => {
                    const setClickDate = dateSelected;
                    renderTimeGuest(setClickDate);
                    var totGuest = numGuestArray.guestAdults + numGuestArray.guestChildren;
                    setTotalPrices((parseFloat(e.prezzo)+totalAddons+totalProductsPrice) * totGuest);
                    setMaxGuestAdult(parseFloat(e.posti));
                    setTotalHourPrice(e.prezzo);
                    setSelectedHour(e.value);
                    if(e.value){
                      setControlForm(true);
                    } else{
                      setControlForm(false);
                    }
                    var valoreoracorrente = e.value;
                    setSelectedHourConverted(valoreoracorrente.replace(':', 'D'));
                    setMakeCalendarProvider(e.provider);

                    if(focusedInputSectionCheckDate){
                    const setClickDateHour = e.data;
                    const dataCalProdotti = e.value;
                    renderProducts(dataCalProdotti, setClickDateHour);
                    }
                  }
                }
                placeholder={<div className="flex-grow"><span className="block xl:text-lg text-neutral-800 font-semibold">Ora</span><span className="block mt-1 text-sm text-neutral-400 leading-none font-light">orario</span></div>}
                styles={customStylesSelect2}

                        />

        </div>

    );
  }
}



  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Informazioni utili</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Politica di cancellazione</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          {experienceData.politica_cancellazione}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Requisiti di partecipazione</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            {experienceData.requisiti_partecipazione}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Regole di appuntamento</h4>
          <div className="prose sm:prose">
          {experienceData.regole_appuntamento}
          </div>
        </div>
      </div>
    );
  };


const renderBabyIcon = () => {
  return(
    <div className="inline"style={{background: '#028001', padding: '4px', borderRadius: '9999999px', color: 'white', fontSize: '16px', fontWeight: '600', marginLeft: '7px', paddingRight: '6px', letterSpacing: '-1px'}}>
     <i className="las la-child"></i> {parseInt(numGuestArray.guestChildren)}
     </div>
  );
}

const renderAdultIcon = () => {
  return(
    <div className="inline"style={{background: '#028001', padding: '4px', borderRadius: '9999999px', color: 'white', fontSize: '16px', fontWeight: '600', marginLeft: '7px', paddingRight: '6px', letterSpacing: '-1px'}}>
     <i className="las la-user"></i> {parseInt(numGuestArray.guestAdults)}
     </div>
  );
}

  const [getVisibilityReservation, setVisibilityReservation] = useState(false);
  const renderSidebar = () => {

    return (
      <div className="listingSection__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between ">
          <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
            Valore voucher  {" "}{"  "}
            <div>
            {parseInt(numGuestArray.guestAdults) > 0 ? renderAdultIcon() : ''}
            {parseInt(numGuestArray.guestChildren) > 0 ? renderBabyIcon() : ''}
            </div>
            </span>

            <span className="text-3xl font-semibold">
              € {parseFloat(importoScalare).toFixed(2)}
            </span>
        </div>

  {/* BOTTONI SCELTA INIZIALE */}
    {
    renderFormPrenotazione()
    }
        </div>

    );
  };







  const modalForMobileReservation = ({openModalMoreFilter}) => {
    return(
      <div>


        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Prenota esperienza
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto mb-20">
                    <div className="mt-10 px-10 mb-20">
                    <div className="flex justify-between pb-10 ">
                      <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">

                        <div>
                        {parseInt(numGuestArray.guestAdults) > 0 ? renderAdultIcon() : ''}
                        {parseInt(numGuestArray.guestChildren) > 0 ? renderBabyIcon() : ''}
                        </div>
                        </span>

                        <span className="text-3xl font-semibold">
                          € {parseFloat(importoScalare).toFixed(2)}
                        </span>
                    </div>
                    {renderFormPrenotazione()}

                    </div>
                  </div>


                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  }





  const renderFormPrenotazione = () => {
    var getProviderfrom = '';

    if(!getProductsProvider){
      getProviderfrom = getProductsProvider;
    } else {
      getProviderfrom = makeCalendarProvider;
    }

    {/* PRENOTA FORM */}
    return(

      <div className="">
        <form className="rounded-3xl">
        <div className="border rounded-3xl divide-x flex divide-neutral-200 dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700  ">
          <div className="flex-1">

          <ExperiencesDateSingleInput
          defaultValue={dateRangeValue.startDate}
          onFocusChange={() => {
            setDateRangeValue(focusedInputSectionCheckDate);
            setVarVisibilityHour(false);
          }}
          numberOfMonths={1}
          variable={moment(getPrimoGiornoMese)}
          fieldClassName="p-5"
          wrapFieldClassName="flex flex-col w-full flex-shrink-0 relative divide-y divide-neutral-200 dark:divide-neutral-700"
          onChange={(focusedInputSectionCheckDate) => {

            setDateRangeValue(dateRangeValue);
            if(focusedInputSectionCheckDate){
              const setClickDate = moment(focusedInputSectionCheckDate['_d']).format("YYYY-MM-DD");
              setDateSelected(moment(focusedInputSectionCheckDate['_d']).format("YYYY-MM-DD"));
              setDateSelectedConverted(moment(focusedInputSectionCheckDate['_d']).format("YYYY-MM-DD").replace('-', 'D').replace('-', 'D'));
              renderTimeGuest(setClickDate);
              setVarVisibilityHour(true);
              }
            }
          }
          anchorDirection={windowSize.width > 1400 ? "left" : "right"}
          />
          </div>


          {varVisibilityHour ? renderhourfield() : ''}
              </div>
          {moment(focusedInputSectionCheckDate['_d']).format("YYYY-MM-DD") && totalHourPrice > 0 ? renderSectionGuest() : ''}
          <div className="block mt-6">
          {prodotto && prodotto != '' && !!getProducts && getProducts != '' && moment(focusedInputSectionCheckDate['_d']).format("YYYY-MM-DD") && totalHourPrice > 0 && maxGuestAdult  ? renderSectionProducts() : ''}
          </div>
          {prodotto && prodotto != '' && !!getProducts && getProducts != '' && moment(focusedInputSectionCheckDate['_d']).format("YYYY-MM-DD") && totalHourPrice > 0 && maxGuestAdult && giri ?
          <div className="flex justify-between font-semibold mt-5">
          <span>Optional: </span>
          <div className="flex justify-between font-semibold">
          {!!getGiriData && getGiriData != '' && !!getProducts && !!prodotto && moment(focusedInputSectionCheckDate['_d']).format("YYYY-MM-DD") && totalHourPrice > 0 && maxGuestAdult ? getGiriData[0].label : ''}
            </div></div> : ""}
        <div className="block mt-6">
        {rendercheckboxInclusi() }
        {maxGuestAdult  ? rendercheckbox() : ''}
        </div>
        </form>
        {/* SUM */}
        <div className="flex flex-col space-y-4 mt-4">

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold mt-5">
          <span>Codice voucher</span>
          <div className="flex justify-between font-semibold">

            <small>{codice}</small>
          </div>
          </div>
          <div className={(parseFloat(parseFloat(totalPricesPlus)-parseFloat(importoScalare)).toFixed(2)) > 0 ? "flex justify-between font-semibold mt-5 " : "hidden" }>
          <span>Totale da pagare</span>
          <div className="flex justify-between font-semibold">

            <span style ={{textDecoration: "line-through", color: "#d80000"}}>€ {parseFloat(totalPricesPlus).toFixed(2)}</span>
            <span style ={{color: "green", paddingLeft: "5px", fontSize: "20px"}}>€ {parseFloat(parseFloat(totalPricesPlus)-parseFloat(importoScalare)).toFixed(2)}</span>
          </div>
          </div>
        </div>

        {/* SUBMIT */}
      <div className="flex justify-end mt-6">

        {controlForm && checkDisp ? <ButtonPrimary className="flex justify-between" targetBlank={true}
        href={"//www.adrenalinika.com/gestionale/index.php?key="+btoa("idExp="+idExperiences+"&startDate="+dateSelectedConverted+"&providerId="+getProviderfrom+"&startHour="+selectedHourConverted+"&endHour="+endHour+"&guestAdult="+numGuestArray.guestAdults+"&guestChildren="+numGuestArray.guestChildren+"&selectedProduct="+checkoutFieldProduct+"&addons="+getaddonsquery+(!!fieldAddonsCheckout.lenght || fieldAddonsCheckout != '' ? ","+Object.values(fieldAddonsCheckout).join(',') : '')+"&totalPrices="+(parseFloat(totalPricesPlus)-parseFloat(importoScalare))+"&idUs="+isNew+"&giri="+giri+"&codice="+codice+"&addsplus="+Object.values(fieldAddonsCheckout).join(',')+"&enddate="+endDate)} >Prenota</ButtonPrimary> : <ButtonSecondary className="flex justify-between" isDisabled={true}>Prenota</ButtonSecondary>}
        </div>
      </div>

      );
    };

  return (
    <div
      className={`nc-ListingExperiencesRiscatto  ${className}`}
      data-nc-id="ListingExperiencesRiscatto"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">

        </header>

      </>

      {/* MAIn */}
      <main className="container mt-11 flex ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
                prevImageHorizontal
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS.filter((_, i) => i >= 1 && i < 4).map((item, index) => (
              <div
                id={'photo-'+index}
                key={'photo-key-'+index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 2 ? "block" : ""
                }`}
              >
                <NcImage
                  id={index+'-img'}
                  containerClassName="aspect-w-4 aspect-h-3"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                  prevImageHorizontal
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Tutte le foto
              </span>
            </div>
          </div>
          {/* MODAL PHOTOS */}
          <ModalPhotos
            imgs={PHOTOS}
            isOpen={isOpen}
            onClose={handleCloseModal}
            initFocus={openFocusIndex}
          />
          {renderSection2()}
          {/*renderSection3()*/}
          {/*renderSectionNotIncluded()*/}
          {renderSection8()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
        <div className="container flex items-center justify-between">
          <span className="text-2xl font-semibold">
            € {parseFloat(importoScalare).toFixed(2)}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              (Valore voucher)
            </span>
          </span>

          <ButtonPrimary onClick={()=>{openModalMoreFilter(true)}}>Prenota</ButtonPrimary>
        </div>
      </div>
      {openModalMoreFilter && modalForMobileReservation(openModalMoreFilter)}
      {/* OTHER SECTION */}
      <div className="container py-24 lg:py-32">

      </div>
    </div>
  );
};

export default ListingExperiencesRiscatto;
