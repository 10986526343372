import React, { useEffect, useState } from "react";
import LocationInput from "./LocationInput";
import CategoryInput from "./CategoryInput";
import GuestsInput, { GuestsInputProps } from "./GuestsInput";
import ExperiencesDateSingleInput from "./ExperiencesDateSingleInput";
import ButtonSubmit from "./ButtonSubmit";
import moment from "moment";
import { FC } from "react";

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultLocationValue = "Milano, Italia";


export interface ExperiencesSearchFormProps {
  haveDefaultValue?: boolean;
  data: [];
  luoghi: [];
}

const ExperiencesSearchForm: FC<ExperiencesSearchFormProps> = ({
  haveDefaultValue,
  data,
  luoghi,
}) => {
  let [locationInputValue, setLocationInputValue] = useState("");
  let [categoryInputValue, setCategoryInputValue] = useState("");

  useEffect(() => {
    if (haveDefaultValue) {
      setLocationInputValue(defaultLocationValue);
      setCategoryInputValue(defaultCategoryValue);

    }
  }, []);

  //
  if(locationInputValue){
    locationInputValue = locationInputValue.toLowerCase();
    locationInputValue = locationInputValue.charAt(0).toUpperCase() + locationInputValue.slice(1);
  }
  if(categoryInputValue){
    categoryInputValue = categoryInputValue.toLowerCase();
    categoryInputValue = categoryInputValue.charAt(0).toUpperCase() + categoryInputValue.slice(1);
  }

  const renderForm = () => {
    return (
      <form className="w-full relative mt-0 flex flex-col md:flex-row md:items-center rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-700 md:mt-0 md:divide-y-0" style={{marginTop: "-50px", marginBottom: "50px"}}>
        <LocationInput
          defaultValue={locationInputValue}
          onChange={(e) => setLocationInputValue(e)}
          luoghi={luoghi}
          data={categoryInputValue}
        />
        <CategoryInput
          defaultValue={categoryInputValue}
          onChange={(e) => setCategoryInputValue(e)}
          data={data}
          luoghi={locationInputValue}
        />
        {/* BUTTON SUBMIT OF FORM */}
        <div className="px-4 py-4 lg:py-0">
          <ButtonSubmit luogo={locationInputValue} categoria={categoryInputValue} />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default ExperiencesSearchForm;
