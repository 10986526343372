import React, { FC } from "react";
import rightImgPng from "images/adrenalinika-experience.webp";
import card from "images/card-adrenalinika.webp";
import box from "images/box-adrenalinika.webp";
import rate from "images/scala-logo.webp";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          UN REGALO ADRENALINICO
        </span>
        <h2 className="font-semibold text-4xl mt-5">È sempre un emozione </h2>

        <ul className="space-y-10 mt-10">
          <li className="space-y-4 text-center md:text-left m-auto p-4">
            <img src={card} width="150" height="auto" alt="giftcard esperienze" className="text-center center md:text-left md:left m-auto md:hidden"  />
            <span className="block text-xl font-semibold">
              Gift Card Adrenalinika
            </span>
            <span className="block mt-5 text-neutral-500 text-sm dark:text-neutral-400">
              Il regalo perfetto per gli amanti delle esperienze adrenalinike: scegli solo l’importo, sarà poi il destinatario a decidere su quale avventura lanciarsi. La gift Card non ha scadenza.
            </span>
            <a
            href="/giftcard"
            children="Regala ora"
            className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-sm font-medium ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 text-center center bg-secondary-800 hover:bg-secondary-900 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 "
            />
          </li>
          <li className="space-y-4 text-center md:text-left m-auto bg-neutral-100 p-4 rounded-[20px]">
            <img src={box} width="220" height="auto" alt="box esperienze" className="text-center center md:text-left md:left m-auto md:hidden"  />
            <span className="block text-xl font-semibold">
              Cofanetto Regalo
            </span>
            <span className="block mt-5 text-neutral-500 text-sm dark:text-neutral-400">
            Se in fase di checkout aggiungi il cofanetto regalo al tuo ordine, otterrai lo Zaino Adrenalinika in omaggio.
            </span>
            <a
            href="/giftcard"
            children="Regala ora"
            className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-sm font-medium ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 text-center center bg-secondary-800 hover:bg-secondary-900 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 "
            />
          </li>
          <li className="space-y-4 text-center md:text-left m-auto p-4">
          <img src={rate} width="220" height="auto" alt="esperienze in 3 rate" className="sm:text-center sm:center m-auto md:hidden" />
            <span className="block text-xl font-semibold">
              Puoi pagare a rate
            </span>
            <span className="block mt-5 text-neutral-500 text-sm dark:text-neutral-400">
              Da oggi, le esperienze che ami in 3 rate, senza interessi!
            </span>
            <a
            href="/partner-scalapay"
            children="Scopri come"
            className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-sm font-medium ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 text-center center px-3 py-2 m-auto focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 "
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
