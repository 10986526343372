import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import React, { FC, useState, useEffect }from "react";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import { withRouter } from 'react-router';
import { Formik, Field, Form } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {FormForgot} from "containers/PageForgot/FormForgot";
import {loginUser} from "components/FormLogin/FunctionUser";
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import moment from "moment";

export interface PageGuestVoucherEnProps {
  className?: string;
}







const PageGuestVoucherEn: FC<PageGuestVoucherEnProps> = ({ className = "" }) => {
const [visibleCod, setVisibleCod] = useState(true);
const [visibleBtn, setVisibleBtn] = useState(false);
const [check, setCheck] = useState(false);
const [result, setResult] = useState();
const [code, setCode] = useState();



  return (
    <div className={`nc-PageGuestVoucher ${className}`} data-nc-id="PageGuestVoucher">
      <Helmet>
        <title>Redeem your voucher || Adrenalinika experiences </title>
      </Helmet>
      <div id="PageGuestVoucher"></div>
      <div id="paginalogPageGuestVoucher">
      <div className="container mb-24 lg:mb-32">
        <h1 className={`mt-20 mb-10 flex items-center text-2xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center ${visibleCod ? '' : 'hidden'}`}>
          Redeem your voucher
        </h1>
        <p style={{maxWidth:'680px'}} className={`m-auto mt-10 justify-center text-center text-small text-neutral-900 dark:text-neutral-100 justify-center`}>Already have a code to redeem? Enter it below and follow the procedure you will receive via email. Don't have a voucher yet? Explore the
our experiences.</p>
        <div className={`container max-w-md mb-14 mt-10 lg:mb-12 justify-center leading-[115%] md:leading-[115%] ${visibleBtn ? '' : 'hidden'}`} style={{background:"rgba(48,255,0,0.32)", borderRadius:"100px"}}>
        <h2 className={`mt-10 my-5 flex pt-4 items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center text-center ${visibleBtn ? '' : 'hidden'}`}>
          </h2><span  className={`my-0 flex pb-6 items-center text-sm leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center pb-10 text-center ${visibleBtn ? '' : 'hidden'}`}>{result}</span>
          </div>
        <ToastContainer />
        <div className="max-w-md mx-auto space-y-6">
          {/* OR */}
          <div className="relative text-center">
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2  border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}

            <Formik
              initialValues={{ codice: ''}}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                 actions.setSubmitting(false);
               }, 3000);
               var code = values.codice;






                var codice = JSON.stringify(code);
                const xhr = new XMLHttpRequest();
                const url = 'https://www.adrenalinika.com/gestionale/ajax_exemple/voucher/riscatto-guest-voucher.php';
                var tn = "Adrenalinika";
                var token = hashToken(tn);

                xhr.open('POST', url, true);
                //xhr.setRequestHeader("Content-Type", "application/json");
                xhr.setRequestHeader("Authorization", 'Bearer ' + token);
                xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
                xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
                xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                xhr.onreadystatechange = function () {
                // In local files, status is 0 upon success in Mozilla Firefox
                if(xhr.readyState === XMLHttpRequest.DONE) {
                  var status = xhr.status;

                  if (status === 0 || status >= 200 && status < 400) {

                    if(status == 200 && xhr.responseText != 0 && xhr.responseText != ''){
                      toast.success('Codice verificato con successo.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                        setVisibleCod(false);
                        setVisibleBtn(true);
                        setResult(xhr.response);
                        setCode(codice);

                    } else if (xhr.responseText == 0 || !xhr.responseText || xhr.responseText == ''){
                      toast.error('Il codice inserito non è valido o è scaduto. Si prega di contattare gli operatori di Adrenalinika.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                        setVisibleCod(true);
                        setVisibleBtn(false);
                    }


                  } else {
                    // Oh no! There has been an error with the request!

                    toast.error('Errore di connessione al server. Ti invitiamo a riprovare tra poco o a contattare Adrenalinika attraverso altri canali.', {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      });
                      setVisibleCod(true);
                      setVisibleBtn(false);
                  }
                }
              };
              xhr.send("codice="+codice);

                }}

            >
            {
              props => (
                <form id="formCode" className={`grid grid-cols-1 gap-6 ${visibleCod ? '' : 'hidden'}`} onSubmit={props.handleSubmit}>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">

                    </span>
                    <Input
                      type="text"
                      placeholder=""
                      className="mt-1"
                      id="codice"
                      name="codice"
                      placeholder="Cod. Voucher"
                      value={props.values.codice}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                  </label>
                  {props.errors.codice && <div id="feedback">{props.errors.codice}</div>}
                  <ButtonPrimary type="submit">Continue</ButtonPrimary>
                </form>

      )}
      </Formik>

      <Formik
      initialValues={{ mail: ''}}
      onSubmit={(values, actions) => {
      setTimeout(() => {
        actions.setSubmitting(false);
       }, 3000);
       //invia dati controllo mail

       var codesmail = values.mail;
       var coded = JSON.parse(code);
       var tots = {
         code: coded,
         mail: codesmail,
       }



        var data = JSON.stringify(tots);
        const xhr = new XMLHttpRequest();
        const url = 'https://www.adrenalinika.com/gestionale/ajax_exemple/voucher/mail-riscatto.php';
        var tn = "Adrenalinika";
        var token = hashToken(tn);

        xhr.open('PUT', url, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", 'Bearer ' + token);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
        xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
        //xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onreadystatechange = function () {
        // In local files, status is 0 upon success in Mozilla Firefox
        if(xhr.readyState === XMLHttpRequest.DONE) {
          var status = xhr.status;

          if (status === 0 || status >= 200 && status < 400) {

            if(status == 200 && xhr.responseText != 0 && xhr.responseText != ''){
              toast.success('Check your email box to complete the reservation.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });


            } else if (xhr.responseText == 0 || !xhr.responseText || xhr.responseText == ''){
              toast.error('The code entered is invalid or has expired. Please contact Adrenalinika operators.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });

            }


          } else {
            // Oh no! There has been an error with the request!

            toast.error('Server connection error. Please try again soon or contact Adrenalinika through other channels.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

          }
        }
      };
      xhr.send(data);

        }}
    >
                  {
        props => (
      <form id="formMail" className={`grid grid-cols-1 gap-6 ${visibleBtn ? '' : 'hidden'}`} onSubmit={props.handleSubmit}>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            Your email
          </span>
          <Input
            type="email"
            placeholder=""
            className="mt-1"
            id="mail"
            name="mail"
            value={props.values.mail}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
        </label>
        <div className="flex flex-row">
        <input
          key="privacy"
          id="privacy"
          name="privacy"
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              setCheck(true);
            } else {
              setCheck(false);
            }
          }}
          className=" flex mr-3 flex-row focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
          /> <span className="flex flex-row text-extra-small ">I consent to the processing of personal data according to current regulations.</span>
          </div>
        {props.errors.mail && <div id="feedback">{props.errors.mail}</div>}
        <ButtonPrimary type="submit">Book your experience</ButtonPrimary>
        <p className="text-center">You will receive an email containing a link. You can choose a date based on the availability of the structure.</p>
      </form>



    )}
    </Formik>

          </div>
          </div>
          </div>

          </div>
  );
};

export default PageGuestVoucherEn;
