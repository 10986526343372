import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";

export interface PageRegolamentoProps {
  className?: string;
}

const PageRegolamento: FC<PageRegolamentoProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageRegolamento"
    >
      <Helmet>
        <title>Regolamento, Termini e Condizioni || Attività Adrenalinike</title>
        <link rel="canonical" href="https://www.adrenalinika.com/regolamento" />
        <meta name="description" content="Adrenalinika è una piattaforma per prenotare o regalare le esperienze in Italia. Scegli esperienze adrenaliniche, avventure immersive o corsi spericolati." />
      </Helmet>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
      <div className="nc-Section relative">
        <Heading desc="">
        Termini e Condizioni Adrenalinika SRL
        </Heading>
        <div className="">

        <div className="">
        <h4 className="text-neutral-6000 text2 dark:text-neutral-300 mb-10">
        <b>PREMESSA</b> <br />
        1. INTRODUZIONE E DEFINIZIONI <br />
        2. CREAZIONE ORDINI E CONTRATTO <br />
        3. PAGAMENTO E PREZZI ESPERIENZE <br />
        4. PROCESSO DI PRENOTAZIONE <br />
        5. CONSEGNA <br />
        6. ATTIVAZIONE DELLE ESPERIENZE/SERVIZIO <br />
        7. PARTNER <br />
        8. ESPERIENZA/SERVIZIO <br />
        9. CANCELLAZIONE ESPERIENZA/SERVIZIO <br />
        10. RESPONSABILITÀ, ACCESSO E OPERAZIONI AL SITO <br />
        11. SERVIZIO CLIENTI E RECLAMI <br />
        12. IMPEGNO E OBBLIGHI DEL CLIENTE <br />
        13. PROPRIETA’ <br />
        14. MODIFICHE <br />
        15. MODIFICHE AI TERMINI E CONDIZIONI CLIENTI <br />
        16. PROTEZIONE DEI DATI PERSONALI <br />
        </h4>

        </div>

    <div className="text-neutral-6000 dark:text-neutral-300">
    <span className="text-neutral-6000 text-xl dark:text-neutral-300">PREMESSA</span>
    <p>Con i presenti “Termini e Condizioni” si intende definire le regole inerenti alla vendita delle Esperienze/Servizi e al loro utilizzo. I presenti sono validi a far data dal 1 Dicembre 2022 per qualsiasi acquisto e/o utilizzo effettuato a partire da detta data.<br />
    Adrenalinika srl si riserva il diritto di modificare i presenti “Termini e Condizioni”<br />
    <br />
    in qualsiasi momento, senza preavviso. In caso di modifica, le suddette applicabili saranno quelle in vigore alla data di acquisto o di utilizzo dell’Esperienza/Servizio. Una copia dei suddetti “Termini e Condizioni” è consultabile online e può essere stampata direttamente dal Sito on-line nella sezione “Regolamento”<br />
    Qualsiasi Utente/Acquirente/Beneficiario dichiara di avere almeno 18 anni e la capacità di agire, in conformità all’articolo 2 del Codice Civile, per contrattare e utilizzare il Sito in conformità ai “Termini e Condizioni” riportati.<br />
    Adrenalinika srl pubblica e commercializza al dettaglio Voucher elettronici di pacchetti relativi ad attività Outdoor effettuate via internet, in nome e per conto dei suoi Partner che forniscono le Prestazioni proposte nelle Esperienze stesse. Adrenalinika è una società italiana, avente sede legale in Milano, Via Fabio Filzi 2, iscritta presso Camera di Commercio sita in Milano con codice REA: MI-2663406 e con sede operativa sita in Vertemate con Minoprio in Via Canturino 22, Milano.</p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">1. INTRODUZIONE E DEFINIZIONI</span>
    <p>1.1 I termini qui di seguito definiti, avranno i seguenti significati nei “Termini e Condizioni” di Vendita dell’Esperienza/Servizio:<br />
    • Acquirente: designa la persona che acquista un’Esperienza/Servizio;<br />
    • Beneficiario: designa la persona che utilizza l’Esperienza/Servizio;<br />
    • Acquisto online: designa l’acquisto di un‘Esperienza/Servizio;<br />
    • Cookie: designano dei piccoli file inviati sul disco rigido del computer<br />
    dell’Utente, allo scopo di facilitare la navigazione sul Sito e di proporgli le pagine che corrispondono meglio ai suoi interessi nelle successive visite presso il sito Adrenalinika;<br />
    • Dati personali: designano tutte le informazioni che permettono di identificare, direttamente o indirettamente, una persona fisica (cognome, nome, e-mail, ecc.);<br />
    • Esperienza: Le Esperienze/Servizio si dividono in diversi formati: il “Cofanetto regalo” o “GiftBox”, il “Voucher”, la “Gift Card”:<br />
    1. “Cofanetto regalo” o “GiftBox”, si presenta in formato fisico con una scatola che contiene un portachiavi firmato Adrenalinika<br />
    Experience, nonché una guida cartacea che presenta Le condizioni<br />
    di prenotazione di Adrenalinika srl.<br />
    2. “Zaino/Sacca Adrenalinika” si presenta in formato fisico nonché in<br />
    tessuto e spesso utilizzato come zaino/sacca sport;<br />
    3. “Voucher” come versione virtuale del Cofanetto regalo.<br />
    4. “Gift Card” corrispondente ad una versione elettronica, contenente<br />
    un codice univoco utilizzabile unicamente sul sito on-line per acquistare una Esperienza/Servizio.<br />
    • Prestazione: designa la prestazione fornita dal Partner al Beneficiario tra la selezione di offerte contenute nell’Esperienza/Servizio corrispondente. Le prestazioni presentano contenuti di varia natura indicabili come Esperienze/Servizi (ad es. Lancio con Paracadute, Volo in Mongolfiera, Escursione in Quad, Cena e Notte in Baita in Motoslitta, Rafting, ecc..)<br />
    • Sito: designa il sito internet Adrenalinika, con link seguente https://www.adrenalinika.com/<br />
    • Utente: designa la persona che naviga su o si collega al Sito, senza essere necessariamente un Acquirente o un Beneficiario.<br />
    </p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">2. CREAZIONE ORDINI E CONTRATTO</span>
    <p>2.1 L’Acquirente può effettuare gli Acquisti online di Esperienze/Servizio direttamente via Internet sul sito www.adrenalinika.com<br />
    2.2 La procedura di effettuazione degli ordini sul Sito comporta i seguenti passaggi:<br />
    • L’Acquirente seleziona sul Sito uno o più Esperienze/Servizio;<br />
    • L’acquirente seleziona all’occorrenza tramite pulsante la possibilità di<br />
    effettuare preventivamente una prenotazione in base alle disponibilità presenti sul calendario di ogni singola esperienza/servizio. Potrà scegliere di continuare l’acquisto e prenotare l’esperienza/servizio anche successivamente, entro 12 mesi dalla data di acquisto;<br />
    L’Acquirente sarà allora invitato a scegliere il formato della sua Esperienza/Servizio e le modalità di consegna (unicamente per i Cofanetti fisici o Zaino/Sacca Adrenalinika). L’Acquirente avrà anche la possibilità di inserire un Codice Promozionale all’occorrenza;<br />
    Per continuare l’ordine, l’Acquirente potrà procedere senza dover creare un account, compilando i campi necessari all’invio dell’ordine, oppure<br />
    potrà creare o collegarsi al proprio account, cliccando sul link previsto a tal fine;<br />
    2.3 La creazione di un account dà automaticamente accesso ad un Area Personale, che permette all’Acquirente di gestire:<br />
    • Informazioni dell’account<br />
    • Modifica della password<br />
    • Gestione dei voucher attivi/scaduti/utilizzati<br />
    • Gestione delle prenotazioni attive o passate<br />
    • Codici attivi/scaduti utilizzati per Cofanetto Regalo o Zaino/Sacca Adrenalinika<br />
    • Logout<br />
    • Ordini<br />
    • Eventuali Esperienze/Servizi<br />
    • Informazioni personali indicati nell’apposito spazio dedicato<br />
    2.4 L’Acquirente garantisce, a questo proposito, che tutte le informazioni che fornirà a Adrenalinika srl saranno corrette e prive di informazioni ingannevoli.<br />
    L’Acquirente si impegna ad aggiornare i propri dati, attraverso il proprio spazio personale in caso di variazione.<br />
    2.5 L’Acquirente è informato e accetta che le informazioni inserite ai fini della creazione o dell’aggiornamento del proprio account valgono come prova della sua identità e lo impegnano sin dalla loro conferma.<br />
    L’Acquirente si impegna a mantenere la riservatezza dei dati inerenti al proprio account. Ogni intervento effettuato con i dati di registrazione personale dell’Acquirente sarà considerato effettuato da quest’ultimo e l’Acquirente ne resta l’unico responsabile. L’Acquirente solleva quindi espressamente Adrenalinika srl da ogni responsabilità in caso di utilizzo abusivo da terzi dei propri elementi di identificazione e/o del proprio account.</p>

    <p> In caso di perdita, di furto o di qualsiasi utilizzo fraudolento dei suoi username e/o password, l’Acquirente dovrà immediatamente informare Adrenalinika srl al fine di ottenerne la sostituzione.<br />
    Dopo aver selezionato l’ordine, l’acquirente verificherà i dettagli del suo ordine e relative modifiche da apportare prima della registrazione definitiva. La verifica di questo ordine si rifà ad elementi quali il contenuto, le scelte e il prezzo totale delle Esperienze/Servizio selezionate, i dettagli, le eventuali spese di spedizione nonché il metodo di pagamento.<br />
    La registrazione dell’ordine sarà realizzata dopo l’accettazione dei presenti “Termini e Condizioni”. La vendita sarà considerata definitiva solo dopo l’accettazione del mezzo di pagamento utilizzato e l’incasso da parte di Adrenalinika srl .<br />
    2.6 Per i pagamenti con carta bancaria, il Voucher Elettronico di Esperienza/Servizio sarà da considerarsi ufficialmente terminato dopo l’accettazione del pagamento da parte della Banca.<br />
    Adrenalinika srl si riserva il diritto di annullare o rifiutare ordini dell’Acquirente in cui emerga una vertenza relativa al pagamento di un ordine precedente rimasto insoluto, o in caso di grave sospetto di ordine fraudolento.<br />
    2.7 Dopo la conferma definitiva dell’ordine, Adrenalinika srl invierà un e-mail di conferma dell’ordine stesso all’Acquirente, che comprende tutte le informazioni relative previste dall’ Esperienza/Servizio. Stampando e/o conservando questa e-mail, l’Acquirente disporrà di una prova di attestazione di ordine effettuato. Nel caso dell’ordine di Voucher/Gift Card o Cofanetto Regalo, questa e-mail di conferma dell’ordine dovrà obbligatoriamente essere conservata<br />
    dall’Acquirente e, all’occorrenza, essere trasmessa al Beneficiario. Detta e-mail sarà richiesta da Adrenalinika srl e/o Partner come mezzo di prova dell’Acquisto in caso di problemi di non attivazione dell’Esperienza /Servizio.</p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">3. PAGAMENTO E PREZZI ESPERIENZE</span>
    <p>3.1 I prezzi delle Esperienze/Servizio visualizzati sul Sito adrenalinika.com sono quelli in vigore nel giorno dell’ordine effettuato. La vendita delle Esperienze /Servizio è soggetta a IVA, pertanto tutti i prezzi sul sito si ritengono IVA inclusa. Per i Cofanetti regalo e Zaino/Sacca, le spese di spedizione sono da considerarsi comprese nel prezzo, salvo indicazione esplicita da parte di Adrenalinika srl. Queste spese sono fatturate in aggiunta e indicate prima della conferma finale dell’ordine.</p>

    <p> 3.2 Condizioni di Pagamento:<br />
    Il pagamento dell’ordine potrà essere effettuato, secondo preferenza espressa dall’Acquirente, con Carta Bancaria, Paypal, Scalapay, Stripe, Welfare o Bonifico Bancario nelle modalità seguenti:<br />
    • Carta bancaria (Carta Bancomat, Carta Visa, Eurocard/Mastercard,<br />
    Postepay,ecc): indicando direttamente nello spazio previsto a tal fine, il numero di carta, la sua data di validità, nonché il codice di controllo indicato sul retro della carta, l’intero importo dell’ordine sarà addebitato sulla Carta Bancaria il giorno stesso dell’ordine. Adrenalinika srl si riserva il diritto di sospendere ogni acquisto in fase di elaborazione, qualora si definisca una mancata autorizzazione di pagamento da parte della Banca. Il Sito permette all’Acquirente di trasmettere i propri dettagli bancari in modo riservato e sicuro al momento dell’ordine (inserimento sicuro e crittografato SSL);<br />
    • Con Paypal: l’Acquirente dovrà semplicemente accedere al suo Conto Paypal, se ne ha già uno, o creare(non obbligatorio) un conto Paypal seguendo le procedure che gli saranno indicate;<br />
    • Con Stripe: questa modalità di pagamento sarà proposta all’Acquirente solo se questi sceglie di pagare con Carta Bancaria. Gli sarà sufficiente accedere al suo conto Stripe, se ne ha già uno, o creare(non obbligatorio) un conto Stripe seguendo le procedure che gli saranno indicate;<br />
    • Con Scalapay: questa modalità di pagamento sarà proposta<br />
    all’Acquirente solo se questi sceglie di pagare con Carta Bancaria. Gli sarà sufficiente accedere al suo conto Scalapay, se ne ha già uno, o creare un conto Scalapay seguendo le procedure che gli saranno indicate. L’acquisto con Scalapay permette l’immediata ricezione dell’ordine ed il relativo pagamento effettuato in 3 rate. Si prenda atto che le rate verranno cedute a Incremento SPV S.r.l., a soggetti correlati e ai loro cessionari, e che autorizzi tale cessione;<br />
    • Con Portale Welfare aziendale: questa modalità di pagamento sarà proposta all’Acquirente solo se questi sceglie di pagare tramite portale Welfare convenzionato con Adrenalinika srl, comunicando il codice rilasciato dalla struttura welfare associata e solo dopo la verifica da parte di Adrenalinika srl.;</p>

    <p> Il suddetto pagamento avverrà in crediti welfare di cui l’acquirente è in<br />
    possesso;<br />
    • Con Bonifico Bancario: questa modalità di pagamento sarà proposta<br />
    all’Acquirente solo se questi sceglie di pagare tramite Bonifico Bancario. L’Acquirente riceverà tramite mail tutte le coordinate per procedere al pagamento dell’ Esperienza/Servizio, inviando copia di ricevuta all’indirizzo indicato, al fine di proseguire con l’elaborazione e successivo completamento dell’ordine richiesto;<br />
    3.3 Sicurezza sul pagamento:<br />
    Al fine di proteggere l’Acquirente e Adrenalinika srl da pratiche fraudolente, gli ordini di Esperienze/Servizio sono sottoposti a controlli regolari, con conseguente attuazione di procedure previste per casi di contrasto. Adrenalinika srl potrà sospendere il trattamento di un ordine e contattare l’Acquirente o il Beneficiario per ottenere documenti giustificativi necessari allo sblocco dell’ordine, i quali dovranno corrispondere ai dettagli di consegna e di fatturazione inseriti al momento dell’ordine nonché, ai dettagli del titolare della carta di pagamento utilizzata.<br />
    Adrenalinika srl potrebbe fungere da intermediario tra le due parti, nel caso in cui un ordine presentasse un indirizzo di consegna diverso dall’indirizzo di fatturazione. Le informazioni ottenute in questo modo potranno essere oggetto di un trattamento automatizzato di dati, con lo scopo di definire un livello di sicurezza delle transazioni e individuare, nonché contrastare, eventuali azioni fraudolente relative alle carte bancarie.<br />
    Questi giustificativi potranno essere richiesti per e-mail, per posta o per telefono<br />
    e la loro mancata trasmissione potrà comportare l’annullamento dell’ordine.<br />
    3.4: Diritto di Recesso/Rimborso:<br />
    Ai sensi degli articoli 52 e ss. del D.lgs. n. 206/2005 (“Codice del Consumo”), l’Acquirente può richiedere il Rimborso del Voucher Elettronico d’acquisto, senza dover fornire alcuna motivazione, entro quattordici (14) giorni a partire dal giorno in cui l’Acquirente entra in possesso dell’Esperienza/Servizio dell’ordine via e-mail, con relativa conferma e pagamento effettuato. Il Rimborso è possibile solo ed esclusivamente per l’acquisto del Voucher Elettronico a data aperta, quindi non è possibile qualora l’ Acquirente abbia già prenotato una data a calendario, presente sul sito on-line.</p>

    <p> Adrenalinika srl non emetterà alcun Rimborso ad Acquirenti che non abbiano effettuato il pagamento per il servizio per il quale si richiede il rimborso. Non è possibile richiedere rimborsi per acquisti pagati tramite Credito Welfare Aziendale. Il costo del Cofanetto Regalo o dello Zaino/Sacca Adrenalinika non verrà rimborsato.<br />
    All’importo del Rimborso verrà trattenuto:<br />
    – da Adrenalinika srl l’importo di € 15,00 per spese amministrative e gestione del rimborso;<br />
    – la tariffa trattenuta automaticamente dalla piattaforma di pagamento utilizzata dal cliente in fase di acquisto (PayPal, Stripe, Scalapay), che varia dall’1,6% al 3% in base all’importo dell’ordine.<br />
    Questo Rimborso non si applica agli Acquisti online effettuati utilizzando un credito Gift Card Adrenalinika srl.<br />
    3.5 Il cliente, ha diritto di recedere dal contratto ai sensi dell’ art.52 codice del consumo (D.lgs. 6 settembre 2005, n. 206 – Aggiornato al 11 Maggio 2019), comunicando la sua volontà mediante Lettera Raccomandata A/R da inviare tassativamente entro 14 giorni lavorativi dalla data di acquisto.<br />
    Alla ricezione della Lettera Raccomandata, Adrenalinika srl provvederà al rimborso entro 15/30 giorni lavorativi, attraverso comunicazione diretta al cliente. Il rimborso avverrà tramite Bonifico Bancario o tramite il metodo di pagamento utilizzato dal cliente in fase di acquisto (PayPal, Stripe, Scalapay).<br />
    Non sono previsti rimborsi nei seguenti casi:<br />
    • Prenotazione dell’esperienza online. In tal caso l’articolo verrà ritenuto<br />
    utilizzato e non sarà possibile effettuare richiesta di Recesso;<br />
    • Sospensione o Annullamento degli eventi per forze di causa maggiore<br />
    (pandemia, pista inagibile, previsione forte mal tempo, etc) e Sicurezza del cliente. In tal caso si consideri responsabilità indipendente dall’Acquirente, al quale verranno indicate proposte alternative.<br />
    3.6 La richiesta di rimborso tramite Lettera Raccomandata A/R dovrà contenere:<br />
    1. Nome e Cognome dell’Acquirente;<br />
    2. Indirizzo di Residenza (Via, Numero civico, CAP, Località, Provincia);<br />
    3. Numero Ordine e Codice Voucher (contenuti nella e-mail di conferma acquisto);<br />
    4. Data di acquisto;</p>

    <p> 5. Recapito telefonico ed e-mail;<br />
    6. Dati bancari dell’Acquirente (Banca, IBAN e Intestatario del conto corrente); 7. Fotocopie firmate dei documenti dell’Acquirente fronte e retro (Carta d’Identità, Codice Fiscale).<br />
    e dovrà essere spedita all’indirizzo di seguito: Adrenalinika srl<br />
    Via Canturino, 22<br />
    22070 Vertemate con Minoprio (CO)</p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">4. PROCESSO DI PRENOTAZIONE</span>
    <p>4.1 Attraverso il processo di prenotazione l’Acquirente potrà scegliere l’Esperienza/Servizio all’interno del sito on-line, generando un Voucher Elettronico da utilizzare all’interno di un determinato arco temporale. Attraverso la scelta e l’acquisto dell’Esperienza/Servizio scelto, l’Acquirente potrà definire una data specifica a calendario inserito all’interno del sito on-line di Adrenalinika srl, generando così una richiesta di prenotazione al Partner presso cui l’Esperienza/Servizio verrà erogata.<br />
    4.2 Eseguito l’accesso al Sito on-line, l’Acquirente potrà accedere all’Esperienza/Servizio selezionando tra le Attività Adrenaliniche, il relativo Luogo, Tipologia o Categoria di Esperienza/Servizio, con successiva definizione del pacchetto preferito.<br />
    L’Acquirente a questo proposito potrà gestire il proprio acquisto dell’Esperienza/Servizio nelle seguenti modalità:<br />
    • Attraverso l’opzione di Acquisto Voucher Elettronico: successivamente alla scelta dell’Esperienza/Servizio, l’Acquirente potrà procedere con la definizione del numero di Ospiti Partecipanti e con la selezione delle opzioni facoltative Zaino/Sacca Adrenalinika o Gift Box, per poi procedere all’Acquisto del Voucher Elettronico. Si richiederà in questa fase di inserire dati anagrafici e gli estremi identificativi richiesti per il pagamento, così da consentire l’autorizzazione, che verrà eseguita attraverso un sistema di pagamento sicuro. L’inserimento degli estremi identificativi delle carte di pagamento comporta, da parte di Adrenalinika srl una conferma di addebito di spesa all’Acquirente.</p>

    <p> L’Acquirente durante la compilazione in fase di pagamento è tenuto a fornire ad Adrenalinika srl tutte le informazioni richieste, con responsabilità a Sua carico della veridicità delle suddette informazioni.<br />
    Al termine del pagamento, l’Acquirente riceverà tramite e-mail una conferma in merito all’acquisto del Voucher Elettronico, in cui è riportato all’interno un Codice identificativo, necessario per procedere alla richiesta di prenotazione data, seguito da un link diretto di collegamento al sito on-<br />
    line, con cui l’Acquirente potrà accedere, inserire le proprie credenziali tali come Username, Password e Codice identificativo e procedere alla scelta di una data a calendario, elaborando cosi una richiesta di prenotazione.<br />
    Il Partner ha 48 ore di tempo dal momento della ricezione della Richiesta di Prenotazione per accettarla ovvero rifiutarla espressamente. Il Partner è sempre tenuto a fornire un riscontro all’Acquirente, sia in caso di conferma positiva o negativa per la richiesta di prenotazione. In caso di mancata risposta da parte del Partner entro le 48 ore, si avranno a disposizione altre 24 ore di attesa: al termine delle suddette, l’esito è da considerarsi automaticamente negativo, pertanto la richiesta di prenotazione da parte dell’Acquirente sarà sistematicamente annullata e si procederà ad una nuova richiesta, sempre selezionando una data di preferenza a calendario, in base alle disponibilità fornite.<br />
    Nel caso in cui la Richiesta di Prenotazione sia accettata, l’erogazione del Servizio/Esperienza scelta è stipulato definitivamente, attraverso una notifica di ricezione inviata tramite e-mail contenente il riepilogo della prenotazione nel dettaglio.<br />
    Nel caso in cui la Richiesta di Prenotazione sia rifiutata, il Partner e/o Adrenalinika srl si riserva il diritto di offrire all’Acquirente una data o un orario alternativi, attraverso una nuova proposta di prenotazione, da intendersi come controproposta alla Richiesta di Prenotazione formulata dall’Acquirente.<br />
    L’Acquirente ha la possibilità di scegliere se accettare o rifiutare la Proposta Alternativa a lui offerta, con conseguente conferma di prenotazione tramite notifica inviata con e-mail. È da intendersi che le Attività offerte dai Partners all’interno del Sito sono soggette alla previa verifica da parte dei Partners della disponibilità. L’assenza di disponibilità da parte del Partner, per la prima richiesta di prenotazione effettuata dallo stesso Acquirente, permetterà all’Acquirente di concordare una nuova data tra quelle future disponibili, con conseguente conferma di</p>

    <p> esito negativo o positivo da parte del Partner ed eventuale conferma di prenotazione o nuova proposta di data alternativa. In caso di esito negativo da parte del Partner, l’Acquirente potrà accettare una nuova proposta di data selezionata dal Partner appunto, oppure, una volta cancellata la prenotazione potrà scegliere di intervenire sul Suo acquisto attraverso un Cambio Esperienza/Servizio.<br />
    • Attraverso l’opzione di Prenotazione dell’Esperienza/Servizio:<br />
    successivamente alla scelta dell’Esperienza/Servizio, l’Acquirente potrà procedere con la selezione delle opzioni facoltative Zaino/Sacca Adrenalinika o Cofanetto regalo/Gift Box, per poi selezionare una data di preferenza, tra le opzioni proposte a calendario.<br />
    A questo punto l’Acquirente potrà attivare una richiesta di prenotazione tramite la compilazione dei dati richiesti successivamente alla pagina di Dettagli dell’Esperienza/Servizio. In questa fase l’Acquirente è tenuto a fornire al Partner le informazioni richieste, tra cui data, orario e numero di Partecipanti.<br />
    Compilata la Richiesta di Prenotazione, l’Acquirente provvederà all’inserimento degli estremi identificativi richiesti per il pagamento, così da consentire l’autorizzazione, che verrà eseguita attraverso un sistema di pagamento sicuro. L’inserimento degli estremi identificativi delle carte di pagamento comporta, da parte di Adrenalinika srl una conferma di addebito di spesa all’Acquirente.<br />
    L’Acquirente durante la compilazione in fase di pagamento è tenuto a fornire ad Adrenalinika srl tutte le informazioni richieste, con responsabilità a Sua carico della veridicità delle suddette informazioni.<br />
    Al termine del pagamento, l’Acquirente riceverà tramite e-mail una conferma in merito alla richiesta di prenotazione data effettuata, in cui è riportato all’interno un Codice identificativo, necessario per visionare i dettagli dell’Esperienza/Servizio acquistata sul sito on-line, seguito da un link diretto di collegamento al sito on-line appunto, con cui l’Acquirente potrà accedere, inserire le proprie credenziali tali come Username, Password e Codice identificativo e procedere alla lettura dei dettagli dell’Esperienza/Servizio o prendere visione dello storico acquisti personale. Il Partner ha 48 ore di tempo dal momento della ricezione della Richiesta di Prenotazione per accettarla ovvero rifiutarla espressamente. Il Partner è sempre tenuto a fornire un riscontro all’Acquirente, sia in caso di conferma positiva o negativa per la richiesta di prenotazione. In caso di</p>

    <p> mancata risposta da parte del Partner entro le 48 ore, l’esito è da considerarsi automaticamente negativo.<br />
    Nel caso in cui la Richiesta di Prenotazione sia accettata, l’erogazione del Servizio/Esperienza scelta è stipulato definitivamente, attraverso una notifica di ricezione inviata tramite e-mail contenente il riepilogo della prenotazione nel dettaglio.<br />
    Nel caso in cui la Richiesta di Prenotazione sia rifiutata, il Partner e/o Adrenalinika srl si riserva il diritto di offrire all’Acquirente una data o un<br />
    orario alternativi, attraverso una nuova proposta di prenotazione, da intendersi come controproposta alla Richiesta di Prenotazione formulata dall’Acquirente.<br />
    L’Acquirente ha la possibilità di scegliere se accettare o rifiutare la Proposta Alternativa a lui offerta, con conseguente conferma di prenotazione tramite notifica inviata con e-mail. È da intendersi che le Attività offerte dai Partners all’interno del Sito sono soggette alla previa verifica da parte dei Partners della disponibilità. L’assenza di disponibilità da parte del Partner, per la prima richiesta di prenotazione effettuata dallo stesso Acquirente, permetterà all’Acquirente di concordare una nuova data tra quelle future disponibili, con conseguente conferma di esito negativo o positivo da parte del Partner ed eventuale conferma di prenotazione o nuova proposta di data alternativa. In caso di esito negativo da parte del Partner, l’Acquirente potrà accettare una nuova proposta di data selezionata dal Partner appunto, oppure, una volta cancellata la prenotazione potrà scegliere di intervenire sul Suo acquisto attraverso un Cambio Esperienza/Servizio.<br />
    4.3 Adrenalinika srl offre all’Acquirente la possibilità di richiedere una Modifica della data prenotata (si intende una data confermata, quindi successiva alle 48 ore dedicate alla conferma della richiesta di prenotazione), direttamente all’interno del sito on-line nella sezione dedicata, accedendo tramite proprie credenziali alla sezione “profilo”. La suddetta richiesta di modifica verrà presa in carico solo dopo aver verificato i termini e condizioni inerenti alla Politica di Cancellazione appartenente al Partner per cui viene erogata l’Esperienza/Servizio acquistata. L’Acquirente in questo caso potrà, previo pagamento di un importo di penale univoco pari ad Euro 15,00 per avere diritto all’annullamento della data scelta e alla successiva riprogrammazione di una nuova data, contattando direttamente l’Assistenza Clienti di Adrenalinika srl.<br />
    La richiesta di annullamento da parte dell’Acquirente sarà sempre soggetta a penale di cancellazione. Qualora non venissero rispettate le politiche di cancellazione indicate dal Partner nella descrizione dell’Esperienza/Servizio, la richiesta di Modifica della data non verrà accettata, pertanto l’Acquirente non avrà diritto di rimborso dell’Esperienza/Servizio acquistato ma potrà eventualmente intervenire sulla modifica dell’Esperienza/Servizio attraverso un cambio<br />
    Esperienza/Servizio o un cambio in Gift Card.<br />
    La modifica della data, su richiesta esplicita dell’Acquirente, non prescinde in alcun modo dalla richiesta di annullamento dell’Esperienza/Servizio da parte del Partner: è possibile, sempre previo pagamento di penale di cancellazione e sempre entro e non oltre i termini di cancellazione definiti dal Partner, annullare e riprogrammare la data senza un limite definito di tempo.<br />
    Tutte le informazioni inerenti a conferma, annullamento, programmazione o riprogrammazione della data, effettuata sempre da parte dell’Acquirente e per motivazioni imprescindibili dal Partner, verranno comunicate tramite canale telefonico, social o e-mail, in modo da garantire con certezza la fruizione di tali informazioni in modalità costante e coerente nei confronti dell’Acquirente, mantenendo le adeguate tempistiche richieste.</p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">5. CONSEGNA</span>
    <p>5.1: Adrenalinika srl invia i Cofanetti regalo o Zaino/Sacca Adrenalinika, unicamente all’interno del territorio italiano tramite Corriere Espresso GLS o BRT. I Cofanetti regalo o Zaino/Sacca Adrenalinika saranno spediti all’indirizzo indicato dall’Acquirente al momento del suo Acquisto online, con elaborazione degli stessi entro le ore 11:00 am dal Lunedì al Venerdi. Tutti gli ordini di spedizione del Cofanetto Regalo o Zaino/Sacca Adrenalinika ricevuti tra Sabato e Domenica, verranno presi in carico il Lunedì successivo ed avranno decorrenza dal Martedì come giorno di spedizione effettivo. In caso di errori in merito ad inserimento di dati errati (indirizzo di consegna e di spedizione) da parte dell’Acquirente, Adrenalinika srl non potrà essere considerata responsabile. In caso di assenza della persona destinataria del Cofanetto regalo o zaino/sacca al momento della consegna da parte del Corriere, Adrenalinika srl non potrà essere considerata responsabile. L’Acquirente potrà tuttavia contattare Adrenalinika srl per una nuova spedizione a carico dell’Acquirente.<br /><br />
    5.2: Il termine massimo di consegna è di 7 giorni lavorativi a partire dalla conferma dell’ordine. L’Acquirente riceverà una e-mail di notifica dell’invio dell’ordine in cui è indicato il numero di riferimento del trasporto, al fine di tracciare il percorso di spedizione dell’ordine stesso.<br />
    A titolo informativo, a partire dal ricevimento della e-mail di conferma di spedizione, il termine medio di consegna, quale comunicato dalle società di<br />
    trasporto, è stimato come segue entro 2 e 3 giorni lavorativi sul territorio italiano. 5.3: Spese di Spedizione:<br />
    Le spese di spedizione a carico di Adrenalinika srl sono forfettarie e subiscono fatturazione in aggiunta al costo di Cofanetto Regalo e Zaino/Sacca Adrenalinika, al costo di 6,00 Euro IVA inclusa e salvo promozione specifica sulle spese di spedizione. I Voucher Elettronici o Gift Card sono consegnate per e-mail e non comportano alcuna spesa di consegna supplementare.<br />
    5.4: Deterioramento e Oggetti mancanti:<br />
    Ogni Cofanetto regalo o Zaino/Sacca Adrenalinika spedita, dovrà essere oggetto di un controllo minuzioso da parte del destinatario. In caso di Cofanetto regalo o Zaino/Sacca deteriorati o mancanti, il destinatario dovrà indicare la problematica direttamente sulla Bolla di Trasporto e successivamente confermare le riserve fatte all’atto della consegna, attraverso Lettera Raccomandata o Pec entro 72 ore lavorative a partire dal ricevimento<br />
    dell’ordine contestato. In assenza di comunicazione di uno dei termini sopra inidcati, l’ordine sarà considerato come consegnato correttamente ed in buono stato, pertanto non potrà essere non potrà essere oggetto di alcuna contestazione nei confronti di Adrenalinika srl.</p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">6. ATTIVAZIONE DELLE ESPERIENZE/SERVIZIO</span>
    <p>6.1: Le Esperienze/Servizio sono attivate al momento del pagamento e avranno le seguenti modalità di attivazione:<br />
    • Se l’Esperienza/Servizio è stata acquistata online, l’attivazione sarà immediata. Si intende con acquisto on-line, la creazione di un Voucher</p>

    <p> Elettronico a data aperta, oppure la creazione di una richiesta di<br />
    prenotazione di una data specifica.<br />
    • Le Esperienze/Servizio non attivate non possono essere utilizzate. In caso<br />
    di problemi di attivazione, la prova di acquisto dell’Esperienza/Servizio (e- mail di conferma dell’ordine o Voucher Elettronico Cartaceo con relativo Codice di identificazione dell’acquisto) dovrà essere comunicata tramite canali social, e-mail o telefonata, all’Assistenza Clienti di Adrenalinika srl.<br />
    A questo proposito, in caso di acquisto di Cofanetto Regalo o Sacca/Zaino Adrenalinika, Voucher Elettronico o Gift Card non ancora attivati, Adrenalinika srl si riserva il diritto di non dar seguito ad alcun reclamo legato al problema di attivazione stesso.<br />
    6.2: Utilizzo della GIFT CARD:<br />
    La Gift Card permette di scegliere gratuitamente un‘Esperienza/Servizio in formato PDF, da selezionarsi fra la gamma delle Esperienze/Servizio proposte sul sito on-line.<br />
    Se il Beneficiario desidera ricevere un’Esperienza/Servizio in formato fisico “Cofanetto Regalo/Gift Box o Zaino/sacca Adrenalinika o ulteriori extra”, subirà l’applicazione di spese supplementari relative al costo di ogni singolo elemento sopra indicato, indipendentemente dalla Esperienza/Servizio prescelta.<br />
    Se l’Esperienza/Servizio prescelta presenta un costo superiore alla disponibilità economica della Gift Card, il Beneficiario avrà la possibilità di utilizzare la Gift<br />
    Card e finalizzare l’ordine dopo la scelta dell’Esperienza/Servizio, pagando la differenza indicata con altro metodo di pagamento tra le opzioni presenti sul sito on-line.<br />
    Il prodotto Gift Card Adrenalinika :<br />
    • Può essere utilizzato per l’acquisto di una o più Esperienze/Servizi proposti sul nostro sito on-line, fino al raggiungimento dell’importo;<br />
    • Non può essere utilizzato congiuntamente a un’altra Gift Card Adrenalinika;<br />
    • Non ha scadenza periodica;<br />
    <br />
    6.3: Garanzia Perdita e Furto:<br />
    In caso di perdita o di furto del Voucher Elettronico, Gift Card o Cofanetto Regalo/Gift Box e Zaino/Sacca Adrenalinika e, a condizione che questi ultimi non siano stati nel frattempo utilizzati, il Beneficiario potrà contattare il Servizio Clienti di Adrenalinika srl, al fine di richiedere la sostituzione del Voucher/Gift Card perduto o rubato, per un importo pari al prezzo di acquisto del Voucher/Gift Card perduto o rubato, senza il pagamento di commissioni.<br />
    6.4: Adrenalinika srl non applica sostituzioni in caso di perdita o furto di Cofanetto Regalo e Zaino/Sacca Adrenalinika, vista la gestione diretta delle spedizioni da parte del Corriere Express.<br />
    Attenzione: se il Voucher/Gift Card perduto o rubato è stato utilizzato prima della richiesta di sostituzione, nessun cambio sarà possibile.<br />
    Il Beneficiario è tenuto a prendere tutte le misure utili per conservare accuratamente il Codice di acquisto, al fine di evitare utilizzi fraudolenti.</p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">7. PARTNER</span>
    <p>7.1: Il Voucher dà diritto a Prestazioni diverse da un Partner all’altro; la prenotazione può essere effettuata direttamente presso il portale web adrenalinika.com nell’apposita sezione Area riscatto o preventivamente in fase di scelta e acquisto esperienza/servizio.<br />
    Il contenuto delle Prestazioni all’Esperienza/Servizio erogati, possono essere soggetti a modifiche. Adrenalinika si impegna a proporre al Beneficiario soluzioni alternative.<br />
    Il contenuto fotografico ed editoriale presentato nelle Esperienze/Servizio sul sito on-line è da considerarsi indicativo e può essere soggetto a modifiche, anche successivamente a quanto definito in fase contrattuale.<br />
    In caso di eventuali anomalie rispetto allo stato delle strutture dei Partner, Adrenalinika srl non si addebiterà alcun tipo di responsabilità.<br />
    Adrenalinika srl vieta ogni riproduzione parziale o totale del contenuto delle Esperienze/Servizio, ricordando al Partner di avere responsabilità in merito ad una corretta esecuzione della Prestazione delle proprie Esperienze/Servizio. All’occorrenza, Adrenalinika srl si adopererà al meglio per tentare di trovare una soluzione amichevole alle dispute tra il Beneficiario e il Partner.<br />
    Si precisa altresì che Adrenalinika srl non potrà essere ritenuta responsabile per eventuali perdite/avarie/furti che il Beneficiario subisca nel corso dell’Esperienza. Il Beneficiario è invitato a rivolgersi al Partner in caso di</p>

    <p> eventuale insoddisfazione relativamente alla Prestazione.<br />
    7.2: Nonostante quanto precede, in caso di difficoltà legate all’esecuzione della Prestazione da parte del Partner, il beneficiario avrà la possibilità di rivolgersi indifferentemente ad Adrenalinika srl, che sarà responsabile nei limiti e alle condizioni stabilite dalla legge applicabile in caso di inadempimento contrattuale nell’esecuzione di dette Prestazioni.<br />
    7.3: Disponibilità e Fornitura delle Offerte Partner:<br />
    Dato che Adrenalinika srl non può garantire la disponibilità continua delle offerte del Partner scelto dal Beneficiario, si consiglia vivamente al Beneficiario di recarsi al più presto sul Sito e di consultare la lista dei Partner al fine di effettuare una scelta e di prenotare appena possibile la Prestazione.<br />
    Salvo indicazione contraria, il Voucher è valido tutti i giorni della settimana, compresi i week-end, secondo le effettive disponibilità, i giorni e gli orari di apertura fornite direttamente dal Partner.<br />
    La fornitura della Prestazione scelta sarà possibile solo su prenotazione e successivamente confermata previo controllo da parte del Partner in merito alla validità del numero d’ordine del Voucher Elettronico.<br />
    7.4: Assicurazione dei Partner:<br />
    I Partner selezionati da Adrenalinika srl hanno dichiarato a quest’ultima di essere titolari di assicurazioni di responsabilità civile professionale per importi<br />
    sufficienti per la fornitura delle Prestazioni, di possedere tutte le autorizzazioni e i titoli che permettono loro di esercitare la propria attività in modo regolare ed in ogni caso di rispettare le disposizioni legislative e regolamentari ad essi applicabili. Tuttavia, il Beneficiario è invitato a dotarsi di una copertura assicurativa sufficiente, in particolare per la pratica di alcune attività sportive dette “a rischio”. Si ricorda al Beneficiario che la fornitura delle Prestazioni in buone condizioni di sicurezza non lo dispensa dall’osservare le regole di prudenza elementari per le attività sportive, in particolare quelle dette “a rischio”. Il Beneficiario ne accetta i rischi conseguenti.<br />
    </p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">8. ESPERIENZA/SERVIZIO</span>
    <p>8.1: Le Esperienze/Servizi proposti sul sito on-line possono avere validità solitamente 12 mesi dall’acquisto. Adrenalinika srl si riserva il diritto di integrare e/o di modificare o cessare la lista delle Esperienze/Servizio o la loro diffusione, a sua sola discrezione ed in qualsiasi momento. In caso di interruzione di commercializzazione di un’Esperienza/Servizio prima della data finale di validità, Adrenalinika srl proporrà al Beneficiario una modalità di Intervento Alternativa<br />
    sul Voucher Elettronico, con eccezione per le Gift Card. 8.2: Modalità di scambio delle Esperienze<br />
    In ogni caso, il cambio è riservato esclusivamente alle Esperienze/Servizio di cui non sia stato utilizzato Il voucher o numero d’ordine. Il Cambio viene effettuato solo successivamente all’acquisto di un’ Esperienza/Servizio a data aperta, pertanto non sarà possibile qualora sia già stata prenotata una data all’interno del nostro sito on-line.<br />
    Fatta eccezione per l’esercizio del diritto di recesso riconosciuto per legge in caso di Acquisti online, la Gift Card non è mai rimborsabile. I cambi si effettuano sempre in funzione del valore di acquisto dell’Esperienza da cambiare, ovvero in funzione del prezzo effettivamente pagato dall’Acquirente e in funzione della data di acquisto anche laddove il costo sia comprensivo di sconti o promozioni fino a quella data applicate. Lo scambio consente di beneficiare di una nuova Esperienza/Servizio, il cui periodo di validità sarà pari alla durata della validità residua dell’Esperienza/Servizio scambiata. Adrenalinika srl si riserva il diritto di<br />
    accettare un prolungamento della validità dell’Esperienza/Servizio, previo pagamento di un importo di penale corrispondente al valore dell’importo originario di acquisto dell’Esperienza/Servizio.<br />
    8.3: È possibile cambiare più di una Esperienza/Servizio con una nuova Esperienza/Servizio, versando l’eventuale differenza di prezzo che rimane a carico del Beneficiario, direttamente ad Adrenalinika srl, tramite pagamento con Bonifico Bancario.<br />
    Qualora l’Acquirente scegliesse di effettuare il cambio dell’ Esperienza/Servizio acquistata con un’Esperienza/Servizio di importo inferiore a quello di acquisto originario, Adrenalinika non si assume alcuna responsabilità in merito al rimborso o all’ integrazione dell’importo di differenza, che andrà sistematicamente perduto.</p>

    <p> Il cambio Esperienza è gratuito se richiesto entro i 6 mesi dalla data di scadenza del Voucher originario, mentre in caso contrario è soggetto alle seguenti penali: – Importo del voucher da €0,00 a €150,00 = penale del 20% dell’importo del voucher + IVA<br />
    – Importo del voucher da €151,00 a €350,00 = penale del 15% dell’importo del voucher + IVA<br />
    – Importo del voucher da €351,00 in su = penale del 10% dell’importo del<br />
    voucher + IVA<br />
    Tali costi di cambio sono soggetti all’IVA italiana all’aliquota applicabile il giorno del cambio.<br />
    Adrenalinika srl si riserva di effettuare il Cambio di un’Esperienza/Servizio in modalità gratuita solo se la richiesta avviene entro i 6 mesi da avvenuto acquisto da parte dell’Acquirente e solo previa consultazione con il Partner in merito ad eventuale indisposizione nell’attuazione dell’esperienza, dovuta a cause totalmente indipendenti dall’Acquirente.<br />
    Si ribadisce che il cambio di un’Esperienza/Servizio può essere effettuato solo entro e non oltre la data di scadenza del Voucher Elettronico dell’Esperienza/Servizio scelta, pertanto Adrenalinika srl non si assume alcuna responsabilità in merito a richieste effettuate dopo la scadenza prevista: si definisce in questo caso il sistematico annullamento del Voucher Elettronico con conseguente impossibilità di riprogrammare l’Esperienza/Servizio.<br />
    8.4: Modalità di Prolungamento Voucher Elettronico:<br />
    Il rinnovo delle Esperienze/Servizi può essere soggetto al pagamento di un prezzo aggiuntivo. Il voucher verrà prorogato per ulteriori 6 mesi rispetto alla originaria data di scadenza.<br />
    Il rinnovo è gratuito se richiesto entro i 6 mesi dalla data di scadenza del Voucher originario, mentre in caso contrario è soggetto alle seguenti penali: – Importo del voucher da €0,00 a €150,00 = penale del 20% dell’importo del voucher + IVA<br />
    – Importo del voucher da €151,00 a €350,00 = penale del 15% dell’importo del voucher + IVA<br />
    – Importo del voucher da €351,00 in su = penale del 10% dell’importo del voucher + IVA</p>

    <p> Tali costi di cambio sono soggetti all’IVA italiana all’aliquota applicabile il giorno del cambio.<br />
    Adrenalinika srl si riserva di effettuare il Prolungamento di un’Esperienza/Servizio in modalità gratuita solo se la richiesta avviene entro i 6 mesi da avvenuto acquisto da parte dell’Acquirente e solo previa consultazione con il Partner in merito ad eventuale indisposizione nell’attuazione dell’esperienza, dovuta a<br />
    cause totalmente indipendenti dall’Acquirente.<br />
    Si ribadisce che il prolungamento di un’Esperienza/Servizio può essere effettuato solo entro e non oltre la data di scadenza del Voucher Elettronico dell’Esperienza/Servizio scelta, pertanto Adrenalinika srl non si assume alcuna responsabilità in merito a richieste effettuate dopo la scadenza prevista: si definisce in questo caso il sistematico annullamento del Voucher Elettronico con conseguente impossibilità di riprogrammare l’Esperienza/Servizio.<br />
    8.5: Modalità di attivazione GIFT CARD Regalo/VOUCHER Esperienza Regalo<br />
    Successivamente all’acquisto da parte di un Utente e/o Acquirente di una Gift Card Regalo, con conseguente acquisto legato alla consegna di Sacca Adrenalinika/Zaino o Gift Box Regalo, sarà possibile gestire la prenotazione seguendo le indicazioni riportate all’interno della Gift Box consegnata/acquistata o all’interno dello Zaino/Sacca Adrenalinika consegnata/acquistata.<br />
    La generazione dell’acquisto da parte dell’Utente o Acquirente sul sito, prevede sistematicamente la creazione di una e-mail di notifica in cui è indicato il dettagli dell’acquisto e relativo Codice Voucher o Codice di Prenotazione di una determinata Esperienza/Servizio: il medesimo Codice è riportato nella descrizione di utilizzo all’interno dello Zaino/sacca o Gift Box ricevuti.</p>

    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10"> 9. CANCELLAZIONE ESPERIENZA/SERVIZIO</span>
    <p>9.1: Adrenalinika srl è un intermediario tra i clienti e i gestori di attività (i nostri Partner). Successivamente ad una prenotazione tramite Adrenalinika srl, viene generato e completato un pagamento direttamente alla stessa e non alla struttura che garantirà l’erogazione dell’Esperienza/Servizio scelta.<br />
    Adrenalinika srl di ogni prenotazione generata e di ogni acquisto completato: si indichi con acquisto completato un pagamento concluso con ricevuta di<br />
    conferma per la richiesta di prenotazione, inviata all’acquirente tramite e-mail.<br />
    9.2: Considerando che l’acquisto di un Voucher elettronico a data aperta e l’acquisto di un Esperienza/Servizio con scelta di data a calendario costituiscono una prenotazione completata a tutti gli effetti, sono previsti dei termini e condizioni di cancellazione, variabili in base a circostanze d’acquisto come segue:<br />
    • Se l’Acquirente acquista un Esperienza/Servizio con una richiesta di prenotazione per una data scelta a calendario, dovrà attendere la conferma da parte della struttura entro 48 ore da avvenuta richiesta: qualora l’esito sia negativo, l’Acquirente potrà scegliere di riprogrammare una data sempre a calendario, direttamente sul nostro sito on-line in base alle disponibilità indicate, oppure potrà richiedere la cancellazione della richiesta di prenotazione.<br />
    La cancellazione in questo caso sarà possibile, tramite l’erogazione del Rimborso dell’Esperienza/Servizio acquistata, solo entro 14 giorni da avvenuto acquisto; in caso contrario e quindi superati i 14 giorni da<br />
    avvenuto acquisto, Adrenalinika srl provvederà a fornire soluzioni alternative per intervenire sull’Esperienza/Servizio, attraverso modalità di Cambio Esperienza/Servizio o Cambio dell’Esperienza/Servizio in una Gift Card.<br />
    La cancellazione dell’Esperienza/Servizio è possibile solo ed esclusivamente qualora la richiesta di prenotazione non sia accettata, in merito a circostante indipendenti in tutto e per tutto dall’Acquirente, ma dipendenti quindi solo dal Partner o dalle condizioni legate all’impossibilità di svolgere la suddetta Esperienza/Servizio.<br />
    Qualora la richiesta di cancellazione dell’Esperienza/Servizio avvenga, da parte dell’Acquirente e quindi per motivi indipendenti dal Partner ma dipendenti solo ed esclusivamente dall’Acquirente, prima della data scelta e confermata dell’Esperienza/Servizio che andrà a svolgere,</p>

    <p> l’Acquirente potrà riprogrammare un’altra data di Sua preferenza a calendario, tra quelle disponibili, solo ed esclusivamente se la richiesta di cancellazione viene effettuata entro le tempistiche indicate dal Partner nella descrizione dell’Esperienza/Servizio presente sul nostro sito, nella sezione Regole di Cancellazione di ogni pacchetto, in relazione all’Esperienza/Servizio acquistata.<br />
    Qualora la richiesta di cancellazione dell’Esperienza/Servizio venga effettuata entro la data stessa di prenotazione, ma oltre i termini di<br />
    cancellazione indicati dal Partner, l’Acquirente potrà riprogrammare una nuova data di Sua preferenza a calendario, tra quelle disponibili, in modalità gratuita solo ed esclusivamente per una sola richiesta di cancellazione. Si intende pertanto che a partire dalla seconda richiesta cancellazione da parte dell’Acquirente, sarà possibile riprogrammare una nuova data di preferenza a calendario, tra quelle disponibili, previo pagamento di Penale di Cancellazione pari ad Euro 15,00.<br />
    Si noti che i Termini e Condizioni relativi alle regole di Cancellazione per ogni singola Esperienza/Servizio possono variare da un Partner all’altro e da un’Esperienza/servizio ad un'altra Esperienza/Servizio, quindi possono essere anche inesistenti e quindi con impossibilità di cancellazione dell’Esperienza/Servizio scelta.<br />
    Si intende che tutte le richieste di cancellazione effettuate oltre i termini richiesti, non saranno accettate né prese in carico, oppure rifiutate con conseguente obbligo da parte dell’Acquirente di perdita dell’Esperienza/Servizio o Cambio Esperienza/Servizio e/o Trasformazione in Gift Card. Il Cambio Esperienza/Servizio e/o Trasformazione in Gift Card prevedono il pagamento di penale come sopra.<br />
    • Se l’Acquirente acquista un Voucher Elettronico a data aperta, riceverà un Codice di prenotazione tramite e-mail, con il quale poter scegliere una data a calendario tra quelle disponibili, fornite dal Partner direttamente sul nostro sito on-line: a quel punto l’Acquirente effettuerà una richiesta di prenotazione per la data scelta e attenderò la conferma da parte della struttura entro 48 ore da avvenuta richiesta: qualora l’esito sia negativo, l’Acquirente potrà scegliere di riprogrammare una data sempre a calendario, direttamente sul nostro sito on-line in base alle disponibilità indicate, oppure potrà richiedere la cancellazione della richiesta di prenotazione.<br />
    La cancellazione in questo caso sarà possibile, tramite l’erogazione del</p>

    <p> Rimborso dell’Esperienza/Servizio acquistata, solo entro 14 giorni da avvenuto acquisto; in caso contrario e quindi superati i 14 giorni da avvenuto acquisto, Adrenalinika srl provvederà a fornire soluzioni alternative per intervenire sull’Esperienza/Servizio, attraverso modalità di Cambio Esperienza/Servizio o Cambio dell’Esperienza/Servizio in una Gift Card.<br />
    La cancellazione dell’Esperienza/Servizio è possibile solo ed esclusivamente qualora la richiesta di prenotazione non sia accettata, in<br />
    merito a circostante indipendenti in tutto e per tutto dall’Acquirente, ma dipendenti quindi solo dal Partner o dalle condizioni legate all’impossibilità di svolgere la suddetta Esperienza/Servizio.<br />
    Qualora la richiesta di cancellazione dell’Esperienza/Servizio avvenga, da parte dell’Acquirente e quindi per motivi indipendenti dal Partner ma dipendenti solo ed esclusivamente dall’Acquirente, prima della data scelta e confermata dell’Esperienza/Servizio che andrà a svolgere, l’Acquirente potrà riprogrammare un’altra data di Sua preferenza a calendario, tra quelle disponibili, solo ed esclusivamente se la richiesta di cancellazione viene effettuata entro le tempistiche indicate dal Partner nella descrizione dell’Esperienza/Servizio presente sul nostro sito, nella sezione Regole di Cancellazione di ogni pacchetto, in relazione all’Esperienza/Servizio acquistata.<br />
    Qualora la richiesta di cancellazione dell’Esperienza/Servizio venga effettuata entro la data stessa di prenotazione, ma oltre i termini di cancellazione indicati dal Partner, l’Acquirente potrà riprogrammare una nuova data di Sua preferenza a calendario, tra quelle disponibili, in modalità gratuita solo ed esclusivamente per una sola richiesta di<br />
    cancellazione. Si intende pertanto che a partire dalla seconda richiesta cancellazione da parte dell’Acquirente, sarà possibile riprogrammare una nuova data di preferenza a calendario, tra quelle disponibili, previo pagamento di Penale di Cancellazione pari ad Euro 15,00.<br />
    Si noti che i Termini e Condizioni relativi alle regole di Cancellazione per ogni singola Esperienza/Servizio possono variare da un Partner all’altro e da un’Esperienza/servizio ad un'altra Esperienza/Servizio, quindi possono essere anche inesistenti e quindi con impossibilità di cancellazione dell’Esperienza/Servizio scelta.<br />
    Si intende che tutte le richieste di cancellazione effettuate oltre i termini richiesti, non saranno accettate né prese in carico, oppure rifiutate con conseguente obbligo da parte dell’Acquirente di perdita dell’Esperienza/Servizio o Cambio Esperienza/Servizio e/o Trasformazione</p>

    <p> in Gift Card. Il Cambio Esperienza/Servizio e/o Trasformazione in Gift Card prevedono il pagamento di penale come sopra.<br />
    9.3: Cancellazione da parte dei Partner<br />
    In caso si verifichino circostanze che rendono l'attività non sicura o impraticabile, come ad esempio condizioni meteo particolarmente severe,<br />
    oppure problematiche legate alla logistica dei mezzi a disposizione, oppure impossibilità di svolgere l’Esperienza/Servizio al massimo della Sicurezza per l’Acquirente, il Partner può decidere di cancellare l'attività. Non essendo sempre possibile prevedere questi eventi in anticipo, la cancellazione potrebbe avvenire in qualsiasi momento.<br />
    In questo caso sarà Adrenalinika srl che, in quanto intermediario tra le parti, provvederà a fornire all’Acquirente le seguenti soluzioni alternative:<br />
    • Annullamento Esperienza/Servizio acquistata, al fine di generare un Voucher elettronico o Giftcard, di importo pari a quello del primo acquisto;<br /></p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">10. RESPONSABILITÀ, ACCESSO E OPERAZIONI AL SITO ED AL SERVIZIO</span>
    <p>10.1: Gli Acquirenti riconoscono e sono consapevoli del fatto che, al non corretto funzionamento della rete internet e dei sistemi di telecomunicazioni può conseguire l’impossibilità di garantire:<br />
    • La corretta operatività del Sito e del Servizio, in particolare in termini di interrotta accessibilità al Sito, alle performance del Sito e del Servizio come, ad esempio, il tempo di risposta per le varie richieste e azioni effettuate;</p>

    <p> • La sicurezza del Sito e del Servizio, in particolare rispetto alla presenza di virus informatici.<br />
    10.2: Gli Acquirenti sono tenuti ad essere consapevoli di quanto sopra, indipendentemente dalle risorse impiegate dalla Società, Internet e i sistemi di telecomunicazione, che presentano specifiche tecniche esterne rispetto alla sfera di dominio della Società.<br />
    Gli Acquirenti sono inoltre tenuti ad essere consapevoli che il Sito ed il Servizio<br />
    potrebbero essere temporaneamente sospesi, in particolare in caso di guasti al sistema, manutenzione, riparazione o aggiornamento, pertanto Adrenalinika srl non può essere considerata responsabile per alcun danno provocato agli Acquirenti a seguito dell’utilizzo del Sito o del Servizio e, in particolare, alcun danno risultante dalla non disponibilità del Sito.<br />
    10.3: Perimetro di Responsabilità della Società<br />
    Adrenalinika srl fornisce un servizio tecnico diretto a mettere in comunicazione Utenti e Partners.<br />
    I Partners sono i soli responsabili nei confronti dei Clienti e dei Partecipanti per il corretto svolgimento dell’ Esperienza/Servizio offerti sul Sito, pertanto Adrenalinika srl in quanto soggetto terzo, non contribuirà in alcun modo a regolarne forme, modalità e termini di esercizio e pertanto non può essere ritenuta in alcun modo responsabile rispetto all’inottemperanza, totale o parziale, dell’obbligazione, e/o scarsa performance, resa da un Partner con riferimento allo svolgimento di un’Esperienza/Servizio, né della correttezza delle informazioni fornite dal Partner relativamente alle caratteristiche delle Attività e<br />
    alle condizioni di accesso.<br />
    Si esclude in eguale modo, la responsabilità da parte di Adrenalinika srl rispetto alle azioni commesse dai Partecipanti durante lo svolgimento dell’Esperienza/Servizio.<br />
    In relazione a situazioni di inottemperanza, totale o parziale, dell’obbligazione, e/o scarsa performance, resa da un Partner, l’Acquirente si impegna a non intraprendere azioni legali nei confronti di Adrenalinika srl, riconoscendone la qualità di soggetto terzo e, quindi, come tale estraneo rispetto al rapporto in contesa.<br />
    Gli Acquirenti sono personalmente responsabili per l’accettazione dei Termini e Condizioni Clienti da parte dei Partecipanti per i quali l’Acquirente ha effettuato la prenotazione dell’Attività.</p>

    <p> 10.4: Limitazioni di Responsabilità<br />
    Adrenalinika srl assume la responsabilità limitatamente ai casi di violazione dimostrata da un Acquirente, delle sue obbligazioni contrattuali nell’esecuzione dell’Esperienza/Servizio.<br />
    La Società non può essere considerata responsabile nei seguenti casi:<br />
    • Atti compiuti da un Acquirente, Partecipante, o Partner;<br />
    • In casi di forza maggiore;<br />
    • In caso di atti compiuti da terze parti ed in particolare malfunzionamenti,<br />
    l’impossibilità ad accedere o difficoltà ad utilizzare Internet;<br />
    • In caso di danni consequenziali o non consequenziali riguardanti<br />
    Esperienza/Servizio diversa dall’Esperienza/Servizio fornito sul sito on-line<br />
    Adrenalinika srl, in ottemperanza con le presenti disposizioni;<br />
    • In caso di danni riconducibili ai contenuti, natura, o caratteristiche<br />
    dell’Esperienza/Servizio forniti da un Partner ad un Acquirente.</p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">11. SERVIZIO CLIENTI E RECLAMI</span>
    <p>11.1: In merito ad acquisto, rimborso, cambio o funzionamento di Esperienza/Servizio o Voucher Elettronico sul sito on-line Adrenalinika srl, l’Acquirente può effettuare una richiesta tramite il sito www.adrenalinika.com consultando la sezione “Contattaci” oppure scrivendo ad<br />
    amministrazione@adrenalinika.com<br />
    11.2: In caso di problematiche con un Partner, Adrenalinika srl si impegna a contattarlo con l’obiettivo di risolvere il problema per conto del Cliente, seppur non impegnandosi in modo vincolante e non garantendo la buona riuscita della negoziazione con certezza assoluta.<br />
    </p>
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">12.IMPEGNI E OBBLIGHI DEL CLIENTE</span>
    <p>12.1: Gli Utenti sono tenuti ad utilizzare il Sito ed il Servizio rispettando i diritti di terze parti e nel rispetto dei diritti dei Partners e della Società, pertanto devono, quando connessi al Sito e quando utilizzano il Servizio, attenersi alle regolamentazioni applicabili.<br />
    12.2: In particolare, gli Utenti si impegnano a:<br />
    • Non utilizzare il Sito o il Servizio per finalità estranee rispetto allo stesso, ovvero tramite sistemi atti a contravvenire alle regolamentazioni applicabili;<br />
    • Non diffamare, insultare o denigrare persone fisiche o giuridiche;<br />
    • Non creare Account Utenti con identità fittizie, o falsificare la propria;<br />
    • Non causare danni a terzi utilizzando il Sito o il Servizio e, in particolare,<br />
    non rubare l’identità di terze parti o, in generale, utilizzare dati di proprietà<br />
    di terzi;<br />
    • Non utilizzare le informazioni contrattuali ottenute tramite il Sito o il<br />
    Servizio per inviare comunicazioni indesiderate, a prescindere dalla forma<br />
    utilizzata per tali comunicazioni;<br />
    • Non utilizzare le informazioni ottenute attraverso l’utilizzo del Sito o del<br />
    Servizio per disseminare virus informatici o minacce simili;<br />
    • Non causare danno alla reputazione dei Partners o della Società, o al<br />
    brand Adrenalinika srl;<br />
    • Non causare l’interruzione o l’arresto del Sito;<br />
    • Non causare danni, o tentare di causare danni, relativi ai servizi forniti da<br />
    uno o più fornitori o Partners, in particolare alla società che fornisce il<br />
    servizio di hosting, ad esempio esponendo il Sito a virus informatici, creando saturazione o l’inondazione del server, saturando i sistemi di messaggistica con email, e altre condotte ad esse equiparabili;<br />
    • Non accedere, o tentare di accedere, a informazioni non espressamente messe a disposizioni degli Utenti;<br />
    • Non sondare, scannerizzare, o testare la vulnerabilità del sistema, o violare il Sito, i sistemi di sicurezza del Servizio o i processi di autenticazione, o tentare di accedere in modo illecito ai network e sistemi connessi al Sito;<br />
    • Non caricare sul Sito, mandare per email o per ogni altro mezzo, alcun elemento contenente virus informatici o altri codici, file, o programmi per computer progettati per interrompere, distruggere o limitare la funzionalità di ogni computer, software, hardware o attrezzatura di telecomunicazione.</p>

    <p> 12.3: Responsabilità e Obbligazioni relative alla prenotazione<br />
    Rispetto alla prenotazione e svolgimento delle Attività, l’Acquirente prende atto che:<br />
    • I Partecipanti potranno svolgere l’Attività solo qualora siano in possesso delle condizioni richieste per l’esercizio della stessa (ad esempio certificati comprovanti il possesso di una corretta condizione fisica, di un determinato livello di abilità, nonché ulteriori certificati, patentini o licenze,<br />
    richiesti per legge); in caso contrario il Partner ha diritto di rifiutare la partecipazione dei Partecipanti all’Attività senza che ne derivi un diritto al rimborso del prezzo pagato al Cliente;<br />
    • I Partecipanti dovranno, con particolare riferimento all’Attività, attenersi al rispetto delle regole ed istruzioni dettate al fine di permettere lo svolgimento dell’Attività in condizioni di sicurezza ottimali; in caso contrario il Partner ha diritto di interrompere lo svolgimento dell’Attività senza che ne derivi un diritto al rimborso del prezzo pagato al Cliente;<br />
    • I Partecipanti dovranno arrivare in orario al punto di ritrovo indicato nel riepilogo dell’Attività. Un ritardo superiore ai 15 minuti rispetto all’ora concordata per l’esecuzione dell’Attività può determinare la cancellazione della prenotazione, in conformità a quanto stabilito nei termini e condizioni generali dell’Attività del Partner, ed indicato nel riepilogo della prenotazione.<br />
    12.4: Gli Acquirenti sono tenuti ad assicurare l’accuratezza delle informazioni fornite al Partner in sede di prenotazione dell’Attività; la falsità delle informazioni rese dagli Acquirenti, qualora disposte al fine di aggirare i limiti imposti dal<br />
    Partner per l’esercizio dell’Attività, legittimano il Partner a rifiutare l’esecuzione della propria prestazione, con ulteriore esclusione di alcuna forma di rimborso. È inoltre specificato che ogni attività che non rientra espressamente nell’Attività prenotata non è sotto la responsabilità della Società o del Partner.<br />
    Adrenalinika srl non si assume alcuna responsabilità in merito alla documentazione riguardante lo stato di salute, il possesso di brevetti, certificati, o ogni altro documento prodotto dall’Acquirente in sede di prenotazione. La veridicità delle dichiarazioni rese è posta ad esclusivo carico dell’Acquirente e/o del Partecipante, fermo il diritto dei Partners di richiederne l’esibizione, al momento dell’esecuzione dell’Attività oggetto di prenotazione. Di conseguenza, è espressamente specificato che Acquirenti e Partecipanti sono i soli responsabili per la prenotazione e partecipazione alle Attività, tenendo in considerazione il proprio livello di abilità, condizione fisica, esperienza, limiti, e</p>

    <p> tutti i rischi derivanti dal prendere parte in attività, sportive e non, potenzialmente pericolose. A questo fine, né i Partners né la Società possono essere considerati responsabili per infortuni, incidenti, o morte causati dallo svolgimento dell’Attività, qualora uno di questi eventi consegua alle false informazioni rese dall’Acquirente o dal Partecipante.<br />
    12.5: ASSICURAZIONE<br />
    Gli Acquirenti sono informati circa la possibilità di stipulare, per sé stessi e per<br />
    tutti i Partecipanti, polizze assicurative per le responsabilità civili in Italia ed all’estero mentre svolgono le Attività. È inoltre specificato che le Attività offerte tramite Adrenalinika srl, se non espressamente specificato, non includono alcun tipo di assicurazione.<br />
    È responsabilità di ogni Partecipante assicurarsi che la propria copertura assicurativa sia sufficiente e, nel caso in cui risulti diversamente necessario, attuare tutte le misure idonee a renderla tale, integrando la polizza già in essere, ovvero stipulando un accordo con una compagnia assicurativa di loro scelta. Sul Sito, la Società potrebbe elencare attività “rischiose”. Si ricorda agli Acquirenti che assicurazioni supplementari sono fortemente raccomandate per questo tipo di attività.</p >
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">13. PROPRIETA’</span>
    <p>13.1: Diritti del Sito e del Servizio<br />
    Il Sito, il Servizio e le informazioni in essi contenute sono protetti dalla legge sulla proprietà intellettuale.<br />
    A ciò consegue come, ad eccezione dei casi in cui ciò risulti diversamente espresso, i diritti di proprietà intellettuale concernenti i documenti, nonché tutte le informazioni, di ogni tipo, contenute nel Sito o relative al Servizio, in particolare con riferimento a ogni elemento che compone il Sito ed il Servizio (immagini, animate o meno; illustrazioni; fotografie; suoni; know-how; architetture informatiche; testi; elementi grafici; etc.) ivi inclusi i software e i database, sono da considerarsi di proprietà esclusiva della Società e dei suoi Partners (di seguito, gli “Elementi Protetti”).</p>

    <p> 13.2: La Società non concede agli Utenti alcuna licenza rispetto all’utilizzo degli Elementi Protetti, tutti ovvero anche solo una parte, se non quello relativo alla loro visualizzazione nel Sito ed utilizzo ai fini del Servizio.<br />
    La riproduzione, in tutto o in parte, del Sito e/o degli Elementi Protetti può essere autorizzata al solo scopo informativo e/o divulgativo, per motivi personali e privati. L’utilizzo in questi termini del Sito, ovvero de suoi Elementi Protetti, è possibile solo a seguito di approvazione espressa resa dalla Società in seguito a comunicazione da rivolgersi a info@adrenalinika.com<br />
    Diversamente, ogni riproduzione, utilizzo di copie, prescindendo dalla forma e dalla modalità di impiego, è da considerarsi espressamente vietata se non autorizzata dalla Società. Tali comportamenti, in relazione al pregiudizio arrecato alla Società potranno costituire elemento di valutazione ai fini della responsabilità civile e, se ne sussistono gli elementi, di quella penale.<br />
    13.3: E’ inoltre proibito:<br />
    • Copiare, modificare, assemblare, vendere, o trasferire in ogni modo parte degli Elementi Protetti;<br />
    • Modificare, anche solo in parte, gli Elementi Protetti per ottenere l’accesso non autorizzato al Servizio o al Sito con ogni mezzo che non sia l’interfaccia utente fornita dalla Società a questo scopo;<br />
    • Accedere o utilizzare gli Elementi Protetti con scopi, principi, o termini di utilizzo non espressamente permessi dalla Società;<br />
    Ogni riproduzione e/o rappresentazione, in tutto o in parte, del Sito, del Servizio, e degli Elementi Protetti, senza la preventiva ed espressa autorizzazione, da riceversi per iscritto, della Società.<br />
    13.4: Diritti riguardanti segni distintivi<br />
    • Gli Utenti cedono alla Società tutti i diritti di proprietà intellettuale riconducibili ai Contenuti da essi generati sul Sito.<br />
    I diritti ceduti a tal riguardo includono, in particolare: la riproduzione, la rappresentazione, la trasmissione, la traduzione, la distribuzione, l’utilizzo e, dove opportuno, la modificazione dei contenuti oggetto di cessione. Tra i diritti ceduti possono essere ricompresi anche quelli afferenti alla traduzione dei media, qualunque ne sia il tipo. La cessione di questi contenuti è da considerarsi a titolo gratuito, quale contributo di rilievo sociale allo sviluppo del Sito, diretto ad assicurare una maggiore efficienza del servizio nonché una migliore trasparenza informativa per gli altri Utenti, e così autorizzandosene sin da subito la pubblicazione, online e non, attraverso ogni mezzo o processo tecnico.</p>

    <p> • Gli Utenti garantiscono di possedere i diritti di proprietà relativi ai Contenuti oggetto di pubblicazione. Il possesso dei diritti di proprietà relativi ai Contenuti costituisce presupposto sufficiente ad autorizzarne la loro pubblicazione. Gli Utenti sono i soli che possono essere ritenuti responsabili per l’ottemperanza di tali Contenuti alle norme di legge. A tal riguardo, grava sugli Utenti l’onere di assicurarsi che i Contenuti pubblicati non infrangono alcun diritto di terze parti, in particolare quelli afferenti ai diritti di proprietà intellettuale.<br />
    • Gli Utenti riconoscono la terzietà della Società rispetto alle pubblicazioni dagli stessi fatte all’interno del Sito. In ogni caso, laddove i contenuti dovessero violare i diritti di proprietà intellettuale di terze parti, la Società, a seguito di segnalazione proveniente dai terzi ovvero dall’Autorità Giudiziaria, provvederà senza ritardo alla loro rimozione.<br />
    14. MODIFICHE<br />
    14.1: Modifiche al Sito e/o Servizio<br />
    Gli Utenti riconoscono ed accettano la possibilità per la Società di aggiornare, o modificare, liberamente il proprio Sito e/o il Servizio, in ogni momento e a sua sola discrezione.<br />
    Ogni modifica apportata al Sito e/o al Servizio è soggetta al solo arbitrio della Società.<br />
    15. MODIFICHE AI TERMINI E CONDIZIONI ACQUIRENTI<br />
    15.1: La Società si riserva il diritto di modificare, in termini di integrazione, rimozione o adattamento, i presenti Termini e Condizioni, qualora ciò sia giustificato al fine di adempiere a sopravvenute esigenze legislative, recepire cambiamenti organizzativi diretti a migliorare il servizio o prevenire il ripetersi di condotte scorrette degli Utenti quando queste non siano state già disciplinate dai presenti Termini e Condizioni o rispetto alle quali la disciplina già prevista si sia rivelata inefficace per la prevenzione.<br />
    In ogni caso, l’utilizzo del Servizio e delle sue funzioni presuppone l’accettazione dei presenti TCC, nonché delle eventuali modifiche agli stessi apportate.<br />
    <br />
    <span className="text-neutral-6000 text-xl dark:text-neutral-300 mt-10">16. PROTEZIONE DEI DATI PERSONALI</span>
    16.1: Con riferimento al rispetto dei diritti e delle obbligazioni concernenti l’utilizzo e la protezione dei dati personali, gli Utenti sono invitati a consultare le Privacy Policy accessibile online sul Sito.<br />
    Le Privacy Policy rappresentano parte integrante dei presenti Termini e Condizioni Clienti.<br />
    Per informarsi sulle caratteristiche della policy in materia di protezione dei dati personali attuata da Adrenalinika srl, l’Utente, l’Acquirente e il Beneficiario sono espressamente invitati a prendere conoscenza della Dichiarazione sulla riservatezza consultabile sul Sito.<br />
    16.2: Cookie<br />
    Per saperne di più sull’utilizzo dei Cookie, l’Utente, l’Acquirente e il Beneficiario sono espressamente invitati a prendere conoscenza dell’Informativa di Adrenalinika srl sull’uso dei Cookie disponibile sul Sito on-line.<br />
    Gli elementi che compongono le Esperienze/servizio, in particolare, ma senza limitazione, testi, fotografie, immagini, icone, logo, nonché il marchio Adrenalinika, sono proprietà piena ed esclusiva di Adrenalinika srl. È severamente vietato utilizzare in qualsiasi modo, ivi incluso, a titolo esemplificativo, riprodurre, rappresentare, modificare, trasmettere, pubblicare, adattare, su qualsivoglia supporto, con qualsivoglia mezzo, o sfruttare in qualsivoglia modo, tutto o parte di questi elementi senza la previa<br />
    autorizzazione scritta di Adrenalinika srl.<br />
    Il Sito è un’opera dell’ingegno protetta dal diritto di proprietà intellettuale. Il Sito e ogni elemento che lo compone (quali i marchi registrati, i loghi, i testi, ecc.) sono proprietà esclusiva di Adrenalinika srl, di partner o di terzi e sono oggetto di protezione a titolo di proprietà intellettuale. Accedendo al Sito on-line è quindi concessa all’Utente, all’Acquirente e al Beneficiario una licenza d’uso limitata alla navigazione sul Sito, in via non esclusiva e unicamente per uso privato. Quindi, la riproduzione di documenti pubblicati sul Sito è possibile al solo fine informativo e per un utilizzo strettamente personale e privato.<br />
    È espressamente vietata ogni riproduzione o modifica, integrale o parziale, del Sito o di uno qualsiasi degli elementi che lo compongono e che appartengono a</p>

    <p>Adrenalinika srl o a terzi, su qualsivoglia supporto, ai fini diversi dall’uso personale e privato, e in particolare a fini commerciali, a meno che non consti la previa autorizzazione scritta di Adrenalinika srl o di terzi titolari dei diritti dell’opera. Ogni operazione in violazione di quanto sopra espone l’Utente, l’Acquirente e/o il Beneficiario a responsabilità penale e civile.<br />
    16.3: Integralità del Contratto<br />
    Il mancato esercizio, in un dato momento, da parte di Adrenalinika srl, di una delle clausole dei presenti Termini e Condizioni non può essere interpretato come rinuncia ad avvalersi successivamente di predette clausole e dei diritti e delle facoltà da esse derivanti. Nell’ipotesi in cui una delle pattuizioni dei presenti Termini e Condizioni dovesse essere dichiarata nulla o considerata inefficace, la validità delle altre disposizioni non sarà rimessa in causa.<br />
    16.4: Legge Applicabile e Controversie<br />
    I presenti Termini e Condizioni e il contratto concluso in occasione di un acquisto di Esperienza/Servizio, comprese le questioni che riguardano la loro validità, la loro interpretazione, la loro esecuzione o il recesso dai medesimi, sono soggetti alla legge italiana. Si segnala che l’Acquisto on-line delle Esperienze è soggetto, tra l’altro, alle disposizioni del Codice del Consumo, sui contratti a distanza. Per quanto riguarda le eventuali controversie, le Parti si impegnano prima di esperire ogni azione giudiziaria, a cercare di raggiungere in buona fede una soluzione amichevole in un termine di trenta (30) giorni. L’Acquirente o il Beneficiario dovrà quindi preventivamente presentare il proprio reclamo per iscritto al Servizio Clienti di Adrenalinika .<br />
    Nel caso di impossibilità di trovare un accordo amichevole, la controversia sarà devoluta alla giurisdizione italiana competente secondo le leggi in vigore applicabili.<br />
    16.5: Adrenalinika declina ogni responsabilità da eventuali incorrettezze e/o imprecisioni delle informazioni trasmesse.<br />
    Qualunque offerta contenuta nel sito non comporta per Adrenalinika alcuna obbligazione legale. Parti del testo o l’intera pubblicazione, offerte incluse, potranno subire variazioni e/o integrazioni oppure essere eliminate senza preavviso da parte di Adrenalinika.</p>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageRegolamento;
