import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import Input from "shared/Input/Input";
import Checkbox from "shared/Checkbox/Checkbox";
import { withRouter } from 'react-router';
import PageSignUp from "containers/PageSignUp/PageSignUp";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Formik, Field, Form } from "formik";
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import provList from "./provincia";
import Cookies from 'universal-cookie';

  //check is en
  const lang = new Cookies();
  lang.get('lang');


class NameForm extends React.Component {


  render() {

    function sendEmail(datas){

      var dataEmail = { email: datas.email, };
      var values = datas.password;
      var passwordcrypt = hashPassword(values.password);
      var tn = "Adrenalinika";
      var token = hashToken(tn);
      const xhr = new XMLHttpRequest();
      const urlS = 'https://www.adrenalinika.com/gestionale/ajax_exemple/mailfrontend/creazione-utente.php';


      xhr.open('PUT', urlS, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.onreadystatechange = function () {
      // In local files, status is 0 upon success in Mozilla Firefox
      if(xhr.readyState === XMLHttpRequest.DONE) {
        var status = xhr.status;

        if (status === 0 || (status >= 200 && status < 400)) {

          toast.success('Iscrizione Avvenuta con successo. Controlla la tua casella email per i dettagli.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });


        } else {
          toast.error('Errore durante il processo di iscrizione. Contatta l\'amministratore del sito', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
      }
    };
    xhr.send(JSON.stringify(dataEmail));
    }
    return (

      <div>

      <Formik
        initialValues={{ name: "", surname: "", email: "", phone: "", vat: "", address: "", city: "", cap: "", password: "", privacy: "", marketing: "", provincia:"",}}
        onSubmit={(values, actions) => {
          //await new Promise((resolve) =>
          setTimeout(() => {
           //alert(JSON.stringify(values, null, 2));
           actions.setSubmitting(false);
         }, 3000);
         var control = values.privacy;
         var controla = values.marketing;

         if (!control || control == '' || control == "[]" || control == 0){
           toast.error('Devi acconsentire al trattamento dei dati personali per poter effettuare la registrazione', {
             position: "top-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
          actions.setSubmitting(false);
         } else{
         var passwordcrypt = hashPassword(values.password);
         var tn = "Adrenalinika";
         var token = hashToken(tn);


         var datas = {
                        firstName: values.name,
                        lastName: values.surname,
                        email: values.email,
                        phone: values.phone,
                        provincia: values.provincia,
                        city: values.city,
                        address: values.address,
                        zip: values.cap,
                        cod_fiscale: values.vat,
                        password: passwordcrypt,
                        timezone: "Europe/Rome",
                        language: "italian",
                        notes: "api react",
                        foto: "https://leganerd.com/wp-content/uploads/2021/12/NFT.jpg",
                        privacy: JSON.stringify(parseInt(control[0])),
                        marketing: JSON.stringify(parseInt(controla[0])),
           };


            var datatot = JSON.stringify(datas);

            const xhr = new XMLHttpRequest();
            const url = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/customers';


            xhr.open('POST', url, true);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("Authorization", 'Bearer ' + token);
            //xhr.setRequestHeader("Token", "Basic ".passwordcrypt);
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhr.onreadystatechange = function (datatot) {
            // In local files, status is 0 upon success in Mozilla Firefox
            if(xhr.readyState === XMLHttpRequest.DONE) {
              var status = xhr.status;

              if (status === 0 || (status >= 200 && status < 400)) {
                if(status == 200){
                  toast.error('Utente già registrato. Se non ricordi le credenziali, utilizza il form di recupero.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                } else if (status == 201) {
                  var style = document.createElement('style');
                   style.innerHTML = '.displaynoneclass { display: none!important;}';
                  document.head.appendChild(style);
                  var paginareg = document.getElementById('paginareg');
                  paginareg.classList.remove("nc-PageSignUp");
                  paginareg.classList.add("displaynoneclass");
                  var paginasuccess = document.getElementById('successreg');
                  paginasuccess.insertAdjacentHTML(
                    'beforeend',
                    `<h1 style="padding: 60px auto; margin: auto; text-align: center; padding-top: 80px; font-size: 33px; color: green; font-weight: 700;max-width:90%;line-height:40px;"> Registrazione avvenuta con successo</h1> <br /> <a href="https://www.adrenalinika.com" style="margin: 20px auto 20px auto; font-size:20px;display:block;text-align:center;max-width:90%; background: #e02c1e; color: white; font-weight: 800; text-transform: uppercase; padding: 13px; border-radius: 200px;">Torna in home</a> <br /> <a href="https://www.adrenalinika.com/login" style="margin: 20px auto 60px auto; font-size:20px;display:block;text-align:center;max-width:90%; background: #028001; color: white; font-weight: 800; text-transform: uppercase; padding: 13px; border-radius: 200px;">Accedi ora</a>`,
                  );
                  sendEmail(datas);
                }
              } else {
                // Oh no! There has been an error with the request!

                toast.error('Errore durante il processo di iscrizione. Contatta l\'amministratore del sito', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
              }
            }
          };
          xhr.send(datatot);
      }
    }}

      >
      {
        props => (
      <form className="grid grid-cols-1 gap-6" onSubmit={props.handleSubmit}>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            {lang.cookies.lang == "en" ? "Name" : "Nome"}
          </span>
          <Input
            type="text"
            placeholder={lang.cookies.lang == "en" ? "Your name" : "Il tuo nome"}
            className="mt-1"
            name="name"
            value={props.values.name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            required
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            {lang.cookies.lang == "en" ? "Surname" : "Cognome"}
          </span>
          <Input
            type="text"
            placeholder={lang.cookies.lang == "en" ? "Your surname" : "Il tuo cognome"}
            className="mt-1"
            id="cognome"
            name="surname"
            value={props.values.surname}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            required
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            Email address
          </span>
          <Input
            type="email"
            placeholder="example@example.com"
            className="mt-1"
            name="email"
            value={props.values.email}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            required

          />
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            {lang.cookies.lang == "en" ? "Phone" : "Telefono"}
          </span>
          <Input
            type="text"
            placeholder="+39 000 000 0000"
            className="mt-1"
            id="telefono"
            name="phone"
            value={props.values.phone}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            required
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            {lang.cookies.lang == "en" ? "VAT/Tax code" : "P.Iva/C.F."}
          </span>
          <Input
            type="text"
            placeholder={lang.cookies.lang == "en" ? "VAT/Tax code" : "P.Iva/C.F."}
            className="mt-1"
            id="vat"
            value={props.values.vat}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            required
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200 mb-1">
            {lang.cookies.lang == "en" ? "Province" : "Provincia"}
          </span>
          <select value={props.values.provincia} onChange={props.handleChange} onBlur={props.handleBlur} name="provincia" key="provincia" className="mt-1 rounded-2xl text-sm font-normal h-11 px-4 py-3 block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ">{ provList ? provList.map( (x,y) => <option key={y} id={x} value={x.nome} name={x.codice} >{x.nome}</option> ) : <option></option>}</select>
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            {lang.cookies.lang == "en" ? "Address" : "Indirizzo"}
          </span>
          <Input
            type="text"
            placeholder={lang.cookies.lang == "en" ? "Your Address" : "Indirizzo di residenza"}
            className="mt-1"
            id="address"
            value={props.values.address}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            required
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            {lang.cookies.lang == "en" ? "City" : "Città"}
          </span>
          <Input
            type="text"
            placeholder={lang.cookies.lang == "en" ? "Your city" : "Città di residenza"}
            className="mt-1"
            id="city"
            value={props.values.city}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            required
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            CAP
          </span>
          <Input
            type="text"
            placeholder=""
            className="mt-1"
            id="zip"
            name="cap"
            value={props.values.cap}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            required
          />
        </label>
        <label className="block">
          <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
            Password
          </span>
          <Input type="password" className="mt-1" name="password"
          value={props.values.password}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          required
          />
        </label>
        <label className="block">
        <Field type="checkbox" name="privacy" value="1" className="mr-2" />
        {lang.cookies.lang == "en" ? "I authorize the processing of personal data pursuant to Legislative Decree 2018/101 and the GDPR (EU Regulation 2016/679).*" : "Autorizzo il trattamento dei dati personali ai sensi del D.Lgs. 2018/101 e del GDPR (Regolamento UE 2016/679).*"}

        </label>
        <label className="block">
        <Field type="checkbox" name="marketing" value="1" className="mr-2" />
        {lang.cookies.lang == "en" ? "I authorize Adrenalinika to periodically send me dedicated offers and promotions." : "Autorizzo Adrenalinika ad inviarmi periodicamente offerte e promozioni dedicate."}
        </label>
        {props.errors.name && <div id="feedback">{props.errors.name}</div>}
        <ButtonPrimary type="submit">{lang.cookies.lang == "en" ? "Continue" : "Continua"}</ButtonPrimary>
      </form>
)}
</Formik>
<ToastContainer />
</div>
);
  }
}

ReactDOM.render(
  <NameForm />,
  document.getElementById('root')
);

export default NameForm;
