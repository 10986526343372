import React, { FC, useState, useEffect } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import CurrencyInput from 'react-currency-input-field';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import Heading2 from "components/Heading/Heading2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkbox from "shared/Checkbox/Checkbox";
import ModalPhotos from "./ModalPhotos";
import NcImage from "shared/NcImage/NcImage";

export interface PagePrivacyCookieProps {
  className?: string;
}


const PagePrivacyCookie: FC<PagePrivacyCookieProps> = ({ className = "" }) => {


  return (
    <div
      className={`nc-Privacy overflow-hidden relative ${className}`}
      data-nc-id="PagePrivacyCookie"
    >
      <Helmet>
        <title>Indications Privacy and Cookies || Adrenalinika Experiences</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}

      <div className="container py-8 lg:py-8 space-y-4 lg:space-y-4">


        <ToastContainer />

        <h1 className="text-2xl">ADRENALINE Privacy Policy  </h1>
        <h3>Introduction</h3>
        This Privacy Policy outlines ADRENIKA's practices with respect to information collected from users who access our website at www.adrenalinika.com ("Site"), or otherwise share personal information with us (collectively : ”Users“).
        Legal Basis for Data Collection
        Process your personal information (i.e. any information that would enable you to be identified by reasonable means; hereinafter "Personal Information")
        it is necessary for the fulfillment of our contractual obligations towards the User and to provide our services, to protect our legitimate interests and to comply with the legal and financial regulatory obligations to which we are subject.
<br />
        When you use the Site, you consent to the collection, storage, use, disclosure and other uses of your Personal Information as described in this Privacy Policy.
<br />
        We encourage our Users to read the Privacy Policy carefully and to use it to make informed decisions.
<br />
        What information do we collect?
<br />
        We collect two types of user information.
<br />
        The first type concerns unidentified and non-identifiable information pertaining to a User (or Users) that may be made available or collected through the use of the Site ("Non-personal information"). We are not aware of the identity of a User from whom non-personal information has been collected. The non-personal information we collect may include aggregated usage information and technical information transmitted from your device, including certain software and hardware information (such as the type of browser and operating system your device uses, language preferences, login time etc.) and are used to improve the functionality of our Site. We may also collect information on User activity on the Site (for example pages viewed, online navigation, clicks, actions, etc.).
<br />
        The second type concerns Personal Information which is personally identifiable information, i.e. information that identifies an individual or can identify him with a reasonable effort. Such information includes:
         Device information: We collect personal information from devices. This information includes geolocation data, IP address, unique identifiers (such as MAC addresses and UUIDs) and other information relating to User activity throughout the Site.
         Registration information: when the User registers on our Site he will be asked to provide certain details such as: name and surname, e-mail or physical address, and other information.
         How do we receive user information?
         We receive personal information from several sources:
         When the User voluntarily provides personal data to register on the site;
         When the User uses our Site or accesses it in connection with the use of our services;
         From third-party providers, services and public records (for example, traffic analysis providers).
         What information do we collect?
         We do not rent, sell or share information about Users with third parties except as described in this Privacy Policy.
<br />
          We may use the information for the following purposes:
         Communicate with you – send notices about our services, provide technical information, and respond to any customer support issues;
         Communicate with the User and inform him of the latest updates and services;
         Serve advertisements when you use our Site (more information under "Advertisements");
         Market our websites and our products (more information under "Marketing");
         Carry out analyzes and statistics, aimed at improving the Site.
         In addition to the various uses listed above, we may transfer or disclose Personal Information to our subsidiaries, affiliated companies and contractors.
<br />
In addition to the purposes listed in this Privacy Policy, we may share Personal Information with our trusted third-party providers, who may be located in various jurisdictions around the world, for the following purposes:
         Hosting and operating our Site;
         Offer you our services, including a personalized view of our Site;
         Store and process this information on our behalf;
         Propose advertisements to the User and support us in evaluating the success of our advertising campaign, as well as helping us to recalibrate the target of users;
         Provide the User with marketing offers and promotional materials relating to the Site and services;
         Carry out research, technical diagnostics and analysis;
         We may also disclose information in good faith if we believe doing so is helpful or reasonable to: (i) comply with applicable law, regulation, legal process, or governmental request; (ii) enforce our policies (including the Agreement), including investigating potential violations; (iii) investigate, detect, prevent, or take action regarding illegal or other wrongful activity, suspected fraud, or security issues; (iv) establish or exercise our rights to defend against legal claims; (v) prevent damage to the rights, property or safety of us, our customers, Users or third parties; or (vi) for the purpose of cooperating with law enforcement agencies and/or as we deem it necessary to enforce intellectual property or other legal rights.
<br />
        User rights
<br />
        The User can request to:
         Receive confirmation whether or not your personal information is being processed and access your stored personal information, along with additional information.
         Receive a copy of the personal information you voluntarily provide in a structured, commonly used, and machine-readable format.
         Rectify personal information within our control.
         Erase personal information.
         Object to our processing of your personal information.
         Limit the processing of your personal information by us.
         File a complaint with the supervisory authority.
<br />
        However, please note that these rights are not absolute and may be subject to our legitimate interests and regulatory requirements.
<br />
        If you wish to exercise the above rights or receive more information, please contact our Data Protection Officer (“DPO”) using the details provided below:
         info@adrenalinika.com
<br />
        storage<br />
        We will keep your personal information for as long as necessary to provide our services and to comply with our legal obligations, resolve disputes and enforce our policies. The retention periods will be determined taking into account the type of information collected and the purpose for which it is collected, taking into account the requirements applicable to the situation and the need to destroy obsolete and unused information as soon as possible within reasonable limits. In light of the applicable regulations, we keep records containing the personal data of customers, documents relating to the opening of accounts, communications and anything else required by applicable laws and regulations.
<br />
        We may correct, supplement or remove incomplete or inaccurate information at any time in our sole discretion.
<br />
         Last modified 05/15/2020
      </div>
      <div key="Cookie" id="Cookie"  className="container py-8 lg:py-8 space-y-4 lg:space-y-4">

      <h1 className="text-2xl mt-5">Cookie Policy</h1><br />


    We and our trusted partners use cookies and other technologies in our related services, including when you visit our Site or access our services.
 <br />
 A "cookie" is a small piece of information that a website assigns to the User's device while viewing a website. Cookies are very useful and can be used for various purposes, which include the ability to navigate between pages efficiently, enable the automatic activation of some features, remember preferences and make interaction between the User and our services faster and easier. Cookies are also used to ensure that the advertisements displayed are relevant to the User and his interests and to compile statistical data on the use of our Services.
     <br />
    The Site uses the following types of cookies:
     <br />
    to. 'session cookies' stored only temporarily during a browsing session to allow normal use of the system, deleted from the device when the browser is closed;
     <br />
    b. 'persistent cookies' read only by the Site, saved on the User's computer for a set period and not deleted when the browser is closed. These cookies are used when we need to know who you are for repeat visits, for example to allow us to store your preferences for next time you log in;
     <br />
    c. 'third party cookies' set by other online services that track content on the page viewed, for example, by third party analytics companies, who monitor and analyze access to our website.
     <br />
    The cookies do not contain any information that identifies you personally, but the Personal Information we store about you may be linked, by us, to the information stored in and obtained from the cookies. You can remove cookies by following your device preferences instructions; however, if you choose to disable cookies, some features of our site may not work properly and your online experience may be limited.
     <br />
     We also use a tool called "Google Analytics" to collect information on the use of the Site. Google Analytics collects information such as how often Users access the Site, which pages they visit, when they do so, etc. We use the information for the sole purpose of improving our Site and our services. Google Analytics collects the IP address assigned to you on the date you visited the sites instead of your name or other identifying information. We do not combine information collected through Google Analytics with personally identifiable information. Google's ability to use and share the information it collects about your visit to this Site is limited to the Google Analytics Terms of Use and Google's Privacy Policy.
            Third Party Information Collection
            Our policy only concerns the use and disclosure of information collected from the User. To the extent that you disclose your information to other parties or sites on the Internet, different rules may apply to the use or disclosure of the information you disclose to them. Accordingly, we encourage Users to read the terms and conditions and privacy policy of each third party to whom they choose to disclose their information.
       <br />
       This Privacy Statement does not apply to the practices of companies we do not own or control, or individuals who do not work for us and that we do not manage, including third parties who may disclose information as set forth in this Privacy Statement.
              How do we take care of the information?
              We take great care in implementing and maintaining the security of the Site and user information.
              We have adopted industry standard policies and procedures to ensure the security of the information we collect and maintain, we prevent unauthorized use of such information, and we require any third party to comply with similar security requirements, in accordance with this Privacy Policy. While we have taken all reasonable steps to safeguard your information, we cannot be responsible for the acts of those who gain unauthorized access to or misuse our Site, and we make no warranties, express, implied or otherwise, that we will prevent such access.
              Data transfer outside the European Economic Area
              Note: Some data recipients may be located outside the European Economic Area. In that case, we will only transfer data to countries approved by the European Commission as offering an adequate level of data protection, or enter into legal agreements to ensure an adequate level of data protection.
              Advertisements
              We may use third-party advertising technologies to serve advertisements when the User accesses the Site). These technologies use customer information relating to the use of the Services to deliver advertisements (for example, placing third-party cookies on the User's web browser).
               <br />
               You can opt-out of many third-party ad networks, including those operated by members of the Network Advertising Initiative (“NAI”) and the Digital Advertising Alliance (“DAA”). For more information about this practice of NAI and DAA members and your choices regarding these companies' use of that information, including how to opt out of third-party ad networks operated by NAI and DAA members, please visit their respective websites: http://optout.networkadvertising.org/#!/ and http://optout.aboutads.info/#!/.
                      Marketing
                      We may use Personal Information such as your name, email address, telephone number, etc. internally or using third-party collaborators for the purpose of providing you with promotional materials relating to our services, which we believe may be of interest to you.
                       <br />
                      Out of respect for your right to privacy, you will find within these marketing materials the means to opt-out of receiving further marketing offers from us. If you unsubscribe, we will remove your email address or phone number from our marketing distribution lists.
                       <br />
                      Note: Even if you have unsubscribed from receiving marketing emails from us, we may send you other types of important email communications without giving you an opportunity to opt-out. Such communications may include customer service announcements or administrative notices.
                      Corporate transaction
                      We may share information in the event of a corporate transaction (such as the sale of a significant portion of our business, a merger, consolidation or asset sale). In such event, the assignee or acquiring company will assume the rights and obligations as described in this Privacy Policy.
                      minors
                      We understand the importance of protecting children's privacy, especially in an online environment. Under no circumstances do we allow minors to use our services without the prior consent of a parent or legal guardian. We do not knowingly collect personal information from minors. If a parent or guardian becomes aware that the minor has provided us with Personal Information without their consent, you should contact us at INFO@ADRENALINIKA.COM
                      Updates or changes to this Privacy Policy
                      We reserve the right to periodically change or revise the Privacy Policy; material changes will be effective immediately upon viewing the updated Privacy Policy. The latest revision will be reported in the "Last modified" section. Your continued use of the Platform following notice of such changes on our website represents your acceptance and consent to such changes to the Privacy Policy and your agreement to abide by their terms.
                      How to contact us
                      For any general questions about the Site or about the information we collect about you and how we use it, you can contact us at info@adrenalinika.com
                       <br />
                      adrenaline |
                      via xx settembre, 44 Legnano (MI)
      </div>

    </div>
  );
};

export default PagePrivacyCookie;
