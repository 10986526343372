import React from "react";
import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "./styles/react_dates_overrides.scss";
//
import App from "./App";
//import reportWebVitals from "./reportWebVitals";


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
//console.log(process.env);
//console.log(process.env.SESSION_R);
