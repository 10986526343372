import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import React, { Fragment, useState,useReducer, useEffect } from "react";
import { useHistory, useLocation } from 'react-router';
import Cookies from 'universal-cookie';

const SearchDropdown = () => {

  const [getSearch, setSearch] = useState('');

  //check is en
  const lang = new Cookies();
  lang.get('lang');



  const onFormSubmit = e => {
  e.preventDefault();
  if(lang.cookies.lang == "en"){
    window.location.replace("/en/listing-experiences?luogo=&categoria=&min=&max=&sale=&search="+getSearch);
  } else {
    window.location.replace("/listing-experiences?luogo=&categoria=&min=&max=&sale=&search="+getSearch);
  }
  }


  return (
    <React.Fragment>
      <Popover className="relative">
        {({ open }) => {

          return (
            <>
              <Popover.Button className="text-2xl md:text-[28px] w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
                <i className="las la-search"></i>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="absolute right-0 z-10 w-screen max-w-xs mt-3"
                >   <div className="w-full relative mt-0 flex border flex-col md:flex-row md:items-center rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 dark:text-neutral-700 divide-y divide-neutral-200 dark:divide-neutral-700 md:mt-0 md:divide-y-0">
                    <form className="w-full relative mt-0 flex  flex-col md:flex-row md:items-center rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-900 dark:text-neutral-400 divide-y divide-neutral-200 dark:divide-neutral-700 md:mt-0 md:divide-y-0" onSubmit={onFormSubmit}>
                    <input
                      style={{border: "none", boxShadow: "unset"}}
                      className="w-full flex rounded-3xl outline-none focus:outline-none dark:bg-neutral-900"
                      type="search"
                      placeholder={lang.cookies.lang == "en" ? "Write something..." : "Scrivi qualcosa..."}
                      onChange={(e)=>{setSearch(e.target.value)}}
                    />
                    <button className="h-14 md:h-14 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none" type="submit" value="invia">{lang.cookies.lang == "en" ? "Send" : "Invia"}</button>
                    </form>
                    </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </React.Fragment>
  );
};

export default SearchDropdown;
