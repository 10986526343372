import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {makeRequest} from "components/AccountPage/AccountPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

export interface AccountBillingProps {
  className?: string;
  data?: string;
  location?: object;
}




const AccountBilling: FC<AccountBillingProps> = ({ className = "", location }) => {


  var user = JSON.parse(localStorage.getItem("user"));
  var ids = JSON.parse(localStorage.getItem("rjsth"));
  const idUserUpload = parseInt(atob(ids));

  var tn = "Adrenalinika";
  var token = hashToken(tn);
  const urlUserUpload = "https://www.adrenalinika.com/gestionale/ajax_exemple/voucher/get-user-voucher.php";

  async function sendUploadUser (idUserUpload) {
  return await new Promise(function (resolve, reject) {

    var xhr = new XMLHttpRequest();
    xhr.open('POST', urlUserUpload, true);
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);

      }
    };


    xhr.send("customer_id=" + JSON.stringify(idUserUpload));

  });
  };



    const [data, setDataTot] = useState([]);
    var fieldVoucher = "";


    //inizio richiamo api per la richiesta lista
    if(location.pathname == "/voucher-acquistati"){
      var uploadUs = sendUploadUser(idUserUpload);
        fieldVoucher  = () =>  Promise.all ([uploadUs]).then((listRequestUpl) => {
        return listRequestUpl;
      });
    }



  useEffect(() => {
    if(fieldVoucher != ""){
    fieldVoucher().then((ressts) => {
      var result = JSON.parse(ressts);
      setDataTot(result);
      });
    }
  }, []);


  if(!user){
    return (
      <Redirect to="/"/>
    );
  }

else{
  var dataScaduti = data;
  dataScaduti = dataScaduti.filter((_, i) => moment(dataScaduti[i].data_scadenza) <= moment() && moment(dataScaduti[i].data_scadenza) > moment().add(-365, 'days') );
  var dataUtilizzati = data;
  dataUtilizzati = data.filter((_, x) => parseInt(dataUtilizzati[x].importo) == 0 || moment(dataUtilizzati[x].utilizzato) == 1);
  var dataAttivi = data;
  dataAttivi = dataAttivi.filter((_, i) => moment(data[i].data_scadenza) > moment() && parseInt(data[i].importo) != 0 && parseInt(data[i].utilizzato) != 1 && parseInt(data[i].pagamento_effettuato) == 1);
  var dataAttesaConferma = data;
  dataAttesaConferma = dataAttesaConferma.filter((_, i) => moment(data[i].data_scadenza) > moment() && parseInt(data[i].importo) != 0 && parseInt(data[i].utilizzato) != 1 && parseInt(data[i].pagamento_effettuato) == 0);


  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8 pb-12">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Voucher</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="">
            <span className="text-xl font-semibold block">Voucher attivi</span>
            <small className="mb-4 block">Seleziona la voce "Utilizza" in corrispondenza del voucher che vuoi utilizzare. Nella schermata successiva potrai scegliere data, ora ed eventuali optional. Le disponibilità di alcune esperienze possono essere soggette a variazioni in base al clima. </small>
            <br />
            {dataAttivi ? dataAttivi.map((_, i) => (
                <ExperiencesCardH data={dataAttivi[i]} key={dataAttivi[i].id+[i]} buttonName="Utilizza" ButtonClass="background-red" link="/riscatta-il-tuo-voucher" />
              )): <h2 className="text-1xl md:text-xl font-semibold ">Nessun Risultato...</h2>}

          </div>
        </div>

          <div className="space-y-6 sm:space-y-8  pb-12">
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div>
              <span className="text-xl font-semibold block">Voucher In attesa di pagamento</span>
              <small className="mb-4 block">In questa sezione sono elencati i voucher in attesa di conferma ricezione del pagamento. (Es. Bonifico Bancario, Welfare ecc..). Potrai prenotare la tua esperienza, nonappena il pagamento figurerà nei sistemi Adrenalinika. </small>
              <br />
              <div  style={{opacity: '0.7'}}>
                      {dataAttesaConferma ? dataAttesaConferma.map((_, i) => (
                        <ExperiencesCardH data={dataAttesaConferma[i]} key={dataAttesaConferma[i].id+[i]} buttonName="Richiedi info" ButtonClass="background-red" link="/contact" />
                      )): <h2 className="text-1xl md:text-xl font-semibold ">Nessun Risultato...</h2>}
              </div>
            </div>
          </div>

          <div className="space-y-6 sm:space-y-8  pb-12">
            {/* HEADING */}
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div>
                <span className="text-xl font-semibold block">Voucher scaduti</span>
                <small className="mb-4 block">Puoi richiedere una proroga di scadenza entro e non oltre i 30 giorni. Il servizio di rinnovo ha un costo pari al 30% dell'importo del voucher.</small>
                <br />
                <div  style={{opacity: '0.7'}}>
                  {dataScaduti ? dataScaduti.map((_, i) => (
                      <ExperiencesCardH data={dataScaduti[i]} key={dataScaduti[i].id+[i]} buttonName="Rinnova" ButtonClass="background-red" link="/rinnova" />
                    )): <h2 className="text-1xl md:text-xl font-semibold ">Nessun Risultato...</h2>}
              </div>
              </div>
        </div>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div style={{opacity: '0.7'}}>
              <span className="text-xl font-semibold block">Voucher utilizzati</span>
              <small className="mb-4 block">Storico di voucher acquistati. </small>
              <br />
              {dataUtilizzati ? dataUtilizzati.map((_, x) => (
                  <ExperiencesCardH data={dataUtilizzati[x]} key={dataUtilizzati[x].id+[x]} buttonName="Acquista ancora" ButtonClass="" completeLink={dataUtilizzati[x].href} />
                )): <h2 className="text-1xl md:text-xl font-semibold ">Nessun Risultato...</h2>}
            </div>
      </div>
        </CommonLayout>
    </div>
  );
};
}

export default AccountBilling;
