import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";
import __megamenuuno from "./jsons/__megamenuuno.json";
import __megamenudue from "./jsons/__megamenudue.json";
import {makeListCategory} from "hooks/useRequestDb";
import {makeListSubCategories} from "hooks/useRequestSubCategories";
import {makeListPlaces} from "hooks/useRequestPlaces";




const megaMenuDemo: MegamenuItem[] = [
  {
    id: ncNanoId(),
    image:
      "https://www.adrenalinika.com/gestionale/luoghi/roma.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Luoghi",
    linkimg: "/listing-places",
    items: __megamenu.map((i) => ({
      id: i.id,
      href: "/places/"+i.nome+"/"+i.link,
      name: i.titolo,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://www.adrenalinika.com/gestionale/categorie/terra.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Categorie",
    linkimg: "/listing-categories",
    items: __megamenuuno.map((i) => ({
      id: i.id,
      href: "/categoria/"+i.nome+"/"+i.link,
      name: i.titolo,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://www.adrenalinika.com/gestionale/sottocategorie/giro-in-pista.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Tipologie",
    linkimg: "/listing-subcategories",
    items: __megamenudue.map((i) => ({
      id: i.id,
      href: "/subcategories/"+i.link,
      name: i.titolo,
    })),
  },
];



const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
  },
  {
    id: ncNanoId(),
    href: "/blog-single",
    name: "Blog Single",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
  },
  {
    id: ncNanoId(),
    href: "/login",
    name: "Login",
  },
  {
    id: ncNanoId(),
    href: "/signup",
    name: "Accedi",
  },
  {
    id: ncNanoId(),
    href: "/subscription",
    name: "Subscription",
  },
];


export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    type: undefined,
    //children: demoChildMenus,
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/listing-experiences",
    name: "Attività Adrenaliniche",
    type: "megaMenu",
    megaMenu: megaMenuDemo,
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/area-riscatto-voucher",
    name: "Area Riscatto",
    type: undefined,
    isNew: true,

  },
  {
    id: ncNanoId(),
    href: "/giftcard",
    name: "Gift Card",
    type: undefined,

  },

  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog",
    type: undefined,
  },

  {
      id: ncNanoId(),
      href: "/contact",
      name: "Contatti",
      type: undefined,
    },
];



export const NAVIGATION_DEMO_MOB: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    type: undefined,
    //children: demoChildMenus,
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/listing-subcategories",
    name: "Attività Adrenaliniche",
    type: "megaMenu",
    megaMenu: megaMenuDemo,
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/area-riscatto-voucher",
    name: "Area Riscatto",
    type: undefined,
    isNew: true,

  },
  {
    id: ncNanoId(),
    href: "/giftcard",
    name: "Gift Card",
    type: undefined,

  },

  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog",
    type: undefined,
  },

  {
      id: ncNanoId(),
      href: "/contact",
      name: "Contatti",
      type: undefined,
    },
];
