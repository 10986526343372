import React, { FC, useState, useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import Select from "react-select";
import {makeListPlaces} from "hooks/useRequestPlaces";
import {makeListSubCategories} from "hooks/useRequestSubCategories";
import Cookies from 'universal-cookie';


export interface CategoryInputProps {
  defaultValue: string;
  onChange?: (valuecat: string) => void;
  onInputDone?: (valuecat: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  data: [];
}

const CategoryInput: FC<CategoryInputProps> = ({
  defaultValue,
  autoFocus = false,
  onChange,
  onInputDone,
  placeHolder = "Categoria",
  desc = "Che tipo di esperienza stai cercando?",
  className = "nc-flex-1.5",
  data,
  luoghi,
}) => {

  const [subcategories, setFieldSubCategories] = useState([]);
  const [placeSet, setPlaceSet] = useState([]);//filtra api per luogo cercato
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [valuecat, setValuecat] = useState(defaultValue);
  const [showPopover, setShowPopover] = useState(autoFocus);

  var lisPlaces = makeListPlaces();
  const listPlaces  = () =>  Promise.all ([lisPlaces]).then((listRequestPlaces) => {
    return listRequestPlaces;
  });
  var lisSubCat = makeListSubCategories();
  const listSubCategories  = () =>  Promise.all ([lisSubCat]).then((listRequestSubCat) => {
    return listRequestSubCat;
  });

  //check is en
  const lang = new Cookies();
  lang.get('lang');

  if(lang.cookies.lang == "en"){
    placeHolder = "Category";
    desc = "What kind of experience are you looking for?";
  }

useEffect(() => {

      listPlaces().then((resp) => {
        resp = JSON.parse(resp);
        setPlaceSet(resp);
      });
      listSubCategories().then((rest) => {
        setFieldSubCategories(JSON.parse(rest));
      });
    }, []);

  useEffect(() => {
    setValuecat(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    onChange && onChange(valuecat);
  }, [valuecat]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string) => {
    setValuecat(item);
    onInputDone && onInputDone(item);
    setShowPopover(false);
  };






  var resultCat = Object.values(data).map((name,i) => data[i].name);
  const resultCatEn = Object.values(data).map((name,i) => data[i].nome_eng);
  var resultCats = resultCat.filter(name => name.toLowerCase().includes(valuecat) || name.includes(valuecat) || name.toUpperCase().includes(valuecat));

  var ok = placeSet.filter(resp => resp.name == luoghi);

  if(ok != '' && ok){
    ok = ok[0].id_sottocategoria;
    var subCat = ok.map((_,i) => {
      var result = subcategories.filter(subcategories => parseInt(subcategories.id) == ok[i]);
      return result[0].name;
    });
    resultCats = subCat;
  }
  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          {lang.cookies.lang == "en" ? "Categories" : "Categoria"}
        </h3>
        <div className="mt-2">
          {lang.cookies.lang == "en" ? resultCatEn.filter((_,i) => resultCatEn[i] != null && resultCatEn[i] != "").map((item) => (
              <span
                onClick={() => handleSelectLocation(item)}
                key={item}
                className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
              >
                <span className="block text-neutral-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 sm:h-6 w-4 sm:w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                  {item}
                </span>
              </span>
            )) : resultCats.map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {lang.cookies.lang == "en" ? resultCatEn.filter((_,i) => resultCatEn[i] != null && resultCatEn[i] != "").map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 sm:h-6 w-4 sm:w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item}
              </span>
            </span>
          )) : resultCats.map((item) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item}
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "shadow-2xl rounded-full dark:bg-neutral-800" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={valuecat}
            autoFocus={showPopover}
            onChange={(e) => setValuecat(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!valuecat ? placeHolder : desc}</span>
          </span>
          {valuecat && showPopover && (
            <ClearDataButton onClick={() => setValuecat("")} />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {valuecat ? renderSearchValue() : renderRecentSearches()}
        </div>
      )}
    </div>
  );
};

export default CategoryInput;
