import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import React, { FC, useState, useEffect }from "react";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import { withRouter } from 'react-router';
import NameForm from "components/FormSignup/FormSignup";

export interface PageSignUpProps {
  className?: string;
}


/*const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];*/

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
    <div id="successreg"></div>
    <div id="paginareg">
      <Helmet>
        <title>Registrati al portale delle esperienze || Adrenalinika experience</title>
        <meta name="description" content="Iscriviti ad Adrenalinika, il portale delle esperienze in Italia. Ricevi offerte dedicate e consigli per rendere indimenticabili le tue avventure." />
        <link rel="canonical" href="https://www.adrenalinika.com/signup" />
      </Helmet>

      <div className="container mb-24 lg:mb-32">
        <h2 className="mt-20 mb-5 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Registrati
        </h2>
        <p className="text-center mb-20 px-10">Compila il form di iscrizione per creare un account da cui portai gestire tutte le esperienze acquistate.<br/> Avrai la possibilità di riscattare i tuoi voucher esperienza oppure gestire le tue prenotazioni.</p>
        <div className="max-w-md mx-auto space-y-6 " id="divformsignup">

          {/* OR */}
          <div className="relative text-center">
          </div>
          {/* FORM */}
          <NameForm />

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Hai già un account? {` `}
            <Link to="/login">Accedi</Link>
          </span>
        </div>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
