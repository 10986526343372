import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import { NAVIGATION_DEMO_EN } from "data/navigationEn";
import Cookies from 'universal-cookie';



//check is en
const lang = new Cookies();
lang.get('lang');


function Navigation() {
  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {lang.cookies.lang == "en" ? NAVIGATION_DEMO_EN.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      )) : NAVIGATION_DEMO.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
