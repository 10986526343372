import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayoutEn from "./CommonLayoutEn";
import ReactDOM from "react-dom";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardReservation";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {makeRequest} from "components/AccountPage/AccountPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

export interface AccountBillingEnProps {
  className?: string;
  data?: string;
}

const AccountBillingEn: FC<AccountBillingEnProps> = ({ className = "", location }) => {
  var user = JSON.parse(localStorage.getItem("user"));
  var ids = JSON.parse(localStorage.getItem("rjsth"));
  const idUserUpload = parseInt(atob(ids));


  var tn = "Adrenalinika";
  var token = hashToken(tn);
  const urlUserUpload = "https://www.adrenalinika.com/gestionale/ajax_exemple/sconti/get-user-sconti.php";

  async function sendUploadUser (idUserUpload) {
  return await new Promise(function (resolve, reject) {

    var xhr = new XMLHttpRequest();
    xhr.open('POST', urlUserUpload, true);
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);

      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    xhr.send("id=" + JSON.stringify(idUserUpload));

  });
  };


  const [data, setDataTot] = useState([]);
  var fieldVoucher = "";


  if(location.pathname == "/en/your-giftbox"){
    var uploadUs = sendUploadUser(idUserUpload);
    fieldVoucher  = () =>  Promise.all ([uploadUs]).then((listRequestUpl) => {
      return listRequestUpl;
    });
  }


  useEffect(() => {
    if(fieldVoucher != ""){
    fieldVoucher().then((ressts) => {
      var result = JSON.parse(ressts);
      setDataTot(result);
  });
}
  }, []);

  if(!user){
    return (
      <Redirect to="/en/"/>
    );
  }

else{
  var dataAttivi = data;
  dataAttivi = dataAttivi.filter((_, i) => parseInt(data[i].valore_rimanente) > 0 && parseInt(data[i].valore) != 0 && data[i].pagamento_effettuato == "1");
  var dataScaduti = data;
  dataScaduti = dataScaduti.filter((_, i) => parseInt(data[i].valore_rimanente) <= 0 || data[i].pagamento_effettuato == "2");
  var dataAttesa = data;
  dataAttesa = dataAttesa.filter((_, i) => parseInt(data[i].valore_rimanente) > 0 && parseInt(data[i].valore) != 0 && data[i].pagamento_effettuato == "0");
  return (
    <div>
      <CommonLayoutEn>
        <div className="space-y-6 sm:space-y-8 pb-12">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Discount codes</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="">
            <span className="text-xl font-semibold block">Active codes</span>
            <br />
            {dataAttivi ? dataAttivi.map((_, i) => (
              <div key={"codAttivo"+[i]} className="flex justify-between mb:inline-block mb:text-sm mb:border-0 mb:flex-row md:flex-row" style={{border:'1px solid #dbdbdb', padding:'15px 3%', borderRadius:'50px', boxShadow:'0 0 3px 0px #dbdbdb'}}>
              <div className="flex items-center mb:flex-row md:flex-row space-x-4 mb:text-sm text-neutral-500 dark:text-neutral-400 mb:inline-block">
                <div className="flex items-center">
                  <span className="hidden sm:inline-block md:text-xl mb:text-sm">
                  <b>Code:</b> {dataAttivi[i].codice}
                  </span>
                <span className="sm:inline-block md:text-xl mb:text-sm md:hidden">{' '+[i]+':'}</span>
                </div>
                <div className="flex items-center md:text-xl mb:text-sm" style={{borderLeft:'1px solid #ccc', paddingLeft:'25px'}}>
                  <span className=" sm:inline-block text-small mb:text-sm">
                  <b>Available:</b> € {dataAttivi[i].valore_rimanente}
                  </span>
                </div>
                </div>
                <div className="flex justify-between text-sm flex-col md:flex-row md:items-end mb:flex-row mb:items-center text-neutral-500 dark:text-neutral-400 " >
                <div className="flex text-xl justify-between md:items-end flex-col md:flex-row mb:flex-row mb:items-center mb:border-0" style={{borderLeft:'1px solid #ccc', paddingLeft:'25px'}}>
                  <span className=" sm:inline-block text-small">
                  <ButtonSecondary onClick={()=>{navigator.clipboard.writeText(dataAttivi[i].codice); toast.success('The code has been copied.');}}>Copy</ButtonSecondary>
                  </span>
                </div>
              </div>
              </div>
              )): <h2 className="text-1xl md:text-xl font-semibold ">No Results...</h2>}

          </div>
        </div>
<ToastContainer />
          <div className="space-y-6 sm:space-y-8  pb-12">
            {/* HEADING */}
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div style={{opacity: '0.7'}}>
                <span className="text-xl font-semibold block">Expired or used codes</span>
                <br />
                {dataScaduti ? dataScaduti.map((_, i) => (
                  <div key={"codNonAttivo"+[i]} className="flex justify-between mb:inline-block mb:text-sm mb:border-0 mb:flex-row md:flex-row" style={{border:'1px solid #dbdbdb', padding:'15px 3%', borderRadius:'50px', boxShadow:'0 0 3px 0px #dbdbdb'}}>
                  <div className="flex items-center mb:flex-row md:flex-row space-x-4 mb:text-sm text-neutral-500 dark:text-neutral-400 mb:inline-block">
                    <div className="flex items-center">
                      <span className=" sm:inline-block md:text-xl mb:text-sm">
                      <b>Codice:</b> {dataScaduti[i].codice}
                      </span>
                    </div>
                    <div className="flex items-center md:text-xl mb:text-sm" style={{borderLeft:'1px solid #ccc', paddingLeft:'25px'}}>
                      <span className=" sm:inline-block text-small mb:text-sm">
                      <b>Disponibile:</b> € {dataScaduti[i].valore_rimanente}
                      </span>
                    </div>
                    </div>
                    <div className="flex justify-between text-sm flex-col md:flex-row md:items-end mb:flex-row mb:items-center text-neutral-500 dark:text-neutral-400 " >
                    <div className="flex text-xl justify-between md:items-end flex-col md:flex-row mb:flex-row mb:items-center mb:border-0" style={{borderLeft:'1px solid #ccc', paddingLeft:'25px'}}>
                      <span className=" sm:inline-block text-small">
                      <ButtonSecondary disabled={true}>Copia</ButtonSecondary>
                      </span>
                    </div>
                  </div>
                  </div>
                )): <h2 className="text-1xl md:text-xl font-semibold ">No Results...</h2>}
              </div>
        </div>

        <div className="space-y-6 sm:space-y-8  pb-12">
          {/* HEADING */}
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div style={{opacity: '0.7'}}>
              <span className="text-xl font-semibold block">Codes awaiting payment confirmation</span>
              <br />
              {dataAttesa ? dataAttesa.map((_, i) => (
                <div key={"codNonAttivo"+[i]} className="flex justify-between mb:inline-block mb:text-sm mb:border-0 mb:flex-row md:flex-row" style={{border:'1px solid #dbdbdb', padding:'15px 3%', borderRadius:'50px', boxShadow:'0 0 3px 0px #dbdbdb'}}>
                <div className="flex items-center mb:flex-row md:flex-row space-x-4 mb:text-sm text-neutral-500 dark:text-neutral-400 mb:inline-block">
                  <div className="flex items-center">
                    <span className=" sm:inline-block md:text-xl mb:text-sm">
                    <b>Code:</b> {dataAttesa[i].codice}
                    </span>
                  </div>
                  <div className="flex items-center md:text-xl mb:text-sm" style={{borderLeft:'1px solid #ccc', paddingLeft:'25px'}}>
                    <span className=" sm:inline-block text-small mb:text-sm">
                    <b>Available:</b> € {dataAttesa[i].valore_rimanente}
                    </span>
                  </div>
                  </div>
                  <div className="flex justify-between text-sm flex-col md:flex-row md:items-end mb:flex-row mb:items-center text-neutral-500 dark:text-neutral-400 " >
                  <div className="flex text-xl justify-between md:items-end flex-col md:flex-row mb:flex-row mb:items-center mb:border-0" style={{borderLeft:'1px solid #ccc', paddingLeft:'25px'}}>
                    <span className=" sm:inline-block text-small">
                    <ButtonSecondary disabled={true}>Copy</ButtonSecondary>
                    </span>
                  </div>
                </div>
                </div>
                )): <h2 className="text-1xl md:text-xl font-semibold ">No Results...</h2>}
            </div>
      </div>

        </CommonLayoutEn>
    </div>
  );
};
}

export default AccountBillingEn;
