import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ExperiencesSearchForm from "components/HeroSearchForm/ExperiencesSearchForm";
import GallerySliderHome from "components/GallerySlider/GallerySliderHome";


export interface SectionHeroDueProps {
  data: [];
  className?: string;
  luoghi: [];
  imgGall:[];
}

const SectionHeroDue: FC<SectionHeroDueProps> = ({ className = "", data , luoghi, imgGall }) => {


  return (
    <div
      className={`nc-SectionHero flex flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col">
        <div className="flex-shrink-0 flex flex-col items-start ">
          <div className="relative w-full overflow-hidden absolute">
          <GallerySliderHome galleryImgs={imgGall} />
          </div>
              <div className={`nc-HeroSearchForm w-full max-w-6xl py-0 mb:mt-0  mt-6 ml-auto mr-auto ${className}`} style={{marginBottom: "-20px"}} data-nc-id="HeroSearchForm">
                <ExperiencesSearchForm data={data} luoghi={luoghi} />
              </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeroDue;
