import React, { FC, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import { withRouter } from 'react-router';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {getToken} from "components/FormLogin/FunctionUser";
import {makeRequest} from "components/AccountPage/AccountPage";

export {checkToken,getUs,checkTokenControls };


var tkns = sessionStorage.getItem("session_id");
  async function checkToken(){
    var getTok = getToken();

    const checkAll  = () =>  Promise.all ([getTok]).then((ciao) => {
       return ciao;
     });
     var check = checkAll();
  return check;
}



  async function checkTokenControls(){
  var tkns = sessionStorage.getItem("session_id");
  var check = false;
  if(tkns){
    var getTok = getToken();

    const checkAll  = () =>  Promise.all ([getTok]).then((ciao) => {
      var otp = "r3DqDg=";
      var checkTok = atob(tkns).split(otp);
      ciao = atob(ciao);
      ciao = ciao.replace(',', '');

      checkTok = atob(checkTok[0]); // da session giusto
      if (checkTok == ciao){
        return true;
      } else {
        return false;
      }

     });

    check = checkAll();

    return check
  }

return check;
}




async function getUs(urlUserUpload){

  var tn = "Adrenalinika";
  var token = hashToken(tn);


    return await new Promise(function (resolve, reject) {

      var xhr = new XMLHttpRequest();
      xhr.open('GET', urlUserUpload, true);
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTION, PUT");
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };

      xhr.send();
    });

}
