import {hashToken} from "components/FormLogin/FunctionSalt";



var tn = "Adrenalinika";
var token = hashToken(tn);
const url = 'https://www.adrenalinika.com/gestionale/ajax_exemple/get-all-luoghi.php';





  async function makeListPlaces () {
  return await new Promise(function (resolve, reject) {

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);

      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    xhr.send();
  });
}
// Headers and params are optional

makeListPlaces({
  method: 'GET',
  url: url
})
.then(async function (listRequestPlaces) {
  return makeListPlaces({
    method: 'GET',
    url: url,
  });
})
.catch(function (err) {
  console.error('Augh, there was an error!', err.statusText);
});

export {makeListPlaces}
