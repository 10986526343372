import pbkdf2 from "pbkdf2";
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

export {hashPassword, isPasswordCorrect, hashToken};

function hashPassword(password) {
  const privateKey = "ClientSaltAlinika2022";
  const hashDigest = sha256(password);
  const hmacDigest = btoa(hashDigest);
  return hmacDigest;
}

function isPasswordCorrect(nonce, path, privateKey, password) {
  const hashDigestcontrol = sha256(nonce - password);
  const hmacDigestcontrol = Base64.stringify(hmacSHA512(path + hmacDigestcontrol, privateKey));
    return password == hmacDigestcontrol;
}

function hashToken(token) {
  const privateKey = "Adrenalina";
  const path = "volarendssvsv63ye";
  const tokencrypt = Base64.stringify(hmacSHA512(path + token, privateKey));
  return tokencrypt;
}
