import {hashToken} from "components/FormLogin/FunctionSalt";



export {makeRequest}


var ids = JSON.parse(localStorage.getItem("rjsth"));
var id = 0;
if (ids) {
  id = atob(ids);
}

var tn = "Adrenalinika";
var token = hashToken(tn);
const url = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/customers/'+id;

  async function makeRequest () {
  return await new Promise(function (resolve, reject) {

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTION, PUT");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.responseText);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };


    xhr.send();
  });
}
// Headers and params are optional
if(id != 0){
makeRequest({
  method: 'GET',
  url: url
})
.then(async function (datums) {
  return makeRequest({
    method: 'GET',
    url: url,
  });
})
.catch(function (err) {
  console.error('Augh, there was an error!', err.statusText);
});
}
