import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import Input from "shared/Input/Input";
import { withRouter } from 'react-router';
import PageLogin from "containers/PageLogin/PageLogin";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Formik, Field, Form } from "formik";
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {loginUser} from "components/FormLogin/FunctionUser";
import Cookies from 'universal-cookie';


  //check is en
  const lang = new Cookies();
  lang.get('lang');


class LoginForm extends React.Component {


  render() {
    return (


      <Formik
        initialValues={{ email_login: "", passw_login: ""}}
        onSubmit={(values, actions) => {
          setTimeout(() => {
           actions.setSubmitting(false);
         }, 3000);
         var passwordcrypt = hashPassword(values.passw_login);
         var em = values.email_login;
         var tn = "Adrenalinika";
         var token = hashToken(tn);
         var datal = {
                        email: values.email_login,
                        password: values.passw_login,
           };

           loginUser(datal,token,em,passwordcrypt)
          }
      }

      >
      {
        props => (
          <form id="formlog" className="grid grid-cols-1 gap-6" onSubmit={props.handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="mail@mail.com"
                className="mt-1"
                id="email_login"
                name="email_login"
                value={props.values.email_login}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <a href="/forgot-pass" className="text-sm">
                  Forgot password?
                </a>
              </span>
              <Input type="password" className="mt-1" id="passw_login" name="passw_login"
              value={props.values.passw_login}
              onChange={props.handleChange}
              onBlur={props.handleBlur} />
            </label>
            {props.errors.email_login && <div id="feedback">{props.errors.email_login}</div>}
            <ButtonPrimary type="submit">{lang.cookies.lang == "en" ? "Continue" : "Continua"}</ButtonPrimary>
          </form>
)}
</Formik>

);
  }
}

ReactDOM.render(
  <LoginForm />,
  document.getElementById('root')
);

export default LoginForm;
