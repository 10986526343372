import React from "react";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import {logout} from "components/FormLogin/FunctionUser";

export interface CommonLayoutEnProps {}

const CommonLayoutEn: FC<CommonLayoutEnProps> = ({ children }) => {
  return (
    <div className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900">
      <div className="border-b border-neutral-200 dark:border-neutral-700 pt-12 bg-white dark:bg-neutral-800">
        <div className="container">
          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            <NavLink
              activeClassName="!border-primary-500"
              to="/en/account"
              className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
            >
              Account information
            </NavLink>

            <NavLink
              activeClassName="!border-primary-500"
              to="/en/your-voucher"
              className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
            >
              Manage Vouchers
            </NavLink>
            <NavLink
              activeClassName="!border-primary-500"
              to="/en/your-bookings"
              className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
            >
              Manage Bookings
            </NavLink>
            <NavLink
              activeClassName="!border-primary-500"
              to="/en/your-giftbox"
              className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
            >
              GiftCard
            </NavLink>

            <NavLink
            activeClassName="!border-primary-500"
            to="/en/forgot-pass"
            className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
            >
              Change Password
            </NavLink>
            <NavLink
              activeClassName="!border-primary-500"
              to="/en/account"
              onClick={() => {logout()}}
              className="block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0"
            >
              Logout
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">{children}</div>
    </div>
  );
};

export default CommonLayoutEn;
