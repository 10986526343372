import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import Input from "shared/Input/Input";
import { withRouter } from 'react-router';
import PageLogin from "containers/PageLogin/PageLogin";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Formik, Field, Form } from "formik";
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {LoginForm} from "components/FormLogin/FormLogin";
import {checkTokenControls} from "components/FormLogin/FunctionCheck";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'universal-cookie';


export {loginUser,logout,getToken};

var tknExternal = "Z25HYktvUzMyWXNDLzNIMmpmek9WVWVadmh2Y2pHUU0wT3czeiswYmtKdGx5ZGxRcFQ=";
var tkns = sessionStorage.getItem("session_id");

//check is en
const lang = new Cookies();
lang.get('lang');


async function loginUser(datal,token,em,passwordcrypt) {



     var datatot = JSON.stringify(datal);
     var sendalldata = btoa(em + passwordcrypt); //DEVO DECODIFICARE DAL SERVER IN BASE 64 E FARE CONTROLLO
     //var pws = hashPassword(datatot);

      const xhr = new XMLHttpRequest();
      const url = 'https://www.adrenalinika.com/gestionale/ajax_exemple/login.php';

      xhr.open('POST', url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader("Authorization", 'Bearer ' + token);
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
      xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTION, PUT");

      xhr.onreadystatechange = function (datatot) {
      // In local files, status is 0 upon success in Mozilla Firefox
      //console.log(datatot);
      if(xhr.readyState === XMLHttpRequest.DONE) {
        var status = xhr.status;
        //devo fare confronto tra email password inserite e quelle in json responseText o dbuser
        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully

          var dbuser = xhr.responseText;
          var jsonResponse = JSON.parse(dbuser);


          //utente trovato
          if(jsonResponse.status == 200){

            if (jsonResponse.token) {

              var idus = btoa(jsonResponse.id);
              localStorage.setItem("rjsth", JSON.stringify(idus), 36000);
              localStorage.setItem("__sdTsx", btoa(moment().format()));
              localStorage.setItem("user", JSON.stringify(jsonResponse.token), 36000);
              var user = JSON.parse(localStorage.getItem("user"));
              if(user){
                checkTokenControls();
                const cookies = new Cookies();
                cookies.set('__profile', '__setItemOk', { path: '/', maxAge: '172800' });
                lang.cookies.lang == "en" ?  window.location.assign("/en/account") : window.location.assign("/account");
              }

            }

            else {

            }
          }
          else {
            toast.error('Email o password errate. Ritenta oppure recupera la password.', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }


          //const [token, setToken] = useState();



        } else {
          // Oh no! There has been an error with the request!
        }

      }

    };
    xhr.send(datatot);


 }

 async function logout(){
   const cookiesa = new Cookies();
   var lang = cookiesa.get('lang');
   var engLog = "";
   if(lang == "en"){
      engLog = "Do you want to log out?";
   } else {
       engLog = "Vuoi effettuare il logout?";
   }
   var confirmAction = window.confirm(engLog);

   if (confirmAction) {
   localStorage.removeItem('rjsth');
   localStorage.removeItem('user');
   sessionStorage.removeItem('session_id');
   sessionStorage.removeItem('session_r');
   sessionStorage.removeItem('checkTokenAdrenalinika');
   sessionStorage.removeItem('confirmAci');
   localStorage.removeItem('__sdTsx');
   const cookies = new Cookies();
   cookies.remove('__profile', { path: '/', maxAge: '172800' });
   window.location.assign("/");
   }

   else{

   }

 }


scadenza();

 async function scadenza(){

   const cookies = new Cookies();
   var coookieOk = cookies.get('__profile');


   if (coookieOk) {

   }
   else{
     localStorage.removeItem('rjsth');
     localStorage.removeItem('user');
     sessionStorage.removeItem('session_id');
     sessionStorage.removeItem('session_r');
     sessionStorage.removeItem('checkTokenAdrenalinika');
     sessionStorage.removeItem('confirmAci');
     localStorage.removeItem('__sdTsx');
     const cookies = new Cookies();
     cookies.remove('__profile', { path: '/', maxAge: '172800' });
   }

 }


 async function getToken(){

      var user = JSON.parse(localStorage.getItem("user"));
      if(user){
        user = user.split(atob(tknExternal));
        user = btoa(user);
      }
      return user;
 }
