import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, useParams } from "react-router-dom";
import PrivateRoute from "components/PrivateRoutes/PrivateRoute";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingCategoriesPage from "containers/ListingExperiencesPage/ListingCategoriesPage";
import ListingSubCategoriesPage from "containers/ListingExperiencesPage/ListingSubCategoriesPage";
import ListingPlacesPage from "containers/ListingExperiencesPage/ListingPlacesPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageForgot from "containers/PageForgot/PageForgot";
import FormForgot from "containers/PageForgot/FormForgot"; //insertPage
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
import CategoryPage from "containers/CategoryPage/CategoryPage";
import SubCategoryPage from "containers/SubCategoryPage/SubCategoryPage";
import PlacesPage from "containers/PlacesPage/PlacesPage";
import GiftCard from "containers/GiftBox/GiftBox";
import PageRegolamento from "containers/PageRegolamento/PageRegolamento";
import PageAllPosts from "containers/PageAllPosts/PageAllPosts";
import PageAllCatsPosts from "containers/PageAllCatsPosts/PageAllCatsPosts";
import PageOneCat from "containers/PageOneCat/PageOneCat";
import PrenotazioniEffettuate from "containers/AccountPage/PrenotazioniEffettuate";
import RiscattoVoucher from "containers/RiscattoVoucher/RiscattoVoucher";
import GiftBoxList from "containers/AccountPage/GiftBoxList";
import ModificaPrenotazione from "containers/AccountPage/modificaPrenotazione";
import PageGuestVoucher from "containers/RiscattoGuest/PageGuestVoucher";
import EventiAziendali from "containers/EventiAziendali/EventiAziendali";
import ScalapayPage from "containers/ScalapayPage/ScalapayPage";
import TagPage from "containers/BlogTag/BlogTag";
import PageFaq from "containers/PageFaq/PageFaq";
import PagePrivacyCookie from "containers/PagePrivacyCookie/PagePrivacyCookie";
import PageBecomePartner from "containers/PageBecomePartner/PageBecomePartner";

//English
import PageHomeEn from "containers/PageHome/PageHomeEn";
import GiftCardEn from "containers/GiftBox/GiftBoxEn";
import PageContactEn from "containers/PageContact/PageContactEn";
import ListingExperiencesPageEn from "containers/ListingExperiencesPage/ListingExperiencesPageEn";
import PageGuestVoucherEn from "containers/RiscattoGuest/PageGuestVoucherEn";
import ListingPlacesPageEn from "containers/ListingExperiencesPage/ListingPlacesPageEn";
import ListingCategoriesPageEn from "containers/ListingExperiencesPage/ListingCategoriesPageEn";
import ListingSubCategoriesPageEn from "containers/ListingExperiencesPage/ListingSubCategoriesPageEn";
import PlacesPageEn from "containers/PlacesPage/PlacesPageEn";
import CategoryPageEn from "containers/CategoryPage/CategoryPageEn";
import BlogPageEn from "containers/BlogPage/BlogPageEn";
import EventiAziendaliEn from "containers/EventiAziendali/EventiAziendaliEn";
import ScalapayPageEn from "containers/ScalapayPage/ScalapayPageEn";
import PageAboutEn from "containers/PageAbout/PageAboutEn";
import AccountPageEn from "containers/AccountPage/AccountPageEn";
import SubCategoryPageEn from "containers/SubCategoryPage/SubCategoryPageEn";
import PageBecomePartnerEn from "containers/PageBecomePartner/PageBecomePartnerEn";
import PageRegolamentoEn from "containers/PageRegolamento/PageRegolamentoEn";
import PageFaqEn from "containers/PageFaq/PageFaqEn";
import PageForgotEn from "containers/PageForgot/PageForgotEn";
import FormForgotEn from "containers/PageForgot/FormForgotEn";
import AccountBillingEn from "containers/AccountPage/AccountBillingEn";
import PrenotazioniEffettuateEn from "containers/AccountPage/PrenotazioniEffettuateEn";
import GiftBoxListEn from "containers/AccountPage/GiftBoxListEn";
import BlogSingleEn from "containers/BlogPage/BlogSingleEn";
import PageAllPostsEn from "containers/PageAllPosts/PageAllPostsEn";
import PageAllCatsPostsEn from "containers/PageAllCatsPosts/PageAllCatsPostsEn";
import PageOneCatEn from "containers/PageOneCat/PageOneCatEn";
import ListingExperiencesDetailPageEn from "containers/ListingDetailPage/ListingExperiencesDetailPageEn";
import RiscattoVoucherEn from "containers/RiscattoVoucher/RiscattoVoucherEn";
import PageSignUpEn from "containers/PageSignUp/PageSignUpEn";
import PageLoginEn from "containers/PageLogin/PageLoginEn";
import PagePrivacyCookieEn from "containers/PagePrivacyCookie/PagePrivacyCookieEn";
import ModificaPrenotazioneEn from "containers/AccountPage/modificaPrenotazioneEn";



{/*export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/en", exact: true, component: PageHomeEn },
  { path: "/en#", exact: true, component: PageHomeEn },

  {path: "/listing-experiences", component: ListingExperiencesPage},
  {path: "/en/listing-experiences", component: ListingExperiencesPageEn},

  {path: "/listing-categories", component: ListingCategoriesPage},
  {path: "/en/listing-categories", component: ListingCategoriesPageEn},

  {path: "/listing-subcategories", component: ListingSubCategoriesPage},
  {path: "/subcategories", exact: true, component: ListingSubCategoriesPage},
  {path: "/en/listing-subcategories", component: ListingSubCategoriesPageEn},
  {path: "/en/subcategories", exact: true, component: ListingSubCategoriesPage},


  {path: "/places", exact: true, component: ListingPlacesPage},
  {path: "/listing-places", component: ListingPlacesPage},
  {path: "/en/listing-places", component: ListingPlacesPageEn},

  {path: "/esperienza", component: ListingExperiencesDetailPage},
  {path: "/en/experience", component: ListingExperiencesDetailPageEn},
  //
  { path: "/voucher-acquistati", component: AccountBilling },
  { path: "/en/your-voucher", component: AccountBillingEn },

  { path: "/lista-prenotazioni-effettuate", component: PrenotazioniEffettuate },
  { path: "/en/your-bookings", component: PrenotazioniEffettuateEn },

  { path: "/riscatta-il-tuo-voucher", component: RiscattoVoucher },
  { path: "/en/reedem-your-voucher", component: RiscattoVoucherEn },

  { path: "/modifica-prenotazione", component: ModificaPrenotazione },
  { path: "/en/modifica-prenotazione", component: ModificaPrenotazioneEn },

  { path: "/blog", component: BlogPage },
  { path: "/en/blog", component: BlogPageEn },

  { path: "/categorie-news", component: PageAllCatsPosts },
  { path: "/en/categorie-news", component: PageAllCatsPostsEn },

  { path: "/posts", component: PageAllPosts },
  { path: "/en/posts", component: PageAllPostsEn },

  { path: "/post/:id/:name", exact: true, component: BlogSingle },
  { path: "/en/post/:id/:name", exact: true, component: BlogSingleEn },

  { path: "/categorie", component: PageOneCat },
  { path: "/en/post-category", component: PageOneCatEn },

  { path: "/tag", component: TagPage },

  { path: "/contact", component: PageContact },
  { path: "/en/contact", component: PageContactEn },

  { path: "/about", component: PageAbout },
  { path: "/en/about", component: PageAboutEn },

  { path: "/signup", component: PageSignUp },
  { path: "/en/signup", component: PageSignUpEn },

  { path: "/subscription", component: PageSubcription },

  { path: "/login", component: PageLogin },
  { path: "/en/login", component: PageLoginEn },

  { path: "/forgot-pass", component: PageForgot },
  { path: "/en/forgot-pass", component: PageForgotEn },

  { path: "/change-pass", component: FormForgot },
  { path: "/en/change-pass", component: FormForgotEn },

  { path: "/categoria", component: CategoryPage },
  { path: "/en/category", component: CategoryPageEn },

  { path: "/subcategories/:id/:name", component: SubCategoryPage },
  { path: "/subcategories/:id", exact: true, component: SubCategoryPage },
  { path: "/en/subcategories", component: SubCategoryPageEn },

  { path: "/places/:name/:id", exact: true, component: PlacesPage },
  { path: "/en/places/:name/:id", exact: true, component: PlacesPageEn },

  { path: "/giftcard", component: GiftCard },
  { path: "/en/giftcard", component: GiftCardEn },

  { path: "/regolamento", component: PageRegolamento },
  { path: "/en/regulation", component: PageRegolamentoEn },

  { path: "/area-riscatto-voucher", component: PageGuestVoucher },
  { path: "/en/area-riscatto-voucher", component: PageGuestVoucherEn },

  { path: "/eventi-aziendali", component: EventiAziendali },
  { path: "/en/eventi-aziendali", component: EventiAziendaliEn },

  { path: "/partner-scalapay", component: ScalapayPage },
  { path: "/en/partner-scalapay", component: ScalapayPageEn },

  { path: "/faq-adrenalinika", component: PageFaq },
  { path: "/en/faq-adrenalinika", component: PageFaqEn },

  { path: "/diventa-partner-adrenalinika", component: PageBecomePartner },
  { path: "/en/diventa-partner-adrenalinika", component: PageBecomePartnerEn },

  { path: "/gestionale/index.php" },

  { path: "/privacy-cookie-policy", component: PagePrivacyCookie},
  { path: "/en/privacy-cookie-policy", component: PagePrivacyCookieEn},

];*/}


export const pages: Page[] = [
  { path: "/gestionale/index.php" },

  { path: "/privacy-cookie-policy", component: PagePrivacyCookie},
  { path: "/en/privacy-cookie-policy", component: PagePrivacyCookieEn},
  { path: "/regolamento", component: PageRegolamento },
  { path: "/en/regulation", component: PageRegolamentoEn },

  { path: "/area-riscatto-voucher", component: PageGuestVoucher },
  { path: "/en/area-riscatto-voucher", component: PageGuestVoucherEn },
  { path: "/voucher-acquistati", component: AccountBilling },
  { path: "/en/your-voucher", component: AccountBillingEn },

  { path: "/lista-prenotazioni-effettuate", component: PrenotazioniEffettuate },
  { path: "/en/your-bookings", component: PrenotazioniEffettuateEn },

  { path: "/riscatta-il-tuo-voucher", component: RiscattoVoucher },
  { path: "/en/reedem-your-voucher", component: RiscattoVoucherEn },

  { path: "/modifica-prenotazione", component: ModificaPrenotazione },
  { path: "/en/modifica-prenotazione", component: ModificaPrenotazioneEn },
  { path: "/signup", component: PageSignUp },
  { path: "/en/signup", component: PageSignUpEn },
  { path: "/login", component: PageLogin },
  { path: "/en/login", component: PageLoginEn },

  { path: "/forgot-pass", component: PageForgot },
  { path: "/en/forgot-pass", component: PageForgotEn },

  { path: "/change-pass", component: FormForgot },
  { path: "/en/change-pass", component: FormForgotEn },
];









const Routes = () => {


  var isSignedIn = false;

    var user = JSON.parse(localStorage.getItem("user"));
  const [check2, settsetset2] = useState();




    const [controlChecks, setControlChecks] = useState();
    var tkns = sessionStorage.getItem("session_id");
    const keySecret = "M3NHZlZCU2QzSnNmZG5ibmJkQVNLRk0zVVNzZEdIQUROMzM9PVNIM0dGRDRzNXNIc1NGQXNBQUhkU3NzNTNINEg9PVl6TmFjR0pJVm5kalJ6bEJZbGM1ZFZwaE9YbGFWMUYxWVZoUnBR";

    function checkTokenControls(){
      const keySecret = "M3NHZlZCU2QzSnNmZG5ibmJkQVNLRk0zVVNzZEdIQUROMzM9PVNIM0dGRDRzNXNIc1NGQXNBQUhkU3NzNTNINEg9PVl6TmFjR0pJVm5kalJ6bEJZbGM1ZFZwaE9YbGFWMUYxWVZoUnBR";

      var tknExternal = "Z25HYktvUzMyWXNDLzNIMmpmek9WVWVadmh2Y2pHUU0wT3czeiswYmtKdGx5ZGxRcFQ=";
      var tkns = sessionStorage.getItem("session_id");
      var user = JSON.parse(localStorage.getItem("user"));
      var otp = "r3DqDg=";
      var checkTok = "";



    if(tkns && user){

      checkTok = atob(tkns).split(otp);
      user = user.split(atob(tknExternal));
      user = user[1];


    checkTok = atob(checkTok[0]); // da session giusto
    }
         if (checkTok === user){
           setControlChecks(true);
           sessionStorage.setItem("checkTokenAdrenalinika", btoa(user)+atob(keySecret));

         } else {
           setControlChecks(false);
         }

     }

     useEffect(() => {
       if(user){
         checkTokenControls();
       }
       if(tkns){
         checkTokenControls();
       }

     }, []);

     isSignedIn = controlChecks;


  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <PrivateRoute
        component={AccountPage}
        path="/account"
        isSignedIn={isSignedIn}
        />
        <PrivateRoute
        component={AccountPageEn}
        path="/en/account"
        isSignedIn={isSignedIn}
        />
        <PrivateRoute
        component={GiftBoxList}
        path="/lista-giftbox-acquistate"
        isSignedIn={isSignedIn}
        />
        <PrivateRoute
        component={GiftBoxListEn}
        path="/en/your-giftbox"
        isSignedIn={isSignedIn}
        />
        <Route path="*" component={PageHome} />
        {/*<Route path="*" component={Page404} />*/}
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
