import React, { FC, useState, useEffect } from "react";
import { DayPickerSingleDateController, SingleDatePicker, AnchorDirectionShape } from "react-dates";
import ClearDataButton from "./ClearDataButton";
import moment from "moment";
import useWindowSize from "hooks/useWindowResize";
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import 'moment/locale/it';
import Cookies from 'universal-cookie';


export interface ExperiencesDateSingleInputProps {
  defaultValue: moment.Moment | null;
  onChange?: (date: moment.Moment | null) => void;
  defaultFocus?: boolean;
  fieldClassName?: string;
  onFocusChange: (focused: boolean) => void;
  className?: string;
  anchorDirection?: AnchorDirectionShape;
}

const ExperiencesDateSingleInput: FC<ExperiencesDateSingleInputProps> = ({
  defaultValue,
  onChange,
  defaultFocus = false,
  onFocusChange,
  anchorDirection,
  className = "",
  variable,
  fieldClassName = "[ nc-hero-field-padding ]",
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [startDate, setStartDate] = useState(defaultValue);
  const [dayStart, setDayStart] = useState(defaultValue);
  const [firstDayStart, setFirstDayStart] = useState(variable);
  const [currentMonth, setCurrentMonth] = useState();

  const windowSize = useWindowSize();

  const lang = new Cookies();
  lang.get('lang');


  useEffect(() => {
    setStartDate(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus]);

  useEffect(() => {
    if (onChange) {
      onChange(startDate);
    }
  }, [startDate]);

  const handleClearData = () => {
    setStartDate(null);
  };





  //INIZIO REQUEST PRODOTTI


    const [getCalendarDay, setCalendarDay] = useState([]);


  async function renderCalendarDay(selectedMonth) {
    var tn = "Adrenalinika";
    var token = hashToken(tn);
    var idExp = window.location.pathname;
    idExp = idExp.split('/');
    var idExperiences = "";

    if(lang.cookies.lang == "en"){
      idExperiences = idExp[3];
    } else {
      idExperiences = idExp[2];
    }

    var urlDay = '';
    if(selectedMonth){
      selectedMonth = moment(selectedMonth).subtract(0, 'month').format('YYYY-MM-DD');
      urlDay = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/prodotti?serviceId=' + parseInt(idExperiences) + '&date=' + selectedMonth;
    }else{
      urlDay = 'https://www.adrenalinika.com/gestionale/index.php/api/v1/prodotti?serviceId=' + parseInt(idExperiences) + '&date=' + variable["_i"];
    }


      async function requestDays() {
      return await new Promise(function (resolve, reject) {

        var xhr = new XMLHttpRequest();
        xhr.open('POST', urlDay, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", 'Bearer ' + token);
        xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
        xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
        xhr.onload = function () {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.responseText);

          } else {
            reject({
              status: xhr.status,
              statusText: xhr.statusText
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        };

        xhr.send();
      });
    }


    var getDays = requestDays();

    const detDays  = () => Promise.all([getDays]).then((listOfDays) => {
    return listOfDays;
    });


    detDays().then((restdays) => {
      setCalendarDay(JSON.parse(restdays));
    });


  }


    useEffect(() => {
    renderCalendarDay();
    }, []);

  var isBlocked = day => {
      return !getCalendarDay.some(startDate => day.isSame(startDate, 'day'));

  }

//FINE RENDER CALENDARIO


const handleDateFocusChange = (arg: { focused: boolean }) => {
  setFocusedInput(arg.focused);
  renderCalendarDay();
  onFocusChange && onFocusChange(arg.focused);
};


  const renderInputCheckInDate = () => {
    const focused = focusedInput;
    return (
      <div
        className={`flex w-full relative ${fieldClassName} items-center space-x-3 cursor-pointer ${
          focused ? "rounded-full" : ""
        }`}
        onClick={() => handleDateFocusChange({ focused: true })}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div>
        <div className="flex-grow">
          <span className="block xl:text-lg font-semibold">
            {lang.cookies.lang == "en" ? startDate ? startDate.format("DD MMM") : "Day" : startDate ? startDate.format("DD MMM") : "Data"}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {lang.cookies.lang == "en" ? startDate ? "Date" : `date` : startDate ? "Data" : `data`}
          </span>
          {startDate && focused && (
            <ClearDataButton onClick={() => handleClearData()} />
          )}
        </div>
      </div>
    );
  };
  moment.locale('it');
  if(lang.cookies.lang == "en"){
    moment.locale('en');
  }
  return (
    <div className={`relative flex ${className}`} style={{ flex: "1 0 0%" }}>
      <div className="absolute inset-x-0 bottom-0">
        <SingleDatePicker
          date={startDate}
          onDateChange={(startDate) => setStartDate(startDate)}
          id={"nc-hero-ExperiencesDateSingleInput-startDateId"}
          focused={focusedInput}
          daySize={windowSize.width > 425 ? 56 : undefined}
          orientation={"horizontal"}
          onFocusChange={handleDateFocusChange}
          noBorder
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          anchorDirection={anchorDirection}
          isDayBlocked={isBlocked}
          initialVisibleMonth={() => { var inizioMese = moment().add(0, 'month').endOf('month'); if (startDate > inizioMese){renderCalendarDay(currentMonth); return startDate }else{ renderCalendarDay(currentMonth); return firstDayStart} }}
          onPrevMonthClick={
            (stateDateWrapper) => {
              var selectedMonth = stateDateWrapper['_d'];
              selectedMonth = moment(selectedMonth).format('YYYY-MM-DD');
              const prevBtn = document.getElementsByClassName('DayPickerNavigation_leftButton__horizontalDefault')[0];
              if (moment(selectedMonth).format('MM') <= moment().format('MM') && moment(selectedMonth).format('YYYY') < moment().format('YYYY')) {
                prevBtn.style.display = 'none';
            } else {
                prevBtn.style.display = 'flex';
            }
              setCurrentMonth(selectedMonth);
              renderCalendarDay(selectedMonth);
            }
          }
          onNextMonthClick={
            (stateDateWrapper) => {
              var selectedMonth = stateDateWrapper['_d'];
              selectedMonth = moment(selectedMonth).format('YYYY-MM-DD');
              const prevBtn = document.getElementsByClassName('DayPickerNavigation_leftButton__horizontalDefault')[0];
              if (moment(selectedMonth).format('MM') <= moment().format('MM') && moment(selectedMonth).format('YYYY') < moment().format('YYYY')) {
                prevBtn.style.display = 'none';
            } else {
                prevBtn.style.display = 'flex';
            }
              setCurrentMonth(selectedMonth);
              renderCalendarDay(selectedMonth);
          }}

        />
      </div>

      {renderInputCheckInDate()}
    </div>
  );
};


export default ExperiencesDateSingleInput;
