import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayoutEn from "./CommonLayoutEn";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardReservation";
import {useParams} from "react-router-dom";
import { Redirect } from 'react-router';
import {hashPassword, hashToken} from "components/FormLogin/FunctionSalt";
import {makeRequest} from "components/AccountPage/AccountPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

export interface AccountBillingEnProps {
  className?: string;
  data?: string;
}

const AccountBillingEn: FC<AccountBillingEnProps> = ({ className = "", location }) => {

  var user = JSON.parse(localStorage.getItem("user"));
  var ids = JSON.parse(localStorage.getItem("rjsth"));
  const idUserUpload = parseInt(atob(ids));


  var tn = "Adrenalinika";
  var token = hashToken(tn);
  const urlUserUpload = "https://www.adrenalinika.com/gestionale/ajax_exemple/voucher/get-user-appointments.php";

  async function sendUploadUser (idUserUpload) {
  return await new Promise(function (resolve, reject) {

    var xhr = new XMLHttpRequest();
    xhr.open('POST', urlUserUpload, true);
    xhr.setRequestHeader("Authorization", 'Bearer ' + token);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "*");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT");
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);

      }
    };
    xhr.onerror = function () {
      reject({
        status: xhr.status,
        statusText: xhr.statusText
      });
    };

    xhr.send("customer_id=" + JSON.stringify(idUserUpload));

  });
  };


    const [data, setDataTot] = useState([]);
    var fieldVoucher = "";

    if(location.pathname == "/en/your-bookings"){
    var uploadUs = sendUploadUser(idUserUpload);
    fieldVoucher  = () =>  Promise.all ([uploadUs]).then((listRequestUpl) => {
      return listRequestUpl;
    });
    }

  useEffect(() => {
    if(fieldVoucher != ""){
    fieldVoucher().then((ressts) => {
      var result = JSON.parse(ressts);
      setDataTot(result);
  });
  }
  }, []);


  if(!user){
    return (
      <Redirect to="/en/"/>
    );
  }

else{
  var dataAttivi = data;
  dataAttivi = dataAttivi.filter((_, i) => moment(data[i].start_datetime) > moment() && parseInt(data[i].importo) != 0 && data[i].pagamento_effettuato == "1");
  var dataScaduti = data;
  dataScaduti = dataScaduti.filter((_, i) => moment(dataScaduti[i].start_datetime) <= moment());

  return (
    <div>
      <CommonLayoutEn>
        <div className="space-y-6 sm:space-y-8 pb-12">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Booking list</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="">
            <span className="text-xl font-semibold block">Active bookings</span>
            <br />
            {dataAttivi ? dataAttivi.map((_, i) => (
                <ExperiencesCardH data={dataAttivi[i]} key={dataAttivi[i].id+[i]} buttonName="Request change" ButtonClass="background-red" param={moment(dataAttivi[i].start_datetime) > moment().add(7, 'days') ? "" : "hidden"} link="/en/modifica-prenotazione" />
              )): <h2 className="text-1xl md:text-xl font-semibold ">No Results...</h2>}

          </div>
        </div>

          <div className="space-y-6 sm:space-y-8  pb-12">
            {/* HEADING */}
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div style={{opacity: '0.7'}}>
                <span className="text-xl font-semibold block">Past bookings</span>
                <br />
                  {dataScaduti ? dataScaduti.map((_, i) => (
                      <ExperiencesCardH data={dataScaduti[i]} key={dataScaduti[i].id+[i]} buttonName="Buy again" ButtonClass="background-red" completeLink={dataScaduti[i].href} />
                    )): <h2 className="text-1xl md:text-xl font-semibold ">No Results...</h2>}
              </div>
        </div>
        </CommonLayoutEn>
    </div>
  );
};
}

export default AccountBillingEn;
