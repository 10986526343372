import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import pagamenti from "images/payment.webp";
import Cookies from 'universal-cookie';
import moment from "moment";



export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}


//check is en
const lang = new Cookies();
lang.get('lang');


const widgetMenusEn: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Information",
    menus: [
      { href: "/en/eventi-aziendali", label: "Corporate events" },
      { href: "/en/giftcard", label: "Gift Cards" },
      { href: "/en/partner-scalapay", label: "Scalapay" },
      { href: "/en/about", label: "Company" },
    ],
  },
  {
    id: "1",
    title: "Partner area",
    menus: [
      { href: "/en/diventa-partner-adrenalinika", label: "Become a Partner" },
      { href: "/en/contact", label: "Technical support" },
      { href: "/en/regulation", label: "Regulation" },
      { href: "/gestionale/index.php/backend", label: "Partner area" },
    ],
  },
  {
    id: "2",
    title: "Useful links",
    menus: [
      { href: "/en/area-riscatto-voucher", label: "Voucher redemption" },
      { href: "/en/contact#newsletter", label: "Newsletters" },
      { href: "/en/faq-adrenalinika", label: "Frequent questions" },
      { href: "/en/account", label: "Customer area" },
    ],
  },
  {
    id: "4",
    title: "Contact us",
    menus: [
    /*  { href: "tel:+393922276803", label: "+39 392 227 6803" },*/
      { href: "mailto:info@adrenalinika.com", label: "info@adrenalinika.com" },
      /*{ href: "https://wa.me/+393922276803", label: "Whatsapp" },*/
    ],
  },
];


//menu inglese

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Informazioni",
    menus: [
      { href: "/eventi-aziendali", label: "Eventi Aziendali" },
      { href: "/giftcard", label: "Gift Card" },
      { href: "/partner-scalapay", label: "Scalapay" },
      { href: "/about", label: "Azienda" },
    ],
  },
  {
    id: "1",
    title: "Area Partner",
    menus: [
      { href: "/diventa-partner-adrenalinika", label: "Diventa Partner" },
      { href: "/contact", label: "Supporto Tecnico" },
      { href: "/regolamento", label: "Regolamento" },
      { href: "/gestionale/index.php/backend/", label: "Area Partner" },
    ],
  },
  {
    id: "2",
    title: "Link Utili",
    menus: [
      { href: "/area-riscatto-voucher", label: "Riscatto Voucher" },
      { href: "/contact#newsletter", label: "Newsletter" },
      { href: "/faq-adrenalinika", label: "Domande Frequenti" },
      { href: "/account", label: "Area Clienti" },
    ],
  },
  {
    id: "4",
    title: "Contattaci",
    menus: [
  /*    { href: "tel:+393922276803", label: "+39 392 227 6803" },*/
      { href: "mailto:info@adrenalinika.com", label: "info@adrenalinika.com" },
    /*  { href: "https://wa.me/+393922276803", label: "Whatsapp" },*/
    ],
  },
];


const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-32 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-4 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-2 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="flex items-center">
            <SocialsList1 className="flex items-center lg:flex-row  lg:items-start" />

          </div>
          <div className="flex items-center hidden lg:block">
            <img width="90%" height="auto" alt="metodi-pagamento" className="float-left mb-0 pr-8 mt-0 text-left items-left m-auto" src={pagamenti} />
          </div>
        </div>
        {(lang.cookies.lang == "en" ? widgetMenusEn : widgetMenus).map(renderWidgetMenuItem)}
      </div>
      <div className="w-full text-center items-center mt-20 flex lg:block">
      <div className="Copyright w-full text-center mt-16 mb-0 pb-20 items-center text-sm font-semibold block">
      <div className="flex items-center inline-flex" style={{verticalAlign: "bottom"}}>
        <a
        href="#"
        onClick={() => {
          const cookies = new Cookies();
          cookies.set('lang', "it", { path: '/', maxAge: '172800' });
          if(cookies.cookies.lang == "en")
          {
            window.location.replace("/en");
          } else {
            window.location.replace("/");
          }
          }}

          >🇮🇹 Italiano</a>
      </div>
        <div className="flex items-center mb-0 mt-0 inline-flex" style={{padding:"0px 11px 1px 11px"}}>  </div>
                <div className="flex items-center inline-flex">
                  <a
                  href="#"
                  onClick={() => {
                    const cookies = new Cookies();
                    cookies.set('lang', "en", { path: '/', maxAge: '172800' });
                    if(cookies.cookies.lang == "en")
                    {
                      window.location.replace("/en");
                    } else {
                      window.location.replace("/");
                    }
                    }}
                    >🇬🇧 English</a>
                </div>
        </div>
      <p className="Copyright mt-0 pt-0">© Copyright {moment().format("Y")} Adrenalinika Srl in Liquidazione. {lang.cookies.lang == "en" ? "All rights reserved. VAT. 12560220969" : "Tutti i diritti sono riservati. P.IVA 12560220969"}  | <a href="/privacy-cookie-policy">Privacy&Cookie</a> | Trustpilot | Scalapay.</p>

      </div>
    </div>
  );
};

export default Footer;
